<main>
  <div class="container-fluid mb-4 fondo-gris-ok" style="margin-top:-20px;">
    <div class="container">
        <div class="row">
            <div class="col-md-7 col-12 mt-3">
                <h1>Ejecuciones Masivas</h1>
            </div>
            <div class="col-md-5 col-12">
                <div aria-label="breadcrumb" class="text-right float-right">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href={{urlGestion}}>Home</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/home-reportes']">Reportes</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/rep-cartola-actualizada']">Resumen Cartola</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Ejecuciones Masivas</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="container-fluid fondo-blanco pb-3 mb-4">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-12 mx-auto mt-5 mb-3">
                <h4 class="text-bold">LISTADO DE EJECUCIONES MASIVAS</h4>
            </div>
            <div class="col-xl-12 col-lg-12 col-12 mx-auto margin-top-20 table-responsive">

                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>Estado</th>
                        <th>Fecha Inicio</th>
                        <th>Fecha Fin</th>
                        <th>Total Registros</th>
                        <th>Total Correctos</th>
                        <th>Total Errores</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let ejecuciones of listadoEjecuciones">
                        <td>
                            {{ejecuciones.estado}}
                            <div *ngIf="ejecuciones.estado === 'PROCESANDO'" class="spinner-border spinner-border-sm ml-2" role="status">
                                <span class="sr-only">Cargando...</span>
                            </div>
                        </td>
                        <td>{{ejecuciones.fecInicio | customDate : 'DD/MM/YYYY HH:mm:ss' : true}}</td>
                        <td>{{ejecuciones.fecFin | customDate : 'DD/MM/YYYY HH:mm:ss' : true }}</td>
                        <td>{{ejecuciones.total}}</td>
                        <td>{{ejecuciones.correctas}}</td>
                        <td>{{ejecuciones.errores}}</td>
                    </tr>
                    <tr *ngIf="listadoEjecuciones.length === 0">
                        <td colspan="8" class="text-center">No ha realizado ejecuciones masivas</td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div>
</main>