import { Component, OnInit } from '@angular/core';
import { LoggedInCallback } from '../../services/cognito.service';
import { UserLoginService } from '../../services/user-login.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'angular-web-storage';
import { Router } from '@angular/router';
import {InitParams, ClienteAuditoria} from 'otic-cliente-auditoria';

@Component({
  selector: 'app-home-reportes',
  templateUrl: './home-reportes.component.html',
  styleUrls: ['./home-reportes.component.scss']
})
export class HomeReportesComponent implements OnInit, LoggedInCallback {
  // opciones: any;
  permisosModulosAcciones: any = {};
  urlGestion: string = environment.url_gestor;

  constructor(
    private userLoginService: UserLoginService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    console.log('HomeReportesComponent: constructor');
    InitParams({
      url: environment.URLACCIONES,
      key: environment.KEYACCIONES
    });
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
      if (JSON.stringify(this.permisosModulosAcciones) === '{}') {
          this.router.navigate(['/auth']);
      }
    } else {
      this.router.navigate(['/auth']);
    }
  }

  redireccionar(page:any[], modulo: string, submodulo: string){
    this.accion(modulo, submodulo);
    this.router.navigate(page);

  }

  async accion(modulo: string, submodulo: string){
    const token = await this.localStorageService.get('idToken');
    const userData= await this.localStorageService.get('userData');

    ClienteAuditoria({
      sistema: 'GCH Reportes',
      modulo,
      submodulo: submodulo ? submodulo : '',
      usuario: userData.rut,
      token,
      accion: `Usuario ${userData.rut} entra`
    });
  }

}
