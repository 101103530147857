import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import {InactivityTimerComponent} from './components/inactivity-timer/inactivity-timer.component';
import {AuthGuard} from './_guards';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Reportes';
  // ruta: string;
  location: Location = window.location;

  constructor(public router: Router,
              private authGuard: AuthGuard,
              private inactivityTimerComponent: InactivityTimerComponent) {
    // this.router.events.subscribe(() => this.ruta = this.router.url);
  }
  @HostListener('document:keyup')
  @HostListener('document:click')
  @HostListener('document:wheel')
  resetTimer() {
    this.authGuard.notifyUserAction();
    this.inactivityTimerComponent.ngOnInit();
  }
}
