import { environment } from '../../environments/environment';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as i0 from "@angular/core";
var CognitoUtilService = /** @class */ (function () {
    function CognitoUtilService() {
    }
    CognitoUtilService.prototype.getUserPool = function () {
        if (environment.IDENTITY_POOL_ID) {
            CognitoUtilService.POOL_DATA.endpoint = environment.IDENTITY_POOL_ID;
        }
        return new CognitoUserPool(CognitoUtilService.POOL_DATA);
    };
    CognitoUtilService.prototype.getCurrentUser = function () {
        // tslint:disable-next-line:max-line-length
        CognitoUtilService.CLIENT_ID = localStorage.getItem('ci') ? JSON.parse(localStorage.getItem('ci'))._value != null ? JSON.parse(localStorage.getItem('ci'))._value : environment.CLIENT_ID : environment.CLIENT_ID;
        CognitoUtilService.POOL_DATA = {
            UserPoolId: CognitoUtilService.USER_POOL_ID,
            ClientId: CognitoUtilService.CLIENT_ID
        };
        console.log('CognitoUtilService.CLIENT_ID', CognitoUtilService.CLIENT_ID);
        return this.getUserPool().getCurrentUser();
    };
    CognitoUtilService.prototype.setCognitoCreds = function (creds) {
        this.cognitoCreds = creds;
    };
    CognitoUtilService.prototype.getCognitoCreds = function () {
        return this.cognitoCreds;
    };
    // This method takes in a raw jwtToken and uses the global AWS config options to build a
    // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
    // to avoid unnecessary calls to setCognitoCreds.
    CognitoUtilService.prototype.buildCognitoCreds = function (idTokenJwt) {
        var url = 'cognito-idp.' + CognitoUtilService.REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtilService.USER_POOL_ID;
        if (environment.IDENTITY_POOL_ID) {
            url = environment.COGNITO_IDP_ENDPOINT + '/' + CognitoUtilService.USER_POOL_ID;
        }
        var logins = {};
        logins[url] = idTokenJwt;
        var params = {
            IdentityPoolId: CognitoUtilService.IDENTITY_POOL_ID,
            Logins: logins
        };
        // tslint:disable-next-line:no-angle-bracket-type-assertion
        var serviceConfigs = {};
        if (environment.COGNITO_IDENTITY_ENDPOINT) {
            serviceConfigs.endpoint = environment.COGNITO_IDENTITY_ENDPOINT;
        }
        var creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
        console.log('creds', creds);
        this.setCognitoCreds(creds);
        return creds;
    };
    CognitoUtilService.prototype.getCognitoIdentity = function () {
        return this.cognitoCreds.identityId;
    };
    CognitoUtilService.prototype.getAccessToken = function (callback) {
        if (callback == null) {
            var msjError = 'CognitoUtil: callback in getAccessToken is null...returning';
            throw (msjError);
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getAccessToken().getJwtToken());
                    }
                }
            });
        }
        else {
            callback.callbackWithParam(null);
        }
    };
    CognitoUtilService.prototype.getIdToken = function (callback) {
        if (callback == null) {
            var msjError = 'CognitoUtil: callback en getIdToken es null...retornando';
            throw (msjError);
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log('CognitoUtil: No se pudo obtener las credenciales:' + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getIdToken().getJwtToken());
                    }
                    else {
                        console.log('CognitoUtil: Se obtuvo el token sin embargo la sesion es inválida');
                    }
                }
            });
        }
        else {
            callback.callbackWithParam(null);
        }
    };
    CognitoUtilService.prototype.getRefreshToken = function (callback) {
        if (callback == null) {
            var msjError = 'CognitoUtil: callback in getRefreshToken is null...returning';
            throw (msjError);
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getRefreshToken());
                    }
                }
            });
        }
        else {
            callback.callbackWithParam(null);
        }
    };
    CognitoUtilService.prototype.refresh = function () {
        this.getCurrentUser().getSession(function (err, session) {
            if (err) {
                console.log("CognitoUtil: Can't set the credentials:" + err);
            }
            else {
                if (session.isValid()) {
                    console.log('CognitoUtil: refreshed successfully');
                }
                else {
                    console.log('CognitoUtil: refreshed but session is still not valid');
                }
            }
        });
    };
    CognitoUtilService.REGION = environment.REGION;
    CognitoUtilService.IDENTITY_POOL_ID = environment.IDENTITY_POOL_ID;
    CognitoUtilService.USER_POOL_ID = environment.USER_POOL_ID;
    CognitoUtilService.CLIENT_ID = localStorage.getItem('ci') ? JSON.parse(localStorage.getItem('ci'))._value != null ? JSON.parse(localStorage.getItem('ci'))._value : environment.CLIENT_ID : environment.CLIENT_ID;
    // public static CLIENT_ID =  environment.CLIENT_ID;
    CognitoUtilService.POOL_DATA = {
        UserPoolId: CognitoUtilService.USER_POOL_ID,
        ClientId: CognitoUtilService.CLIENT_ID
    };
    CognitoUtilService.ngInjectableDef = i0.defineInjectable({ factory: function CognitoUtilService_Factory() { return new CognitoUtilService(); }, token: CognitoUtilService, providedIn: "root" });
    return CognitoUtilService;
}());
export { CognitoUtilService };
