<nav class="navbar navbar-expand-md texto-blanco sticky-top shadow-lg" style="background-color:#fff;border-bottom: 1px solid #FC882E;">
    <a (click)="redirectTo('home', 'Home', null)" class="navbar-brand text-bold">
        <img src="../assets/img/logo-otic-2018.png" class="logo-lcliente-home float-left" alt="Logo OTIC SOFOFA">
    </a>
    <a (click)="toggleSidebar('btnMenu', $event)" class="mr-auto ml-3 font-weight-bold menu-open-clase hover-menu-main">
        <img src="../assets/img/open-menu-orange.svg" class="mr-2 text-left">Menú
    </a>
    <a (click)="abrirNotificaciones()" class="ml-2 mr-3 hover-menu-main" data-toggle="tooltip" data-title="Ver Notificaciones">
        <img src="../assets/img/notification-alertas-azul-oscuro.svg">
        <span class="badge badge-alerta-orange" style="top:20px;right:30px;font-weight:400!important;">{{notificacionTotal > 0 ? notificacionTotal : "0"}}</span>
    </a>
    <div class="ml-2 dropdown d-none d-md-block">
        <kendo-dropdownlist *ngIf="userData.clientesComercial && userData.clientesComercial.length > 1 && userData.empresas.length === 0" class="form-control" style="width: 250px;" [(ngModel)]="clienteComercialSeleccionado" [data]="userData.clientesComercial"
                [textField]="'nombre'" [valueField]="'codigo'" [filterable]="true" [kendoDropDownFilter]="{ caseSensitive: false, operator: 'contains' }" (valueChange)="confirmarClienteComercial()">
            <kendo-combobox-messages noDataText="Sin resultados">
            </kendo-combobox-messages>
        </kendo-dropdownlist>
        <kendo-dropdownlist *ngIf="userData.empresas && userData.empresas.length > 1" class="form-control" style="width: 250px;" [(ngModel)]="clienteComercialSeleccionado" [data]="userData.empresas" [textField]="'nombre'" [valueField]="'nombre'" [filterable]="true"
                [kendoDropDownFilter]="{ caseSensitive: false, operator: 'contains' }" (valueChange)="confirmarClienteComercial()">
            <kendo-combobox-messages noDataText="Sin resultados">
            </kendo-combobox-messages>
        </kendo-dropdownlist>
    </div>
    <div class="ml-2 d-none d-md-block hover-menu-main">
        <span style="color:#1D2345!important;">
            <img src="../assets/img/usuario-azul-oscuro.svg" alt="Usuario" class="icon-sm">
            {{userData.nombre}}
        </span>
        <button (click)="doLogout()" class="btn d-none d-md-block" type="button" style="padding: 0px!important;background: transparent!important;float: right;margin-top: 0px;margin-left: 10px;" data-toggle="tooltip" data-title="Cerrar Sesión">
            <img src="../assets/img/salir-azul-oscuro.svg" alt="Salir" class="icon-sm">
        </button>
    </div>
</nav>
<!--inicio sidebar-->

<div id="mySidebar" class="sidebar pt-0" [style.width.px]="width" (mouseover)="toggleSidebar('mouse', $event)" (mouseout)="toggleSidebar('mouse', $event)">
    <span class="people-data d-block d-md-none">
        <a class="texto-cortado people" style="margin-left:3px;">
            <img src="../assets/img/usuario-azul-oscuro.svg">
            <span class="icon-text" style="margin-left:12px;">{{userData.nombre}}</span>
            <button (click)="doLogout()" class="btn d-block d-md-none" type="button" style="padding: 0px!important;background: transparent!important;float: right;margin-top: 0px;" data-toggle="tooltip" data-title="Cerrar Sesión">
                <img src="../assets/img/salir-mini-anaranjado.svg" alt="Salir" class="icon-sm">
            </button>
            <div class="dropdown d-block d-md-none">
                <kendo-dropdownlist *ngIf="userData.clientesComercial && userData.clientesComercial.length > 1 && userData.empresas.length === 0" class="form-control" style="width: 200px;margin-left: 40px;" [(ngModel)]="clienteComercialSeleccionado" [data]="userData.clientesComercial"
                                    [textField]="'nombre'" [valueField]="'codigo'" [filterable]="true" [kendoDropDownFilter]="{ caseSensitive: false, operator: 'contains' }" (valueChange)="confirmarClienteComercial()">
                    <kendo-combobox-messages noDataText="Sin resultados">
                    </kendo-combobox-messages>
                </kendo-dropdownlist>
                <kendo-dropdownlist *ngIf="userData.empresas && userData.empresas.length > 1" class="form-control" style="width: 200px;margin-left: 40px;" [(ngModel)]="clienteComercialSeleccionado" [data]="userData.empresas" [textField]="'nombre'" [valueField]="'nombre'" [filterable]="true"
                                    [kendoDropDownFilter]="{ caseSensitive: false, operator: 'contains' }" (valueChange)="confirmarClienteComercial()">
                    <kendo-combobox-messages noDataText="Sin resultados">
                    </kendo-combobox-messages>
                </kendo-dropdownlist>
            </div>
        </a>
    </span>
    <hr style="border:1px solid #0079BE;" class="py-0 people-data d-block d-md-none my-0">
    <a *ngIf="permisosModulosAcciones.CODSENC" (click)="collapsables.cataCont = !collapsables.cataCont">
        <i class="material-icons">menu_book</i>
        <span class="icon-text">Catálogo de Contenidos</span>
        <img src="../assets/img/desplegar-icono.svg" class="text-right pl-2">
    </a>
    <div class="row" id="CataCont" [(ngbCollapse)]="collapsables.cataCont">
        <div class="col-12 px-0">
            <div class="row">
                <a *ngIf="permisosModulosAcciones.CODSENC" (click)="redirectTo('catalogo-contenidos-cursos', 'Catálogo de Contenidos', 'Códigos SENCE')" class="col-12 padding-sub-option">Códigos SENCE</a>
                <a *ngIf="permisosModulosAcciones.MANTCURSPROP" (click)="redirectTo('catalogo-contenidos-cursos-sf', 'Catálogo de Contenidos', 'Códigos Sin Franquicia')" class="col-12 padding-sub-option">Códigos Sin Franquicia</a>
            </div>
        </div>
    </div>
    <a *ngIf="permisosModulosAcciones.INSCCUR || permisosModulosAcciones.ACHIST" (click)="collapsables.accioCapa = !collapsables.accioCapa">
        <i class="material-icons">manage_accounts</i>
        <span class="icon-text">Acciones de Capacitación</span>
        <img src="../assets/img/desplegar-icono.svg" class="text-right pl-2">
    </a>
    <div class="row" id="AccioCapa" [(ngbCollapse)]="collapsables.accioCapa">
        <div class="col-12 px-0">
            <div class="row">
                <a *ngIf="permisosModulosAcciones.INSCCUR" (click)="redirectTo('accion-capacitacion-listado', 'Acciones de Capacitación', 'Acciones de Capacitación')" class="padding-sub-option col-12">Acciones de Capacitación</a>
                <a *ngIf="permisosModulosAcciones.ACHIST" (click)="redirectTo('accion-capacitacion-historia', 'Acciones de Capacitación', 'Acciones de Capacitación Históricas')" class="padding-sub-option col-12">Acciones de Capacitación <br/>Históricas</a>
            </div>
        </div>
    </div>
    <a *ngIf="permisosModulosAcciones.PP_PLANPROG" (click)="collapsables.planesProg = !collapsables.planesProg">
        <i class="material-icons">assignment</i>
        <span class="icon-text">Planes y Programas</span>
        <img src="../assets/img/desplegar-icono.svg" class="text-right pl-2">
    </a>
    <div class="row" id="PlanesProg" [(ngbCollapse)]="collapsables.planesProg">
        <div class="col-12 px-0">
            <div class="row">
                <a *ngIf="permisosModulosAcciones.PP_ACTIVIDADES" (click)="redirectTo('actividades-listado', 'Planes y Programas', 'Actividades')" class="padding-sub-option col-12">Actividades</a>
                <a *ngIf="permisosModulosAcciones.PP_PLANPROG" (click)="redirectTo('planes-listado', 'Planes y Programas', 'Planes de Capacitación')" class="padding-sub-option col-12">Planes y Programas</a>
                <a *ngIf="permisosModulosAcciones.PP_AGENDA" (click)="redirectTo('agenda', 'Planes y Programas', 'Agenda')" class="padding-sub-option col-12">Agenda</a>
            </div>
        </div>
    </div>
    <a *ngIf="permisosModulosAcciones.NOMI" (click)="redirectTo('nomina', 'Nómina', null)">
        <i class="material-icons">groups</i>
        <span class="icon-text">Nómina</span>
    </a>
    <a *ngIf="
        permisosModulosAcciones.REPOAPOR
        || permisosModulosAcciones.REPOCART
        || permisosModulosAcciones.REPCURPART
        || permisosModulosAcciones.REPCUR
        || permisosModulosAcciones.REPHISTPART
    " [routerLink]="['/home-reportes']" [ngClass]="{active: activeMenu === 'reportes'}">
        <i class="material-icons">filter_alt</i>
        <span class="icon-text">Reportes</span>
    </a>
    <a *ngIf="permisosModulosAcciones.MONOPE" (click)="redirectTo('monitor-operaciones', 'Monitor Operaciones', 'Ac con Incidentes')">
        <i class="material-icons">tv</i>
        <span class="icon-text">Monitor Operaciones</span>
    </a>
    <a *ngIf="permisosModulosAcciones.GESTUSU" (click)="redirectTo('gestion-usuarios', 'Gestión Usuarios', null)">
        <i class="material-icons">build</i>
        <span class="icon-text">Gestión Usuarios</span>
    </a>
    <a *ngIf="permisosModulosAcciones.KPI" (click)="redirectTo('kpi', 'Indicadores', null)">
        <i class="material-icons">pie_chart</i>
        <span class="icon-text">Indicadores</span>
    </a>
    <a *ngIf="permisosModulosAcciones.ALERTAS" (click)="collapsables.liceHab = !collapsables.liceHab">
        <i class="material-icons">engineering</i>
        <span class="icon-text">Licencias Habilitantes</span>
        <img src="../assets/img/desplegar-icono.svg" class="text-right pl-2">
    </a>
    <div class="row" id="LiceHab" [(ngbCollapse)]="collapsables.liceHab">
        <div class="col-12 px-0">
            <div class="row">
                <a *ngIf="permisosModulosAcciones.ALERTAS" (click)="redirectTo('licencias-habilitantes/gestion', 'Licencias Habilitantes', 'Gestión Licencias Habilitantes')" class="padding-sub-option col-12">Gestión Licencias <br/>Habilitantes</a>
                <a *ngIf="permisosModulosAcciones.ALERTAS && (permisosModulosAcciones.ALERTAS.acciones.includes('ALERTASCrear') || permisosModulosAcciones.ALERTAS.acciones.includes('ALERTASEdit_01'))" (click)="redirectTo('licencias-habilitantes/registro', 'Licencias Habilitantes', 'Registrar Licencia Habilitante')" class="padding-sub-option col-12">Registrar Licencia <br/>Habilitante</a>
                <a *ngIf="permisosModulosAcciones.ALERTAS && (permisosModulosAcciones.ALERTAS.acciones.includes('ALERTASCrear') || permisosModulosAcciones.ALERTAS.acciones.includes('ALERTASEdit_01'))" (click)="redirectTo('licencias-habilitantes/carga-masiva', 'Licencias Habilitantes', 'Carga Masiva de Licencias')" class="padding-sub-option col-12">Carga Masiva de <br/>Licencias</a>
                <a *ngIf="permisosModulosAcciones.ALERTAS && (permisosModulosAcciones.ALERTAS.acciones.includes('ALERTASCrear') || permisosModulosAcciones.ALERTAS.acciones.includes('ALERTASEdit_01'))" (click)="redirectTo('licencias-habilitantes/carga-masiva-documentos', 'Licencias Habilitantes', 'Carga Masiva de Documentos Asociados')" class="padding-sub-option col-12">Carga Masiva de <br/>Documentos Asociados</a>
                <a *ngIf="permisosModulosAcciones.ALERTAS" (click)="redirectTo('licencias-habilitantes/mantenedor', 'Licencias Habilitantes', 'Mantenedor Licencias Habilitantes')" class="padding-sub-option col-12">Mantenedor Licencias <br/>Habilitantes</a>
                <a *ngIf="permisosModulosAcciones.ALERTAS" (click)="redirectTo('licencias-habilitantes/mantenedor-alertas', 'Licencias Habilitantes', 'Mantenedor Alertas')" class="padding-sub-option col-12">Mantenedor Alertas</a>
            </div>
        </div>
    </div>
    <a *ngIf="permisosModulosAcciones.PLANTILLAS || permisosModulosAcciones.AVISOS" (click)="collapsables.gestionAvi = !collapsables.gestionAvi">
        <i class="material-icons">outgoing_mail</i>
        <span class="icon-text">Gestión Avisos</span>
        <img src="../assets/img/desplegar-icono.svg" class="text-right pl-2">
    </a>
    <div class="row" id="GestionAvi" [(ngbCollapse)]="collapsables.gestionAvi">
        <div class="col-12 px-0">
            <div class="row">
                <a *ngIf="permisosModulosAcciones.PLANTILLAS" (click)="redirectTo('plantillas-listado', 'Gestión de Avisos', 'Plantillas')" class="padding-sub-option col-12">Plantillas</a>
                <a *ngIf="permisosModulosAcciones.AVISOS" (click)="redirectTo('avisos-listado', 'Gestión de Avisos', 'Avisos')" class="padding-sub-option col-12">Avisos</a>
            </div>
        </div>
    </div>
    <a *ngIf="
        permisosModulosAcciones.MANTFERI ||
        permisosModulosAcciones.MANTROL ||
        permisosModulosAcciones.MANTCAMPADI ||
        permisosModulosAcciones.MANTCAMPADIFIJ ||
        permisosModulosAcciones.DASHBOARD ||
        permisosModulosAcciones.MANTBLOQUEO
    " (click)="collapsables.mante = !collapsables.mante">
        <i class="material-icons">settings</i>
        <span class="icon-text">Mantenedores</span>
        <img src="../assets/img/desplegar-icono.svg" class="text-right pl-2">
    </a>
    <div class="row" id="Mante" [(ngbCollapse)]="collapsables.mante">
        <div class="col-12 px-0">
            <div class="row">
                <a *ngIf="permisosModulosAcciones.MANTFERI" (click)="redirectTo('crud-feriados', 'Mantenedores', 'Feriados')" class="padding-sub-option col-12">Feriados</a>
                <a *ngIf="permisosModulosAcciones.MANTROL" (click)="redirectTo('listar-roles', 'Mantenedores', 'Roles')" class="padding-sub-option col-12">Roles</a>
                <a *ngIf="permisosModulosAcciones.MANTCAMPADI" (click)="redirectTo('listar-campos', 'nofijo', 'Mantenedores', 'Campos Adicionales')" class="padding-sub-option col-12">Campos Adicionales</a>
                <a *ngIf="permisosModulosAcciones.MANTCAMPADIFIJ" (click)="redirectTo('listar-campos', 'fijo', 'Mantenedores', 'Campos Adicionales Fijos')" class="padding-sub-option col-12">Campos Adicionales <br/>Fijos</a>
                <a *ngIf="permisosModulosAcciones.DASHBOARD" (click)="redirectTo('dashboard-listado', 'Mantenedores', 'Dashboard Indicadores')" class="padding-sub-option col-12">Dashboard Indicadores</a>
                <a *ngIf="permisosModulosAcciones.MANTBLOQUEO" (click)="redirectTo('bloqueos-avisos/listar', 'Mantenedores', 'Bloqueos y Avisos de Sistema')" class="padding-sub-option col-12">Bloqueos y Avisos de <br/>Sistema</a>
            </div>
        </div>
    </div>
</div>

<ng-template #refModalNotificacion let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title" id="confirmarModal">Centro Notificación</h4>
        </div>
        <div class="modal-body col-xl-12 col-12">
            <div class="col-12">
                <p>Estimado Usuario:</p>

                <p [innerHtml]="cambiaMensaje(notificacionModalData.mensaje)"></p>
                <p>
                    <span class="font-weight-bold">Fecha: </span>{{notificacionModalData.fecha | date:'dd/MM/yyyy HH:mm:ss'}}
                </p>
                <p *ngIf="notificacionModalData.estado == 'success'">
                    <span *ngIf="notificacionModalData.modulo == 'Nominas' || notificacionModalData.modulo == 'nomina'">
                        <span class="font-weight-bold">Estado: </span> Cargado correctamente
                    </span>
                    <span *ngIf="notificacionModalData.modulo == 'Reporte Consolidado' || notificacionModalData.modulo == 'reporte'">
                       <span class="font-weight-bold">Estado: </span> Extraido correctamente
                    </span>
                </p>
                <p *ngIf="notificacionModalData.estado == 'warning'">
                    <span class="font-weight-bold">Estado: </span>Observaciones
                </p>
                <p *ngIf="notificacionModalData.estado == 'waiting'">
                    <span class="font-weight-bold">Estado: </span>Procesando
                </p>
                <p *ngIf="notificacionModalData.estado == 'error'">
                    <span class="font-weight-bold">Estado: </span>Error
                </p>
                <p *ngIf="notificacionModalData.link">
                    <span class="font-weight-bold">Descargar: </span><button class="btn btn-primary ml-2" (click)="descargarArchivo({bucket:notificacionModalData.bucket,link:notificacionModalData.link})"><img
                      src="assets/img/download.png"
                      class="icon-md mr-1">
              </button>
                </p>
                <!-- <ul>
                    <li>
                        mensaje: {{notificacionModalData.mensaje}}
                    </li>
                    <li>
                        fecha: {{notificacionModalData.fecha}}
                    </li>
                    <li>
                        modulo: {{notificacionModalData.modulo}}
                    </li>
                    <li>
                        sistema: {{notificacionModalData.sistema}}
                    </li>
                    <li>
                        estado: {{notificacionModalData.estado}}
                    </li>
                    <li *ngIf="notificacionModalData.link">
                        Descargar: <button class="btn btn-primary" (click)="descargarArchivo({bucket:notificacionModalData.bucket,link:notificacionModalData.link})"><img
            src="assets/img/download.png"
            class="icon-md mr-1">
          </button>
                    </li>
                </ul> -->
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="modal.close(true)" class="btn btn-primary">
        <img src="assets/img/ok.png" class="icon-md mr-1">Cerrar
      </button>
        </div>
    </div>
</ng-template>


<ng-template #refModalNotificaciones let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title" id="confirmarModal">Centro Notificaciónes</h4>
        </div>
        <div class="modal-body col-xl-12 col-12">
            <kendo-grid [data]="listadoNotificaciones" [navigable]="true" class="table table-striped" [height]="450">
                <kendo-grid-messages pagerFirstPage="Primera" pagerLastPage="Ultima" pagerPreviousPage="Anterior" pagerNextPage="Siguiente" noRecords="No hay datos disponibles." pagerItemsPerPage="cursos por pagina" pagerOf="de" pagerItems="cursos">
                </kendo-grid-messages>
                <kendo-grid-column [width]="290" field="mensaje" title="Mensaje">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div [innerHtml]="cambiaMensaje(dataItem.mensaje)"></div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fecha" title="Fecha">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.fecha | date :'dd/MM/yyyy HH:mm:ss' }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="modulo" title="Modulo"></kendo-grid-column>
                <!-- <kendo-grid-column field="sistema" title="Sistema"></kendo-grid-column> -->
                <kendo-grid-column field="estado" title="Estado">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div *ngIf="dataItem.estado == 'success'">
                            Exitoso
                        </div>
                        <div *ngIf="dataItem.estado == 'warning'">
                            Observaciones
                        </div>
                        <div *ngIf="dataItem.estado == 'waiting'">
                            Procesando
                        </div>
                        <div *ngIf="dataItem.estado == 'error'">
                            Error
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Descargar">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <button *ngIf="dataItem.link" class="btn btn-primary" (click)="descargarArchivo(dataItem)"><img
              src="assets/img/download.png"
              class="icon-md mr-1">
            </button>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
        <div class="modal-footer">
            <button (click)="modal.close(true)" class="btn btn-primary">
        <img src="assets/img/ok.png" class="icon-md mr-1">Cerrar
      </button>
        </div>
    </div>
</ng-template>
