import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AportesService = /** @class */ (function () {
    function AportesService(http) {
        this.http = http;
        console.log('AportesService: constructor');
    }
    /**
     * Retorna las fechas minimas y maxima de aportes para empresas seleccionadas
     * @param codigosEmpresa Listado de Codigo de Empresa seleccionada
     */
    AportesService.prototype.obtenerFechasAportes = function (codigosEmpresa) {
        var body = JSON.stringify(codigosEmpresa);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.APORTES + "/obtenerFechasAportes/", body, { headers: headers });
    };
    /**
     * Retorna el Reporte de Aportes
     * @param filtrosReporte Filtros Ingresados
     */
    AportesService.prototype.reporteAportes = function (filtrosReporte) {
        var body = JSON.stringify(filtrosReporte);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.APORTES + "/reporteAportes/", body, { headers: headers });
    };
    AportesService.ngInjectableDef = i0.defineInjectable({ factory: function AportesService_Factory() { return new AportesService(i0.inject(i1.HttpClient)); }, token: AportesService, providedIn: "root" });
    return AportesService;
}());
export { AportesService };
