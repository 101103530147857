<div class="dual-list">
  <div class="listbox">
    <p class="title-boxlist">Opciones del reporte disponibles:</p>
    <div class="record-picker">
      <ul class="box-list-ul" [ngClass]="{over:available.dragOver}"
        (drop)="drop($event, confirmed)" (dragover)="allowDrop($event, available)" (dragleave)="dragLeave()">
        <li *ngFor="let item of available.sift; let idx=index;"
          (click)="selectItem(available.pick, item); shiftClick($event, idx, available, item)"
          [ngClass]="{selected: isItemSelected(available.pick, item)}"
          draggable="true" (dragstart)="drag($event, item, available)" (dragend)="dragEnd(available)">
          <img class="dragdrop-button" src="../../../../assets/img/drag_indicator.svg">{{item._name}}
        </li>
      </ul>
    </div>
    <div class="button-box">
      <button type="button" class="btn btn-primary" (click)="moveAll()"
      [disabled]="isAllSelected(available)">
        Agregar todos
        <img class="selected-button" src="../../../../assets/img/keyboard_double_arrow_right.svg">
      </button>
      <button type="button" class="btn btn-primary" (click)="moveItem(available, confirmed)"
      [disabled]="available.pick.length === 0">
        Agregar selección
        <img class="selected-button" src="../../../../assets/img/arrow_forward.svg">
      </button>
    </div>
  </div>

  <div class="listbox">
    <p class="title-boxlist">Opciones del reporte agregadas:</p>
    <div class="record-picker">
      <ul class="box-list-ul" [ngClass]="{over:confirmed.dragOver}"
        (drop)="drop($event, available)" (dragover)="allowDrop($event, confirmed)" (dragleave)="dragLeave()">
        <li *ngFor="let item of confirmed.sift; let idx=index;"
          (click)="selectItem(confirmed.pick, item); shiftClick($event, idx, confirmed, item)"
          [ngClass]="{selected: isItemSelected(confirmed.pick, item)}"
          draggable="true" (dragstart)="drag($event, item, confirmed)" (dragend)="dragEnd(confirmed)">
          <img class="dragdrop-button" src="../../../../assets/img/drag_indicator.svg">{{item._name}}
        </li>
      </ul>
    </div>
    <div class="button-box">
      <button type="button" class="btn btn-primary" (click)="moveItem(confirmed, available)"
      [disabled]="confirmed.pick.length === 0">
        <img class="selected-button" src="../../../../assets/img/arrow_back.svg">
        Quitar selección
      </button>
      <button type="button" class="btn btn-primary" (click)="removeAll()"
      [disabled]="isAllSelected(confirmed)">
        <img class="selected-button" src="../../../../assets/img/keyboard_double_arrow_left.svg">
        Quitar todos
      </button>
    </div>
  </div>
</div>
