import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { CheckableSettings, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { Observable, forkJoin, of } from 'rxjs';

// Services
import { ClienteComercialService } from '../../services/cliente-comercial.service';
import { CartolaService } from '../../services/cartola.service';
import { LoggedInCallback } from '../../services/cognito.service';
import { UserLoginService } from '../../services/user-login.service';

// Models
import { EmpresasSucursalModel } from '../../models/empresasSucursal.model';

// Shared
import { GLOBALS, GlobalService } from '../../shared/globals.shared';
import { environment } from 'src/environments/environment';
import { PermisosCliComLocalStorageModel } from 'src/app/models/permisosCliComLocalStorage';
import { UserDataLocalStorageModel } from 'src/app/models/userDataLocalStorage';
import { EmpresaModel } from 'src/app/models/empresa.model';

@Component({
  selector: 'app-rep-cartola',
  templateUrl: './rep-cartola.component.html',
  styleUrls: ['./rep-cartola.component.scss'],
  providers: [GlobalService]
})
export class RepCartolaComponent implements OnInit, LoggedInCallback {
  min: Date = null;
  max: Date = null;
  urlGestion: string = environment.url_gestor;

  fechaActual: string  = [
    new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
    (new Date().getMonth() + 1).toString().length === 1 ?
      '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
    new Date().getFullYear()].join('/');

  empresasSucursales: any[] = [];
  parsedData: any[] = [];
  checkedKeys: any[] = [];
  searchTerm = '';
  nombreCC:string;
  empresasSucursalSeleccionadas: EmpresasSucursalModel[] = [];
  empresas: EmpresasSucursalModel[] = [];
  empresaSeleccionado: EmpresaModel = new EmpresaModel();
  fecIni: Date = null;
  fecFin: Date = null;
  userData: UserDataLocalStorageModel = {};

  errorFiltro = {
    empresaSucursal: false,
    fecIni: false,
    fecFin: false
  };

  cartolaCompleta: any = {
    // Cabecera
    aporteComprometido: 0,
    unoAnterior: 0,
    comision: {
      comisionPromedio: 0,
      comisionCollapse: true,
      comisiones: []
    },
    // Cuenta Normal y Reparto
    ctaNormalyReparto: 0,
    ctaNormalyRepartoCollapse: true,
    aporteImputado: {
      aporteImputadoTotal: 0,
      aporteImputadoCollapse: true,
      aportesImputados: [],
    },
    invCtaNormalyRep: {
      invCtaNormalyRepTotal: 0,
      invCtaNormalyRepCollapse: true,
      subClienteNormalyRep: []
    },
    topeInvVyTNormal: {
      topeInvVyTNormalTotal: 0,
      topeInvVyTNormalCollapse: true,
      topeInvVyTNormales: [],
    },
    invVyTNormal: {
      invVyTNormalTotal: 0,
      invVyTNormalCollapse: true,
      invVyTNormales: [],
    },
    comisionInterm: {
      comisionIntermTotal: 0,
      comisionIntermCollapse: true,
      comisionesIntermediadas: [],
    },
    comisionNoInterm: {
      comisionNoIntermTotal: 0,
      comisionNoIntermCollapse: true,
      comisionesNoIntermediadas: [],
    },
    // Cuenta Excedentes
    ctaExcedentes: 0,
    ctaExcedentesCollapse: true,
    saldoExcedentesLiq: {
      saldoExcedentesLiqTotal: 0,
      saldoExcedentesLiqCollapse: true,
      saldoExcedentesLiqs: []
    },
    invCtaExcedentes: {
      invCtaExcedentesTotal: 0,
      invCtaExcedentesCollapse: true,
      subClienteExcedentes: []
    },
    topeInvVyTExcedentes: {
      topeInvVyTExcedentesTotal: 0,
      topeInvVyTExcedentesCollapse: true,
      topeInvVyTExcedentes: [],
    },
    invVyTExcedentes: {
      invVyTExcedentesTotal: 0,
      invVyTExcedentesCollapse: true,
      invVyTExcedentes: [],
    },
    becasMandato: {
      becasMandatoTotal: 0,
      becasMandatoCollapse: true,
      becasMandatos: [],
    }
  };

  permisosModulosAcciones: any = {};
  permisos: Array<PermisosCliComLocalStorageModel>;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private globalService: GlobalService,
    private clienteComercialService: ClienteComercialService,
    private cartolaService: CartolaService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userLoginService: UserLoginService) {
    console.log('RepCartolaComponent: constructor');
  }

  ngOnInit() {
    console.log('RepCartolaComponent: OnInit');

    this.userLoginService.isAuthenticated(this);
    this.userData =  this.localStorageService.get('userData');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

        this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
        this.permisos = this.localStorageService.get('permisos');

        if (this.permisosModulosAcciones.REPOCART) {
          this.initListadoEmpresas();
          this.configuraFechas();
        } else {
          this.toastr.error(`No estás autorizado para ingresar a esta página`, GLOBALS.TOASTER.TITLE_ERROR);
          this.router.navigate(['/']);
        }

    }
  }

  /**
   * Configura las fechas del formulario
   */
  configuraFechas() {
    if (this.empresasSucursalSeleccionadas.length === 0) {
      this.min = null;
      this.max = null;
      this.fecIni = null;
      this.fecFin = null;
    }

    if (this.min === null && this.max === null) {
      const fechaActual = new Date();

      this.fecIni = new Date(fechaActual.getFullYear(), 0, 1);
      this.fecFin = new Date(fechaActual.getFullYear(), 11, 31);
      this.min = new Date(2006, 0, 1);
      this.max = this.fecFin;
    }
  }

  /**
   * Configura la fecha de Fin del Calendario
   */
  actualizarFechaFin(fecha: any) {
    if (fecha !== null && fecha !== undefined) {
      this.fecFin = new Date(fecha.getFullYear(), 11, 31);
    }
  }

  /**
   * Inicializa el listado de Empresas
   */
  initListadoEmpresas() {
    this.spinner.show();

    const sucursalesAutorizadas = this.permisos.map((item) => item.data.sucursalCodigo).join(',');

    if (sucursalesAutorizadas) {
      this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
        .subscribe(async (result) => {


            if (result.rows.length > 0) {
              this.empresas = result.rows.map((empresa) => new EmpresasSucursalModel(empresa));

              let index = 1;
              let codigoEmpresa: number;
              let rama: any;
              for (const empresa of this.empresas) {
                if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    value: empresa.codigoSucursal,
                    items: []
                  };
                } else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    items: [{
                      text: empresa.sucursal,
                      value: empresa.codigoSucursal
                    }]
                  };
                } else {
                  rama.items.push({
                    text: empresa.sucursal,
                    value: empresa.codigoSucursal
                  });
                }

                if (index === this.empresas.length) {
                  this.empresasSucursales.push(rama);
                }

                index++;
              }

              this.parsedData = this.empresasSucursales;
            } else {
              this.toastr.warning(`No hay información de Empresas / Sucursales para Reportes`, `Advertencia`);
            }

            this.spinner.hide();
          }, error => {
            console.log(error);
            this.spinner.hide();
            this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
          }
        );
    } else {
      this.toastr.warning(`No hay Empresas / Sucursales en Cliente Comercial`, `Advertencia`);
      this.spinner.hide();
    }

  }

  /**
   * Crea las Propiedades del TreeView
   */
  public get checkableSettings(): CheckableSettings {
    return {
        checkChildren: true,
        checkParents: true,
        mode: 'multiple',
        checkOnClick: false
    };
  }

  /**
   * Trae los hijos del árbol
   */
  public children = (dataItem: any): Observable<any[]> => of(dataItem.items);

  /**
   * Valida si tiene hijos el árbol
   */
  public hasChildren = (dataItem: any): boolean => !!dataItem.items;

  /**
   * Evento al presionar una tecla en la búsqueda
   * @param value Valor Ingresado
   */
  public onkeyup(value: string): void {
    this.parsedData = this.search(this.empresasSucursales, value);
  }

  /**
   * Búsqueda
   * @param items Items
   * @param term Término (Palabra)
   */
  public search(items: any[], term: string): any[] {
    return items.reduce((acc, item) => {
      if (this.contains(item.text, term)) {
        acc.push(item);
      } else if (item.items && item.items.length > 0) {
        const newItems = this.search(item.items, term);

        if (newItems.length > 0) {
          acc.push({ text: item.text, items: newItems });
        }
      }

      return acc;
    }, []);
  }

  /**
   * Compara el Texto y el Término ingresado
   * @param text Texto
   * @param term Término (Palabra)
   */
  public contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  }

  /**
   * Crea el array de empresas
   * @param itemLookup TreeItemLookup
   */
  public handleChecking(itemLookup: TreeItemLookup): void {
    if (this.checkedKeys.find(x => x === itemLookup.item.index) !== undefined) {
      if (itemLookup.children.length > 0) {
        for (const hijo of itemLookup.children) {
          this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(x => x !== hijo.item.dataItem.value);
        }
      } else {
        this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(x => x !== itemLookup.item.dataItem.value);
      }
    } else {
      if (itemLookup.children.length > 0) {
        for (const hijo of itemLookup.children) {
          this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(x => x !== hijo.item.dataItem.value);
          this.empresasSucursalSeleccionadas.push(hijo.item.dataItem.value);
        }
      } else {
        this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(x => x !== itemLookup.item.dataItem.value);
        this.empresasSucursalSeleccionadas.push(itemLookup.item.dataItem.value);
      }
    }

    this.configuraFechas();
  }

  /**
   * Filtra el Reporte
   */
  filtrarReporte() {
    this.errorFiltro = {
      empresaSucursal: false,
      fecIni: false,
      fecFin: false
    };

    if (this.empresasSucursalSeleccionadas.length === 0) {
      this.errorFiltro.empresaSucursal = true;
      return;
    } else {
      if (this.fecIni === null) {
        this.errorFiltro.fecIni = true;
      }
      if (this.fecFin === null) {
        this.errorFiltro.fecFin = true;
      }

      if (this.fecIni === null || this.fecFin === null) {
        return;
      }
    }

    const fechaIni: string  = [
      this.fecIni.getFullYear(),
      (this.fecIni.getMonth() + 1).toString().length === 1 ?
        '0' + (this.fecIni.getMonth() + 1).toString() : (this.fecIni.getMonth() + 1).toString(),
      this.fecIni.getDate().toString().length === 1 ? '0' + this.fecIni.getDate().toString() : this.fecIni.getDate().toString()].join('-');

    const fechaFin: string  = [
      this.fecFin.getFullYear(),
      (this.fecFin.getMonth() + 1).toString().length === 1 ?
        '0' + (this.fecFin.getMonth() + 1).toString() : (this.fecFin.getMonth() + 1).toString(),
      this.fecFin.getDate().toString().length === 1 ? '0' + this.fecFin.getDate().toString() : this.fecFin.getDate().toString()].join('-');

      this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
      const empresa = this.empresas.find(x => this.empresasSucursalSeleccionadas.filter((sucursal:any) => sucursal  == x.codigoSucursal).length > 0);
    const filtrosReporte = {
      cliComecial:this.nombreCC,
      empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
      fecIni: fechaIni,
      fecFin: fechaFin
    };

    this.spinner.show();
    console.log('BREAKPOINT');
    forkJoin([
      this.cartolaService.reporteCartola(filtrosReporte),
      this.clienteComercialService.obtenerEmpresa(empresa.codigoEmpresa)
    ]).subscribe(
      ([result,info]) => {
        this.spinner.hide();

        if(info.length > 0){
            this.empresaSeleccionado = new EmpresaModel(info[0]);
        }

        // Aporte Comprometido
        this.cartolaCompleta.aporteComprometido = 0;
        if (result.aporteComprometido.length > 0) {
          for (const obj of result.aporteComprometido) {
            this.cartolaCompleta.aporteComprometido = this.cartolaCompleta.aporteComprometido + obj.valor;
          }
        }

        // 1% año anterior
        this.cartolaCompleta.unoAnterior = 0;
        if (result.datosCierre.rows.length > 0) {
          this.cartolaCompleta.unoAnterior = parseInt(result.datosCierre.rows[0].klimk,10);
        }

        // Comision
        this.cartolaCompleta.comision.comisionCollapse = true;
        this.cartolaCompleta.comision.comisiones = [];
        this.cartolaCompleta.comision.comisionPromedio = 0;
  
        const comisionEmpresa = result.comision.find((emp:any) => emp.codigo == this.empresaSeleccionado.Codigo);
        if(comisionEmpresa){
          this.cartolaCompleta.comision.comisionPromedio = parseFloat(comisionEmpresa.tasa);
        }

        for(const comision of result.comision){
          const empresa = this.empresas.find((emp) => emp.codigoSucursal == comision.codigo);
          this.cartolaCompleta.comision.comisiones.push({
            nombreempresa:empresa.razonSocial,
            nombresucursal:empresa.sucursal,
            tasa_por:comision.tasa,
            cod_sucursal:String(empresa.codigoSucursal)
          });
        }

        // Aporte Imputado
        this.cartolaCompleta.aporteImputado.aporteImputadoCollapse = true;
        this.cartolaCompleta.aporteImputado.aportesImputados = [];
        this.cartolaCompleta.aporteImputado.aporteImputadoTotal = 0;
        if (result.aporteImputado.length > 0) {
          for (const obj of result.aporteImputado) {
            this.cartolaCompleta.aporteImputado.aporteImputadoTotal =
              this.cartolaCompleta.aporteImputado.aporteImputadoTotal + parseInt(obj.aporteimpdisp, 10);
            this.cartolaCompleta.aporteImputado.aportesImputados.push(obj);
          }
        }

        // Total Inversión en Capacitación (Normal y Reparto)
        this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepCollapse = true;
        this.cartolaCompleta.invCtaNormalyRep.subClienteNormalyRep = [];
        this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal = 0;
        if (result.subClienteInscripcionesNormalYReparto.length > 0) {
          for (const obj of result.subClienteInscripcionesNormalYReparto) {
            this.cartolaCompleta.invCtaNormalyRep.subClienteNormalyRep.push(obj);

            this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal =
              this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal +
              parseInt(obj.inscripciones.cuentaNormal, 10) +
              parseInt(obj.inscripciones.cuentaReparto, 10) +
              parseInt(obj.inscripciones.eyccl, 10);
          }
        }

        // Tope Inversión Viáticos y Traslados (Normal y Reparto)
        this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalCollapse = true;
        this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales = [];
        this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal = 0;
        const datosCierreAnterior = result.datosCierreAnterior.rows.filter((obj) => obj.cta_exc == 1000);
        if (datosCierreAnterior.length > 0) {
          for (const obj of datosCierreAnterior) {
            //Tope VyT es el 10% del 1% de los aportes del año anterior
            const topeNormal = Math.round(parseInt(obj.klimk, 10) * 0.1);
            this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales.push({
              nombreempresa: obj.nombreempresa,
              nombresucursal: obj.nombresucursal,
              tope: topeNormal
            });

            this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal =
              this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal + topeNormal;
          }
        }

        // Total Inversión Viáticos y Traslados (Normal y Reparto)
        this.cartolaCompleta.invVyTNormal.invVyTNormalCollapse = true;
        this.cartolaCompleta.invVyTNormal.invVyTNormales = [];
        this.cartolaCompleta.invVyTNormal.invVyTNormalTotal = 0;
        if (result.subClienteInscripcionesNormalYReparto.length > 0) {
          for (const obj of result.subClienteInscripcionesNormalYReparto) {
            this.cartolaCompleta.invVyTNormal.invVyTNormales.push({
              sucursal: obj.sucursal,
              subCliente: obj.subCliente,
              vyt: obj.inscripciones.vyt
            });

            this.cartolaCompleta.invVyTNormal.invVyTNormalTotal =
              this.cartolaCompleta.invVyTNormal.invVyTNormalTotal + parseInt(obj.inscripciones.vyt, 10);
          }
        }


        // Total Comisión sobre Intermediación (Normal y Reparto)
        this.cartolaCompleta.comisionInterm.comisionIntermCollapse = true;
        this.cartolaCompleta.comisionInterm.comisionesIntermediadas = [];
        this.cartolaCompleta.comisionInterm.comisionIntermTotal = 0;

        if (result.subClienteInscripcionesNormalYReparto.length > 0) {
          for (const obj of result.subClienteInscripcionesNormalYReparto) {
            /*const comision = this.cartolaCompleta.comision.comisiones.find(x => x.cod_sucursal === obj.sucursal).tasa_por;
            const invVyt = this.cartolaCompleta.invVyTNormal.invVyTNormales.find(x => x.sucursal === obj.sucursal);

            const valorCurso =
              parseInt(obj.inscripciones.cuentaNormal, 10) +
              parseInt(obj.inscripciones.cuentaReparto, 10) +
              parseInt(obj.inscripciones.eyccl, 10) +
              (invVyt !== undefined ? parseInt(invVyt.vyt, 10) : 0);

            const tasaComision = comision !== undefined ? comision / 100 : 0;
            const comisionInterm = Math.round((valorCurso / (1 - tasaComision)) - valorCurso);*/
            const comision = this.cartolaCompleta.comision.comisiones.find(x => x.cod_sucursal === obj.sucursal);
            const totalIntermediado = parseInt(obj.inscripciones.cuentaNormal, 10) + parseInt(obj.inscripciones.cuentaReparto, 10) + parseInt(obj.inscripciones.eyccl, 10)
            const comisionInterm = Math.round(totalIntermediado * (parseFloat(comision.tasa_por) / 100));
            
            this.cartolaCompleta.comisionInterm.comisionesIntermediadas.push({
              sucursal: obj.sucursal,
              subCliente: obj.subCliente,
              comision: comisionInterm
            });

            this.cartolaCompleta.comisionInterm.comisionIntermTotal =
              this.cartolaCompleta.comisionInterm.comisionIntermTotal + comisionInterm;
          }
        }

        // Total Comisión sobre Saldos no Intevertidos (Normal y Reparto)
        this.cartolaCompleta.comisionNoInterm.comisionNoIntermCollapse = true;
        this.cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas = [];
        this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal = 0;
        for(const aporte of this.cartolaCompleta.aporteImputado.aportesImputados){
          let inversionCursos = 0;
          const comisiones = this.cartolaCompleta.comision.comisiones.find(x => x.nombreempresa === aporte.nombreempresa);
          const inscripcionesNormalyRep = result.subClienteInscripcionesNormalYReparto.filter((obj) => obj.sucursal == aporte.sucursal);
          if(inscripcionesNormalyRep.length > 0){
            for(const obj of inscripcionesNormalyRep){
              inversionCursos += (parseInt(obj.inscripciones.cuentaNormal, 10) + parseInt(obj.inscripciones.cuentaReparto, 10) + parseInt(obj.inscripciones.eyccl, 10));
            }
          }
          if(comisiones){
            const tasa = parseFloat(comisiones.tasa_por)
            const comision = Math.trunc((parseInt(aporte.aporteimpdisp, 10) - inversionCursos) * (tasa / 100));
            this.cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas.push({
              subCliente: aporte.nombreempresa === aporte.nombresucursal ?
                              aporte.nombreempresa :
                              aporte.nombreempresa + ' - Sucursal: ' + aporte.nombresucursal,
              comision: comision
            });

            this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal = 
              this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal + comision;
          }
        }

        this.cartolaCompleta.ctaNormalyRepartoCollapse = true;
        this.cartolaCompleta.ctaNormalyReparto =
          this.cartolaCompleta.aporteImputado.aporteImputadoTotal -
          this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal -
          this.cartolaCompleta.invVyTNormal.invVyTNormalTotal -
          this.cartolaCompleta.comisionInterm.comisionIntermTotal -
          this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal;

        // Saldo Excedentes Líquido (Excedentes)
        this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqCollapse = true;
        this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs = [];
        this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal = 0;
        if (result.datosCierre.rows.length > 0) {
          for (const obj of result.datosCierre.rows) {
            /*const comisionAnterior = result.comisionAnioAnterior.find(x => x.sucursal === obj.sucursal);
            const inversionAnterior = result.inscripcionesAnioAnterior.find(x => x.sucursal === obj.sucursal);
            const saldoExcedentes =
              parseInt(obj.aporteimpdisp, 10) -
              (inversionAnterior !== undefined ? parseInt(inversionAnterior.costootic, 10) : 0) -
              (Math.round((inversionAnterior !== undefined ? parseInt(inversionAnterior.costootic, 10) : 0) *
              (comisionAnterior !== undefined ? parseInt(comisionAnterior.valor, 10) / 100 : 0)) +
              (inversionAnterior !== undefined ? parseInt(inversionAnterior.costovyt, 10) : 0));*/

            const saldoExcedentes = parseInt(obj.exc_liqu, 10);

            this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs.push({
              nombreempresa: obj.nombreempresa,
              nombresucursal: obj.nombresucursal,
              saldo: saldoExcedentes
            });

            this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal =
              this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal + saldoExcedentes;
          }
        }

        // Total Inversión en Capacitación (Excedentes)
        this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesCollapse = true;
        this.cartolaCompleta.invCtaExcedentes.subClienteExcedentes = [];
        this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal = 0;
        if (result.subClienteInscripcionesExcedentes.length > 0) {
          for (const obj of result.subClienteInscripcionesExcedentes) {
            this.cartolaCompleta.invCtaExcedentes.subClienteExcedentes.push(obj);

            this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal =
              this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal +
              parseInt(obj.inscripciones.cuentaNormal, 10) +
              parseInt(obj.inscripciones.cuentaReparto, 10) +
              parseInt(obj.inscripciones.eyccl, 10);
          }
        }

        // Tope Inversión Viáticos y Traslados (Excedentes)
        this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesCollapse = true;
        this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes = [];
        this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal = 0;

        if (result.aporteImputadoAnioAnterior.length > 0) {
          for (const obj of result.aporteImputadoAnioAnterior) {
            /*const inversionVyT = result.inscripcionesAnioAnterior.find(x => x.sucursal === obj.sucursal);
            const topeExcedentes =
              Math.round(parseInt(obj.aporteimpdisp, 10) * 0.1) - (inversionVyT !== undefined ? inversionVyT.costovyt : 0);*/

            const datosCierreAnterior = result.datosCierreAnterior.rows.find(x => x.cod_sucursal === obj.sucursal);
            //Inicializar si es que no hay datos
            let vt_costo = '0';
            let via_tras = '0';

            if(datosCierreAnterior) {
                vt_costo = datosCierreAnterior.vt_costo;
                via_tras = datosCierreAnterior.via_tras;
            }
            const topeExcedentes = Math.round((parseInt(obj.aporteimpdisp, 10) * 0.1)
                                   - parseInt(vt_costo, 10)
                                   - parseInt(via_tras, 10));

            this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes.push({
              nombreempresa: obj.nombreempresa,
              nombresucursal: obj.nombresucursal,
              tope: topeExcedentes
            });

            this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal =
              this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal + topeExcedentes;
          }
        }

        // Total Inversión Viáticos y Traslados (Excedentes)
        this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesCollapse = true;
        this.cartolaCompleta.invVyTExcedentes.invVyTExcedentes = [];
        this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal = 0;
        if (result.subClienteInscripcionesExcedentes.length > 0) {
          for (const obj of result.subClienteInscripcionesExcedentes) {
            this.cartolaCompleta.invVyTExcedentes.invVyTExcedentes.push({
              sucursal: obj.sucursal,
              subCliente: obj.subCliente,
              vyt: obj.inscripciones.vyt
            });

            this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal =
              this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal + parseInt(obj.inscripciones.vyt, 10);
          }
        }

        // Becas Mandato
        this.cartolaCompleta.becasMandato.becasMandatoCollapse = true;
        this.cartolaCompleta.becasMandato.becasMandatos = [];
        this.cartolaCompleta.becasMandato.becasMandatoTotal = 0;
        if (result.becasMandato.length > 0) {
          for (const obj of result.becasMandato) {
            this.cartolaCompleta.becasMandato.becasMandatos.push(obj);

            this.cartolaCompleta.becasMandato.becasMandatoTotal = this.cartolaCompleta.becasMandato.becasMandatoTotal + obj.valor;
          }
        }

        this.cartolaCompleta.ctaExcedentesCollapse = true;
         this.cartolaCompleta.ctaExcedentes =
        this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal - //(**)
        this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal - //(**)
        this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal -
        this.cartolaCompleta.becasMandato.becasMandatoTotal;

        console.log(this.cartolaCompleta);

        if (result.clienteCerrado.toString() === 'No') {
          this.toastr.warning('Cliente no está cerrado, los valores pueden variar', GLOBALS.TOASTER.TITLE_INFO);
        }
      },
      error => {
        console.log(error);
        this.spinner.hide();
        this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
      }
    );
  }

  /**
   * Filtra Reporte al presionar tecla Enter
   * @param event Evento
   */
  filtrarReporteKeydown(event) {
    if (event.key === 'Enter') {
      this.filtrarReporte();
    }
  }
}
