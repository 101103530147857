import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { of } from 'rxjs';
import * as moment from 'moment';
import { Workbook } from '@progress/kendo-ooxml';
import { saveAs } from '@progress/kendo-file-saver';
var lzwCompress = require('lzwcompress');
import { ClienteAuditoria } from 'otic-cliente-auditoria';
// Services
import { UserLoginService } from '../../services/user-login.service';
import { ClienteComercialService } from '../../services/cliente-comercial.service';
// Models
import { EmpresasSucursalModel } from 'src/app/models/empresasSucursal.model';
// Shared
import { environment } from '../../../environments/environment';
import { GLOBALS } from '../../shared/globals.shared';
import { ConsolidadoService } from '../../services/consolidado.service';
var RepConsolidadoCursosComponent = /** @class */ (function () {
    function RepConsolidadoCursosComponent(spinner, toastr, router, localStorageService, clienteComercialService, userLoginService, consolidadoService) {
        this.spinner = spinner;
        this.toastr = toastr;
        this.router = router;
        this.localStorageService = localStorageService;
        this.clienteComercialService = clienteComercialService;
        this.userLoginService = userLoginService;
        this.consolidadoService = consolidadoService;
        this.urlGestion = environment.url_gestor;
        this.min = null;
        this.max = null;
        this.fechaActual = [
            new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
            (new Date().getMonth() + 1).toString().length === 1 ?
                '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
            new Date().getFullYear()
        ].join('/');
        this.sinPVs = false;
        this.empresasSucursales = [];
        this.parsedData = [];
        this.checkedKeys = [];
        this.searchTerm = '';
        this.userData = {};
        this.empresasSucursalSeleccionadas = [];
        this.prevEmpresasSucursalSeleccionadas = [];
        this.fecIni = null;
        this.fecFin = null;
        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false
        };
        this.listFecIni = [];
        this.listFecFin = [];
        this.consolidado = [];
        this.camposAdicACCabecera = [];
        this.camposAdicAC = [];
        this.camposAdicPartCabecera = [];
        this.camposAdicPart = [];
        this.permisosModulosAcciones = {};
        this.checkAnulados = false;
        this.checkCASeparados = false;
        this.checkCASoloCC = false;
        /**
         * Trae los hijos del árbol
         */
        this.children = function (dataItem) { return of(dataItem.items); };
        /**
         * Valida si tiene hijos el árbol
         */
        this.hasChildren = function (dataItem) { return !!dataItem.items; };
        console.log('RepAportesImputadosComponent: constructor');
    }
    /**
     * OnInit Event
     */
    RepConsolidadoCursosComponent.prototype.ngOnInit = function () {
        console.log('RepAportesImputadosComponent: OnInit');
        this.userLoginService.isAuthenticated(this);
        this.userData = this.localStorageService.get('userData');
    };
    RepConsolidadoCursosComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        if (isLoggedIn) {
            this.router.events.subscribe(function (evt) {
                if (!(evt instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
            this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
            this.permisos = this.localStorageService.get('permisos');
            if (this.permisosModulosAcciones.REPCUR) {
                this.initListadoEmpresas();
            }
            else {
                this.toastr.error("No est\u00E1s autorizado para ingresar a esta p\u00E1gina", GLOBALS.TOASTER.TITLE_ERROR);
                this.router.navigate(['/']);
            }
        }
    };
    /**
     * Inicializa el listado de Empresas
     */
    RepConsolidadoCursosComponent.prototype.initListadoEmpresas = function () {
        var _this = this;
        this.spinner.show();
        var sucursalesAutorizadas = this.permisos.map(function (item) { return item.data.sucursalCodigo; }).join(',');
        if (sucursalesAutorizadas) {
            this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
                .subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var empresas, index, codigoEmpresa, rama, _i, empresas_1, empresa;
                return tslib_1.__generator(this, function (_a) {
                    if (result.rows.length > 0) {
                        empresas = result.rows.map(function (empresa) { return new EmpresasSucursalModel(empresa); });
                        index = 1;
                        codigoEmpresa = void 0;
                        rama = void 0;
                        for (_i = 0, empresas_1 = empresas; _i < empresas_1.length; _i++) {
                            empresa = empresas_1[_i];
                            if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    value: empresa.codigoEmpresa,
                                    items: []
                                };
                            }
                            else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    items: [{
                                            text: empresa.sucursal,
                                            value: empresa.codigoEmpresa
                                        }]
                                };
                            }
                            else {
                                rama.items.push({
                                    text: empresa.sucursal,
                                    value: empresa.codigoEmpresa
                                });
                            }
                            if (index === empresas.length) {
                                this.empresasSucursales.push(rama);
                            }
                            index++;
                        }
                        this.parsedData = this.empresasSucursales;
                        console.log('this.parsedData', this.parsedData);
                        this.listadoFinal = [{
                                text: 'Seleccionar Todos',
                                value: 'Todos',
                                items: this.parsedData.map(function (x) {
                                    return {
                                        text: x.text,
                                        value: x.value,
                                        items: x.items
                                    };
                                })
                            }];
                        console.log('prueba', this.listadoFinal);
                    }
                    else {
                        this.toastr.warning("No hay informaci\u00F3n de Empresas / Sucursales para Reportes", "Advertencia");
                    }
                    this.spinner.hide();
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                _this.spinner.hide();
                _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            });
        }
        else {
            this.toastr.warning("No hay Empresas / Sucursales en Cliente Comercial", "Advertencia");
            this.spinner.hide();
        }
    };
    Object.defineProperty(RepConsolidadoCursosComponent.prototype, "checkableSettings", {
        /**
         * Crea las Propiedades del TreeView
         */
        get: function () {
            return {
                checkChildren: true,
                checkParents: true,
                mode: 'multiple',
                checkOnClick: false
            };
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Evento al presionar una tecla en la búsqueda
     * @param value Valor Ingresado
     */
    RepConsolidadoCursosComponent.prototype.onkeyup = function (value) {
        this.parsedData = this.search(this.empresasSucursales, value);
        if (this.parsedData.length > 0) {
            this.listadoFinal = [{
                    text: 'Seleccionar Todos',
                    value: 'Todos',
                    items: this.parsedData.map(function (x) {
                        return {
                            text: x.text,
                            value: x.value,
                            items: x.items
                        };
                    })
                }];
            console.log('prueba', this.listadoFinal);
        }
        else {
            this.listadoFinal = [];
        }
    };
    /**
     * Búsqueda
     * @param items Items
     * @param term Término (Palabra)
     */
    RepConsolidadoCursosComponent.prototype.search = function (items, term) {
        var _this = this;
        return items.reduce(function (acc, item) {
            if (_this.contains(item.text, term)) {
                acc.push(item);
            }
            else if (item.items && item.items.length > 0) {
                var newItems = _this.search(item.items, term);
                if (newItems.length > 0) {
                    acc.push({ text: item.text, items: newItems });
                }
            }
            return acc;
        }, []);
    };
    /**
     * Compara el Texto y el Término ingresado
     * @param text Texto
     * @param term Término (Palabra)
     */
    RepConsolidadoCursosComponent.prototype.contains = function (text, term) {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    };
    /**
     * Crea el array de empresas
     * @param itemLookup TreeItemLookup
     */
    RepConsolidadoCursosComponent.prototype.handleChecking = function (itemLookup) {
        this.empresasSucursalSeleccionadas = [];
        if (this.checkedKeys.find(function (x) { return x === itemLookup.item.index; }) !== undefined) {
            if (itemLookup.children.length > 0) {
                var _loop_1 = function (hijo) {
                    var aux = hijo.item.dataItem.items;
                    if (aux) {
                        if (hijo.item.dataItem.items.length > 0) {
                            var x = void 0;
                            var _loop_3 = function () {
                                var valor = {
                                    value: x.value,
                                    text: x.text
                                };
                                this_1.prevEmpresasSucursalSeleccionadas = this_1.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor.value || x.text !== valor.text; });
                            };
                            for (var _i = 0, _a = hijo.item.dataItem.items; _i < _a.length; _i++) {
                                x = _a[_i];
                                _loop_3();
                            }
                        }
                        else {
                            var valor_1 = {
                                value: hijo.item.dataItem.value,
                                text: hijo.item.dataItem.text
                            };
                            this_1.prevEmpresasSucursalSeleccionadas = this_1.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_1.value || x.text !== valor_1.text; });
                        }
                    }
                    else {
                        var valor_2 = {
                            value: hijo.item.dataItem.value,
                            text: hijo.item.dataItem.text
                        };
                        this_1.prevEmpresasSucursalSeleccionadas = this_1.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_2.value || x.text !== valor_2.text; });
                    }
                };
                var this_1 = this;
                for (var _i = 0, _a = itemLookup.children; _i < _a.length; _i++) {
                    var hijo = _a[_i];
                    _loop_1(hijo);
                }
            }
            else {
                var valor_3 = {
                    value: itemLookup.item.dataItem.value,
                    text: itemLookup.item.dataItem.text
                };
                this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_3.value || x.text !== valor_3.text; });
            }
        }
        else {
            if (itemLookup.children.length > 0) {
                var _loop_2 = function (hijo) {
                    var aux = hijo.item.dataItem.items;
                    if (aux) {
                        if (hijo.item.dataItem.items.length > 0) {
                            var x = void 0;
                            var _loop_4 = function () {
                                var valor = {
                                    value: x.value,
                                    text: x.text
                                };
                                this_2.prevEmpresasSucursalSeleccionadas = this_2.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor.value || x.text !== valor.text; });
                                this_2.prevEmpresasSucursalSeleccionadas.push(valor);
                            };
                            for (var _i = 0, _a = hijo.item.dataItem.items; _i < _a.length; _i++) {
                                x = _a[_i];
                                _loop_4();
                            }
                        }
                        else {
                            var valor_4 = {
                                value: hijo.item.dataItem.value,
                                text: hijo.item.dataItem.text
                            };
                            this_2.prevEmpresasSucursalSeleccionadas = this_2.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_4.value || x.text !== valor_4.text; });
                            this_2.prevEmpresasSucursalSeleccionadas.push(valor_4);
                        }
                    }
                    else {
                        var valor_5 = {
                            value: hijo.item.dataItem.value,
                            text: hijo.item.dataItem.text
                        };
                        this_2.prevEmpresasSucursalSeleccionadas = this_2.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_5.value || x.text !== valor_5.text; });
                        this_2.prevEmpresasSucursalSeleccionadas.push(valor_5);
                    }
                };
                var this_2 = this;
                for (var _b = 0, _c = itemLookup.children; _b < _c.length; _b++) {
                    var hijo = _c[_b];
                    _loop_2(hijo);
                }
            }
            else {
                var valor_6 = {
                    value: itemLookup.item.dataItem.value,
                    text: itemLookup.item.dataItem.text
                };
                this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_6.value || x.text !== valor_6.text; });
                this.prevEmpresasSucursalSeleccionadas.push(valor_6);
            }
        }
        console.log('this.prevEmpresasSucursalSeleccionadas: ', this.prevEmpresasSucursalSeleccionadas);
        for (var _d = 0, _e = this.prevEmpresasSucursalSeleccionadas; _d < _e.length; _d++) {
            var a = _e[_d];
            this.empresasSucursalSeleccionadas.push(a.value);
        }
        this.inicializaFechas();
    };
    /**
     * Busca las fechas de Inicio y Fin de las PV al cambiar la selección
     */
    RepConsolidadoCursosComponent.prototype.inicializaFechas = function () {
        var _this = this;
        this.fecIni = null;
        this.fecFin = null;
        this.sinPVs = false;
        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false
        };
        if (this.empresasSucursalSeleccionadas.length > 0) {
            var rutsEmpresa = this.empresasSucursalSeleccionadas.join(',');
            // const rutsEmpresa = `'88566900-K', '96731890-6'`;
            this.spinner.show();
            this.consolidadoService.obtenerFechasPVs(rutsEmpresa).subscribe(function (result) {
                _this.spinner.hide();
                if (result.minanio === null) {
                    _this.sinPVs = true;
                }
                else {
                    _this.min = new Date(result.minanio.substring(0, 19));
                    _this.max = new Date(result.maxanio.substring(0, 19));
                    _this.fecIni = _this.min;
                    _this.fecFin = _this.max;
                    // this.min = result.minanio.toString();
                    // this.max = result.maxanio.toString();
                    // this.fecIni = this.min;
                    // this.fecFin = this.max;
                    // this.listFecIni = [];
                    // this.listFecFin = [];
                    // for (let z = this.min; z <= this.max; z++) {
                    //   this.listFecIni.push(z.toString());
                    //   this.listFecFin.push(z.toString());
                    // }
                }
            }, function (error) {
                console.log(error);
                _this.spinner.hide();
                _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            });
        }
        else {
            if (this.empresasSucursales.length > 0) {
                this.errorFiltro.empresaSucursal = true;
            }
        }
    };
    /**
     * Configura las fechas de inicio y fin seleccionables cuando cambia el valor
     */
    RepConsolidadoCursosComponent.prototype.configuraFechas = function () {
        // this.spinner.show();
        // this.listFecIni = [];
        // this.listFecFin = [];
        // for (let z = this.min; z <= (this.fecFin !== undefined ? this.fecFin : this.max); z++) {
        //   this.listFecIni.push(z.toString());
        // }
        // for (let z = (this.fecIni !== undefined ? this.fecIni : this.min); z <= this.max; z++) {
        //   this.listFecFin.push(z.toString());
        // }
        // this.spinner.hide();
    };
    /**
     * Filtra el Reporte
     */
    RepConsolidadoCursosComponent.prototype.filtrarReporte = function () {
        var _this = this;
        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false
        };
        if (this.empresasSucursalSeleccionadas.length === 0) {
            this.errorFiltro.empresaSucursal = true;
            return;
        }
        else {
            if (this.fecIni === undefined || this.fecIni === null) {
                this.errorFiltro.fecIni = true;
            }
            if (this.fecFin === undefined || this.fecFin === null) {
                this.errorFiltro.fecFin = true;
            }
            if (this.fecIni === undefined || this.fecIni === null || this.fecFin === undefined || this.fecFin === null) {
                return;
            }
        }
        this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
        var filtrosReporte = {
            cliComercial: this.nombreCC,
            empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
            // empresaSucursal: `'88566900-K', '93658000-9'`,
            fecIni: moment(this.fecIni).format('L'),
            fecFin: moment(this.fecFin).format('L'),
            usuario: this.userData.rut,
            email: this.userData.email,
            anulados: this.checkAnulados,
            caseparados: this.checkCASeparados,
            cacc: this.checkCASoloCC
        };
        this.spinner.show();
        this.consolidadoService.reporteConsolidadoCursosStart(filtrosReporte).subscribe(function (result) {
            console.log('Proceso iniciado');
            _this.accion('Reporte Consolidado de Cursos', '', 'Genera Reporte para ' + _this.nombreCC);
            _this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
            _this.spinner.hide();
        }, function (error) {
            console.log('No es posible descargar el archivo', error);
            _this.spinner.hide();
            _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        });
    };
    /**
     * Metodo para exportar a Excel
     * @param grid Grid
     */
    RepConsolidadoCursosComponent.prototype.exportToExcel = function () {
        this.spinner.show();
        var workbook = new Workbook({
            sheets: [{
                    columns: [],
                    rows: [{ cells: [{}] }]
                }]
        });
        workbook.options.sheets[0].name = 'Consolidado';
        var columnas = Object.keys(this.consolidado[0]);
        workbook.options.sheets[0].rows[0].cells[0].colSpan =
            columnas.length + this.camposAdicACCabecera.length - 1 + this.camposAdicPartCabecera.length - 1;
        workbook.options.sheets[0].rows[0].cells[0].value = "Fecha Informe: " + this.fechaActual;
        workbook.options.sheets[0].rows.push({ cells: [] });
        var index = 0;
        for (var _i = 0, columnas_1 = columnas; _i < columnas_1.length; _i++) {
            var valor = columnas_1[_i];
            var columna = void 0;
            columna = valor;
            if (index === 31) {
                for (var _a = 0, _b = this.camposAdicACCabecera; _a < _b.length; _a++) {
                    var nombreCAAC = _b[_a];
                    columna = nombreCAAC.nombre;
                    workbook.options.sheets[0].rows[1].cells.push({
                        background: '#184D9B',
                        bold: true,
                        color: '#ffffff',
                        value: columna
                    });
                    workbook.options.sheets[0].columns.push({ autoWidth: true });
                }
            }
            else if (index === 56) {
                for (var _c = 0, _d = this.camposAdicPartCabecera; _c < _d.length; _c++) {
                    var nombreCAPart = _d[_c];
                    columna = nombreCAPart.nombre;
                    workbook.options.sheets[0].rows[1].cells.push({
                        background: '#184D9B',
                        bold: true,
                        color: '#ffffff',
                        value: columna
                    });
                    workbook.options.sheets[0].columns.push({ autoWidth: true });
                }
            }
            else {
                switch (valor) {
                    case 'nropv':
                        columna = 'Nº PV';
                        break;
                    case 'tipodocumento':
                        columna = 'Tipo de Documento';
                        break;
                    case 'cuentafinanciamiento':
                        columna = 'Cuenta de Financiamiento';
                        break;
                    case 'tipocurso':
                        columna = 'Tipo de Curso';
                        break;
                    case 'modalidadcapacitacion':
                        columna = 'Modalidad de Capacitación';
                        break;
                    case 'modalidadcapacitaciondesc':
                        columna = 'Modalidad: Descripción';
                        break;
                    case 'clasepedido':
                        columna = 'Clase de Pedido';
                        break;
                    case 'clasepedidodesc':
                        columna = 'Clase de Pedido: Descripción';
                        break;
                    case 'modalidadinstruccion':
                        columna = 'Modalidad de Instrucción';
                        break;
                    case 'modalidadinstrucciondesc':
                        columna = 'Modalidad de Instrucción: Descripción';
                        break;
                    case 'fechainicio':
                        columna = 'Fecha Inicio';
                        break;
                    case 'fechatermino':
                        columna = 'Fecha Termino';
                        break;
                    case 'viaticosrendidos':
                        columna = 'Viáticos Rendidos';
                        break;
                    case 'asistenciarendida':
                        columna = 'Asistencia Rendida';
                        break;
                    case 'codigosence':
                        columna = 'Código Sence';
                        break;
                    case 'nombrecurso':
                        columna = 'Nombre del Curso';
                        break;
                    case 'horasautorizadas':
                        columna = 'Horas Autorizadas';
                        break;
                    case 'horasrealizadas':
                        columna = 'Horas Realizadas';
                        break;
                    case 'statusdescripcion':
                        columna = 'Status Descripción';
                        break;
                    case 'statusrechazo':
                        columna = 'Status Rechazo';
                        break;
                    case 'nombreotec':
                        columna = 'Nombre OTEC';
                        break;
                    case 'sedeotec':
                        columna = 'Sede OTEC';
                        break;
                    case 'nroparticipantes':
                        columna = 'Nº Participantes';
                        break;
                    case 'costocursootic':
                        columna = 'Costo Curso OTIC';
                        break;
                    case 'costoviaticootic':
                        columna = 'Costo Viatico OTIC';
                        break;
                    case 'costotrasladootic':
                        columna = 'Costo Traslado OTIC';
                        break;
                    case 'costocursoempresa':
                        columna = 'Costo Curso Empresa';
                        break;
                    case 'costoviaticoempresa':
                        columna = 'Costo Viatico Empresa';
                        break;
                    case 'costotrasladoempresa':
                        columna = 'Costo Traslado Empresa';
                        break;
                    case 'comision':
                        columna = 'Comisión';
                        break;
                    case 'complementaria':
                        columna = 'Complementaria';
                        break;
                    // Campos AC - Posicion 31
                    case 'nombreholding':
                        columna = 'Nombre Holding';
                        break;
                    case 'clientefinancia':
                        columna = 'Cliente que Franquicia';
                        break;
                    case 'sucursal':
                        columna = 'Sucursal';
                        break;
                    case 'areaconocimientocurso':
                        columna = 'Area de Conocimiento Curso';
                        break;
                    case 'especialidadcurso':
                        columna = 'Especialidad curso';
                        break;
                    case 'ciudadejecucion':
                        columna = 'Ciudad de Ejecución';
                        break;
                    case 'region':
                        columna = 'Región';
                        break;
                    case 'direccionejecucion':
                        columna = 'Dirección Lugar de Ejecución';
                        break;
                    case 'unohistorico':
                        columna = '1 % Historico';
                        break;
                    case 'dnc':
                        columna = 'Detección de Nececidades';
                        break;
                    case 'cbp':
                        columna = 'Comité Bipartito';
                        break;
                    case 'rut':
                        columna = 'Rut';
                        break;
                    case 'codtipodocumento':
                        columna = 'Cod.Tipo de Documento';
                        break;
                    case 'nombre':
                        columna = 'Nombre';
                        break;
                    case 'appaterno':
                        columna = 'Ap.Paterno';
                        break;
                    case 'apmaterno':
                        columna = 'Ap.Materno';
                        break;
                    case 'viaticosolicitado':
                        columna = 'Viatico Solicitado';
                        break;
                    case 'viaticorendido':
                        columna = 'Viatico Rendido';
                        break;
                    case 'trasladosolicitado':
                        columna = 'Traslado Solicitado';
                        break;
                    case 'trasladorendido':
                        columna = 'Traslado Rendido';
                        break;
                    case 'diasvyt':
                        columna = 'Dias VyT';
                        break;
                    case 'franquicia':
                        columna = 'Franquicia';
                        break;
                    case 'asistencia':
                        columna = 'Asistencia';
                        break;
                    case 'calificacion':
                        columna = 'Calificacion';
                        break;
                    case 'evaluacion':
                        columna = 'Evaluacion';
                        break;
                    // Campos Participantes - Posicion 56
                    case 'rechazo':
                        columna = 'Rechazo';
                        break;
                    case 'motivorechazo':
                        columna = 'Motivo de Rechazo';
                        break;
                    case 'costocursooticparticipante':
                        columna = 'Costo Curso OTIC por Participante';
                        break;
                    case 'costoviaticooticparticipante':
                        columna = 'Costo Viatico OTIC por Participante';
                        break;
                    case 'costotrasladooticparticipante':
                        columna = 'Costo Traslado OTIC por Participante';
                        break;
                    case 'costocursoempresaparticipante':
                        columna = 'Costo Curso Empresa por Participante';
                        break;
                    case 'costoviaticoempresaparticipante':
                        columna = 'Costo Viatico Empresa por Participante';
                        break;
                    case 'costotrasladoempresaparticipante':
                        columna = 'Costo Traslado Empresa por Participante';
                        break;
                    case 'comisionparticioante':
                        columna = 'Comision';
                        break;
                    case 'condicion':
                        columna = 'Condicion';
                        break;
                    case 'fechadesvinculacion':
                        columna = 'Fecha Desvinculación';
                        break;
                    case 'nivelocupacional':
                        columna = 'Nivel Ocupacional';
                        break;
                    case 'nivelescolaridad':
                        columna = 'Nivel de Escolaridad';
                        break;
                    case 'sexo':
                        columna = 'Sexo';
                        break;
                    case 'totalcostootic':
                        columna = 'Total Costo OTIC';
                        break;
                    case 'totalcostoempresa':
                        columna = 'Total Costo Empresa';
                        break;
                    case 'solt':
                        columna = 'Nº Solicitud';
                        break;
                }
                workbook.options.sheets[0].rows[1].cells.push({
                    background: '#112540',
                    bold: true,
                    color: '#ffffff',
                    value: columna
                });
                workbook.options.sheets[0].columns.push({ autoWidth: true });
            }
            index++;
        }
        var _loop_5 = function (obj) {
            var celdas = [];
            index = 0;
            for (var _i = 0, columnas_2 = columnas; _i < columnas_2.length; _i++) {
                var valor = columnas_2[_i];
                if (index === 31) {
                    var _loop_6 = function (nombreCAAC) {
                        var valorCA = this_3.camposAdicAC.find(function (x) {
                            return parseInt(x.pv, 10) === parseInt(obj.nropv, 10) &&
                                x.nombre.toString() === nombreCAAC.nombre.toString();
                        });
                        celdas.push({
                            value: valorCA !== null && valorCA !== undefined ? valorCA.valor : null
                        });
                    };
                    for (var _a = 0, _b = this_3.camposAdicACCabecera; _a < _b.length; _a++) {
                        var nombreCAAC = _b[_a];
                        _loop_6(nombreCAAC);
                    }
                }
                else if (index === 56) {
                    var _loop_7 = function (nombreCAPart) {
                        var valorPart = this_3.camposAdicPart.find(function (x) {
                            return parseInt(x.pv, 10) === parseInt(obj.nropv, 10) &&
                                x.rut.toString() === obj.rut.toString() &&
                                x.nombre.toString() === nombreCAPart.nombre.toString();
                        });
                        celdas.push({
                            value: valorPart !== null && valorPart !== undefined ? valorPart.valor : null
                        });
                    };
                    for (var _c = 0, _d = this_3.camposAdicPartCabecera; _c < _d.length; _c++) {
                        var nombreCAPart = _d[_c];
                        _loop_7(nombreCAPart);
                    }
                }
                else {
                    celdas.push({
                        value: obj[valor]
                    });
                }
                index++;
            }
            workbook.options.sheets[0].rows.push({
                cells: celdas
            });
        };
        var this_3 = this;
        for (var _e = 0, _f = this.consolidado; _e < _f.length; _e++) {
            var obj = _f[_e];
            _loop_5(obj);
        }
        this.spinner.hide();
        workbook.toDataURL().then(function (dataUrl) {
            saveAs(dataUrl, 'Consolidado.xlsx');
        });
    };
    /**
     * Filtra Reporte al presionar tecla Enter
     * @param event Evento
     */
    RepConsolidadoCursosComponent.prototype.filtrarReporteKeydown = function (event) {
        if (event.key === 'Enter') {
            this.filtrarReporte();
        }
    };
    RepConsolidadoCursosComponent.prototype.accion = function (modulo, submodulo, acc) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localStorageService.get('idToken')];
                    case 1:
                        token = _a.sent();
                        console.log('token', token);
                        ClienteAuditoria({
                            sistema: 'GCH Reportes',
                            modulo: modulo,
                            submodulo: submodulo ? submodulo : '',
                            usuario: this.userData.rut,
                            token: token,
                            accion: acc
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return RepConsolidadoCursosComponent;
}());
export { RepConsolidadoCursosComponent };
