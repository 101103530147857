import {Injectable} from '@angular/core';
// import {Socket} from 'ngx-socket-io';
import * as Rx from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor() {
  }

  private subject: Rx.Subject<MessageEvent>;
  private conectadoState = new Rx.Subject<any>();


  connectadConfirmed$ = this.conectadoState;


  public connect(url): Rx.Subject<MessageEvent> {

    if (!this.subject) {
      this.subject = this.create(url);
      console.log('conectado a websocket');

    }
    return this.subject;
  }

  private create(url): Rx.Subject<MessageEvent> {
    const ws = new WebSocket(url);

    const observable = Rx.Observable.create(
        (obs: Rx.Observer<MessageEvent>) => {
          ws.onmessage = obs.next.bind(obs);
          ws.onerror = obs.error.bind(obs);
          ws.onclose = obs.complete.bind(obs);

          return ws.close.bind(ws);
        }
    );
    ws.onopen = ()=>{
      this.conectadoState.next('conectado');
    };
    const observer = {
      // tslint:disable-next-line:ban-types
      next: (data: Object) => {
        console.log('ws.readyState',ws.readyState);
        console.log('WebSocket.OPEN',WebSocket.OPEN);

        if (ws.readyState === WebSocket.OPEN) {
          console.log('datasend', data);
          ws.send(JSON.stringify(data));
        }
      }
    }
    return Rx.Subject.create(observer, observable)
  }
}
