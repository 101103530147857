import {Injectable, Output, EventEmitter} from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {UserLoginService} from '../services/user-login.service';
import {from, Observable, Subscription, timer, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {TimeoutService} from '../services/timeout.service';
import {TimeoutdialogService} from '../services/timeoutdialog.service';
import {ToastrService} from 'ngx-toastr';
import {Title} from '@angular/platform-browser';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {
  // tslint:disable-next-line:variable-name
    _userActionOccured: Subject<void> = new Subject();
    get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable(); }
  // tslint:disable-next-line:variable-name
    _flagInactivity: boolean;
    @Output() getLoggedInUser: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:variable-name
    public _counter = 0;
  // tslint:disable-next-line:variable-name
    public _status = 'Initialized.';
  // tslint:disable-next-line:variable-name
    private _timer: Observable<number>;
  // tslint:disable-next-line:variable-name
    private _timerSubscription: Subscription;
  // tslint:disable-next-line:variable-name
    private _idleTimerSubscription: Subscription;
  // tslint:disable-next-line:variable-name
    private _navigate?: string;


    constructor(private router: Router,
                private userLoginService: UserLoginService,
                private idleTimeoutSvc: TimeoutService,
                private toastr: ToastrService,
                private dialogSvc: TimeoutdialogService,
                private titleService: Title) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if(route.data.redirect){
        this._navigate = `/${route.routeConfig.path}${(route.queryParams) ? '#' + Object.entries(route.queryParams).map(([key,value]) => `${key}=${encodeURIComponent(value)}`).join('&') : ''}`
    }
      if(route.fragment && route.fragment.includes('access_token')){
          await this.router.navigate(['/auth'],{
              fragment:route.fragment})
      }else{
          this.userLoginService.isAuthenticated(this);
      }
      return true;
    }
// isLoggedIn
  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('authguard: verificacion sesion', this._idleTimerSubscription);
    console.log('localStorage', localStorage);
    if (isLoggedIn) {
      if (this._idleTimerSubscription === undefined) {
        this.sessionTimeout();
      }
    } else {
      this.logout();
    }
    if (!localStorage.getItem('refreshToken')) {
      this.logout();
    }
  }


    sessionTimeout() {
        this.startCounter();
        this.idleTimeoutSvc.resetTimer();
        this.timerout();
    }
    timerout() {
        this._idleTimerSubscription = this.idleTimeoutSvc.timeoutExpired.subscribe(async res => {
                const timmer = setTimeout(() => {
                     console.log('Sesión caducada despues de 10 min');
                     this.logout();
                }, 1000 * 60 * 10);
            // si el tiempo de inactividad es true y llego aca le pregunto si quiere refrezcar
            // si es falso es porque el usuario esta usando el sistema y debo refrezcar automaticamente
                if (this._flagInactivity) {
                    this.checkPageFocus();
                    const modalPromise = this.dialogSvc.open('Sesión Expirando!',
                            'Tu sesión esta apunto de expirar. Necesitas mas tiempo?', true, 'Si', 'No');
                    const newObservable = from(modalPromise);
                    newObservable.subscribe(
                        async (resp) => {
                            if (resp === true) {
                                console.log('Extending session inactive...');
                                clearInterval(timmer);
                                this.checkPageFocus();
                                await this.refreshtoken();
                                this.startCounter();
                                this.idleTimeoutSvc.resetTimer();
                            } else {
                                console.log('Not extending session...');
                                clearInterval(timmer);
                                this.logout();
                            }
                        },
                        (reason) => {
                            console.log('Dismissed ' + reason);
                            this._status = 'Sesión no fue extendida.';
                            this.logout();
                        }
                    );
            } else {
                console.log('Extending session active...');
                clearInterval(timmer);
                await this.refreshtoken();
                this.startCounter();
                this.idleTimeoutSvc.resetTimer();
            }
        });
    }

    public startCounter() {
        if (this._timerSubscription) {
            this._timerSubscription.unsubscribe();
        }

        this._counter = 0;
        this._timer = timer(1000, 1000);
        this._timerSubscription = this._timer.subscribe(n => {
            this._counter++;
        });
    }

    public timerComplete() {
      this.idleTimeoutSvc.forcedTimeout();
    }

    public reset() {
        this.startCounter();
        this._status = 'Initialized.';
        this.idleTimeoutSvc.resetTimer();
    }
    logout() {
        console.log('auth guard logout');
        console.log('redirect after logout',this._navigate);
        localStorage.clear();
        this.toastr.error('Su sesión ha caducado.');
        window.location.href =
        `${environment.URL_SIGNOUT}?callback=${this._navigate ? environment.URL + this._navigate :environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
    }

    async refreshtoken() {
        // se solicita actualizar tokek
        const ci = JSON.parse(localStorage.getItem('ci'));
        const retoken = JSON.parse(localStorage.getItem('refreshToken'));
        console.log('ci', ci);
        const token = {
            app_id: ci._value,
            token: retoken._value
        };
        this.userLoginService.refrestoken(token).subscribe((response: any) => {
            const idToken = {_value: response.IdToken};
            const accessToken = {_value: response.AccessToken};
            localStorage.setItem('idToken', JSON.stringify(idToken));
            localStorage.setItem('accessToken', JSON.stringify(accessToken));
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.toastr.warning('Ocurrió un error en su sesión de usuario y deberá reiniciarla ');
          this.logout();
        });
    }

    notifyUserAction() {
        this._userActionOccured.next();
    }

    flaginactive(item: boolean) {
        this._flagInactivity = item;
    }
     checkPageFocus() {
       const titulo = this.titleService.getTitle();
        if (document.hasFocus()) {
           const last = titulo.split(')');
           this.titleService.setTitle(last[last.length - 1]);
        } else {
            console.log('This document does not have the focus.');
            const newTitle = '(ALERTA)' + titulo;
            this.titleService.setTitle(newTitle);
        }
    }
}
