import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { UserLoginService } from '../../services/user-login.service';
import { LocalStorageService } from 'angular-web-storage';
import { AuthService } from 'src/app/shared/auth.shared';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../../src/app/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { InitParams, ClienteAuditoria } from 'otic-cliente-auditoria';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(spinner, modalService, userLoginService, localStorageService, toastr, authService, router, notificationService, swUpdate) {
        var _this = this;
        this.spinner = spinner;
        this.modalService = modalService;
        this.userLoginService = userLoginService;
        this.localStorageService = localStorageService;
        this.toastr = toastr;
        this.authService = authService;
        this.router = router;
        this.notificationService = notificationService;
        this.swUpdate = swUpdate;
        this.urlGestion = environment.url_gestor;
        this.userData = {};
        this.permisosModulosAcciones = {};
        this.notificacionTotal = 0;
        this.btnMenu = false;
        this.width = 55;
        // Objeto para controlar estado de menus colapsables
        this.collapsables = {
            cataCont: true,
            accioCapa: true,
            planesProg: true,
            liceHab: true,
            gestionAvi: true,
            mante: true
        };
        // Variable para marcar menú activo
        this.activeMenu = 'reportes';
        console.log('HeaderComponent: constructor');
        notificationService.message.subscribe(function (msg) {
            _this.switchEvento(msg);
        });
        notificationService.estado.asObservable().subscribe(function (msg) {
            _this.updateToknowIDWSoc();
        });
        InitParams({
            url: environment.URLACCIONES,
            key: environment.KEYACCIONES
        });
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.userLoginService.isAuthenticated(this);
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(function () {
                //if(confirm("Existe una nueva versión del sistema. Aceptar para actualizar")) {
                window.location.reload();
                //}
            });
        }
    };
    HeaderComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        console.log('HeaderComponent: verificacion sesion');
        if (isLoggedIn) {
            this.userData = this.localStorageService.get('userData');
            this.clienteComercialSeleccionado =
                this.userData.empresas.length > 1 ? this.userData.empresaSeleccionada : this.userData.clienteComercialSeleccionado;
            this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
        }
    };
    HeaderComponent.prototype.redirectTo = function (pagina, modulo, submodulo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var idToken, accessToken, ud, ci, rt, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        idToken = this.localStorageService.get('idToken');
                        accessToken = this.localStorageService.get('accessToken');
                        ud = this.localStorageService.get('ud');
                        ci = this.localStorageService.get('ci');
                        rt = this.localStorageService.get('refreshToken');
                        return [4 /*yield*/, this.accion(modulo, submodulo)];
                    case 1:
                        _a.sent();
                        this.collapseElements();
                        url = environment.url_gestor + "auth#access_token=" + accessToken + "&id_token=" + idToken + "&ud=" + ud + "&ci=" + ci + "&rt=" + rt + "&clicom=" + this.userData.clienteComercialSeleccionado.codigo + "&emp=" + this.userData.empresaSeleccionada.nombre.split(' - ')[0] + "&navigate=" + pagina;
                        window.location.replace(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.doLogout = function () {
        console.log('HeaderComponent: cerrando sesion');
        this.userLoginService.logout();
    };
    HeaderComponent.prototype.confirmarClienteComercial = function () {
        var _this = this;
        if (this.userData.clienteComercialSeleccionado && this.userData.clienteComercialSeleccionado.codigo) {
            this.spinner.show();
            // tslint:disable-next-line: max-line-length
            this.userData.clienteComercialSeleccionado = this.userData.clientesComercial.find(function (x) { return x.codigo === _this.clienteComercialSeleccionado.codigo; });
            this.userData.empresaSeleccionada = this.clienteComercialSeleccionado;
            Promise.all([
                this.authService.cargarPermisosCliCom(this.userData.usuarioId, this.userData.clienteComercialSeleccionado.codigo),
                this.authService.cargarAccionesActivas(this.userData.usuarioId)
            ])
                .then(function () {
                // guarda en storage
                _this.userData.clienteComercialSeleccionado = _this.userData.clientesComercial.find(function (x) { return x.codigo === _this.clienteComercialSeleccionado.codigo; });
                _this.userData.empresaSeleccionada = _this.clienteComercialSeleccionado;
                _this.localStorageService.set('userData', _this.userData, 0, 's');
                //
                _this.toastr.success("Cliente comercial " + _this.userData.clienteComercialSeleccionado.nombre + " seleccionado");
                _this.collapseSidebar();
                _this.router.navigate(['/']);
            }).catch(function (error) {
                _this.toastr.error('Ocurrio un error al obtener Permisos.');
            }).finally(function () {
                _this.spinner.hide();
            });
        }
    };
    HeaderComponent.prototype.switchEvento = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                console.log('evento', event);
                if (event) {
                    data = JSON.parse(event.data);
                    switch (data.tipo) {
                        case 'onKnow':
                            console.log('actulizado para enviar notificaicon');
                            this.keeplive();
                            this.llenarGrid();
                            this.llenarTotalNotificacion();
                            break;
                        case 'onMessage':
                            console.log('actulizado para enviar notificaicon');
                            console.log('data', data);
                            this.notificacionModalData = data;
                            this.modalService.open(this.mdlNotificacion, { backdrop: 'static', keyboard: false });
                            // alerta de nuevo mensaje  y refrezca la data de la tabla
                            break;
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    HeaderComponent.prototype.updateToknowIDWSoc = function () {
        console.log('envio quieen soy');
        var data = {
            user: this.userData.rut,
            tipo: 'onKnow',
        };
        var payload = { action: 'onKnow', message: JSON.stringify(data) };
        this.notificationService.message.next(payload);
    };
    HeaderComponent.prototype.abrirNotificaciones = function () {
        this.llenarGrid();
        var noLeidos = [];
        for (var _i = 0, _a = this.listadoNotificaciones.data; _i < _a.length; _i++) {
            var row = _a[_i];
            if (!row.leido) {
                noLeidos.push(row.id);
            }
        }
        if (noLeidos.length > 0) {
            this.cambioAleido(noLeidos);
            console.log('no leidos', noLeidos);
        }
        this.modalService.open(this.mdlNotificaciones, { backdrop: 'static', size: 'lg', keyboard: false });
        this.accion('Módulo de Notificaciones', null);
    };
    HeaderComponent.prototype.descargarArchivo = function (dato) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.notificationService.getSingURLNotificacion({
                            metodo: 'get',
                            key: dato.link,
                            bucket: dato.bucket
                        }).toPromise()];
                    case 1:
                        result = _a.sent();
                        window.open(result.message);
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.llenarGrid = function () {
        var _this = this;
        var data = { usuarioId: this.userData.rut };
        this.notificationService.getListadoNotificacion(data).subscribe(function (result) {
            _this.listadoNotificaciones = {
                data: result.notificaciones,
                total: result.notificaciones.length,
            };
        });
    };
    HeaderComponent.prototype.llenarTotalNotificacion = function () {
        var _this = this;
        this.notificationService.getListadoNotificacionTotal(this.userData.rut).subscribe(function (result) {
            _this.notificacionTotal = result.total;
        });
    };
    HeaderComponent.prototype.cambioAleido = function (ids) {
        var _this = this;
        this.notificationService.setNotificacionLeida(ids).subscribe(function (result) {
            console.log(result);
            _this.llenarTotalNotificacion();
        });
    };
    HeaderComponent.prototype.keeplive = function () {
        var _this = this;
        timer(0, 300000).subscribe(function () {
            console.log('keeplive');
            var data = {
                usuarioId: _this.userData.rut,
                tipo: 'keepAlive',
            };
            var payload = { action: 'onMessage', message: JSON.stringify(data) };
            _this.notificationService.message.next(payload);
        });
    };
    HeaderComponent.prototype.cambiaMensaje = function (mensaje) {
        var retorno = mensaje.replace('correctamente', '<strong>correctamente</strong>');
        return retorno;
    };
    HeaderComponent.prototype.accion = function (modulo, submodulo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localStorageService.get('idToken')];
                    case 1:
                        token = _a.sent();
                        console.log('token', token);
                        ClienteAuditoria({
                            sistema: 'GCH Reportes',
                            modulo: modulo,
                            submodulo: submodulo ? submodulo : '',
                            usuario: this.userData.rut,
                            token: token,
                            accion: "Usuario " + this.userData.rut + " entra"
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.toggleSidebar = function (origen, event) {
        // if (origen === 'btnMenu' || (origen === 'mouse' && !event.toElement.classList.value.includes('menu'))) {
        if (origen === 'btnMenu') {
            this.btnMenu = !this.btnMenu;
            if (this.btnMenu) {
                this.expandSidebar();
            }
            else {
                this.collapseSidebar();
            }
        }
        else {
            if (event.type === 'mouseover') {
                this.expandSidebar();
            }
            else if (event.type === 'mouseout' && (event.x < 0
                || event.x >= 265
                || event.y < 70
                || event.srcElement.classList.value.includes('sidebar'))) {
                this.collapseSidebar();
            }
        }
        // }
    };
    HeaderComponent.prototype.collapseElements = function () {
        this.collapsables = {
            cataCont: true,
            accioCapa: true,
            planesProg: true,
            liceHab: true,
            gestionAvi: true,
            mante: true
        };
    };
    HeaderComponent.prototype.expandSidebar = function () {
        this.width = 265;
        this.btnMenu = true;
    };
    HeaderComponent.prototype.collapseSidebar = function () {
        this.width = 55;
        this.btnMenu = false;
        this.collapseElements();
    };
    return HeaderComponent;
}());
export { HeaderComponent };
