import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AportesService {

  constructor(private http: HttpClient) {
    console.log('AportesService: constructor');
  }

  /**
   * Retorna las fechas minimas y maxima de aportes para empresas seleccionadas
   * @param codigosEmpresa Listado de Codigo de Empresa seleccionada
   */
  obtenerFechasAportes(codigosEmpresa: string): Observable<any> {
    const body = JSON.stringify(codigosEmpresa);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.APORTES}/obtenerFechasAportes/`, body, { headers });
  }

  /**
   * Retorna el Reporte de Aportes
   * @param filtrosReporte Filtros Ingresados
   */
  reporteAportes(filtrosReporte: any): Observable<any> {
    const body = JSON.stringify(filtrosReporte);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.APORTES}/reporteAportes/`, body, { headers });
  }
}
