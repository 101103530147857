<footer class="footer no-print" style="background-color:#1d2345;color:#ffffff;">
    <div class="container-fluid px-5">
        <div class="row px-3">
            <div class="col-xl-10 col-md-10 col-12 mt-4 mb-4">
                <div class="row">
                    <div class="col-xl-12 iconos-catena-footer">
                        <h3 class="text-bold">Gestor Capital Humano v3 {{anio}}: <br/>OTIC SOFOFA</h3>
                        <p>Todos los Derechos Reservados</p>
                        <p>
                            Casa Matriz: Agustinas 1357. Pisos 11 y 12 - Santiago. Teléfono/Fax: (+562) 2336 28 00.<br/>
                            Agencias Regionales: Iquique - Antofagasta - Calama - Viña del Mar - Concepción - Temuco - Puerto Montt - Punta Arenas
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-md-2 col-12 logo-catena-footer text-right mt-4 mb-4">
                <img src="../assets/img/logo-otic-sofofa-blanco.png" alt="Logo Otic Sofofa">
            </div>
        </div>
    </div>
</footer>
