import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConsolidadoService = /** @class */ (function () {
    function ConsolidadoService(http) {
        this.http = http;
        console.log('ConsolidadoService: constructor');
    }
    /**
     * Retorna las fechas minimas y maxima de PVs para empresas seleccionadas
     * @param rutsEmpresa Listado de Ruts de Empresa seleccionada
     */
    ConsolidadoService.prototype.obtenerFechasPVs = function (rutsEmpresa) {
        var body = JSON.stringify(rutsEmpresa);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CONSOLIDADO + "/obtenerFechasPVs/", body, { headers: headers });
    };
    /**
     * Retorna el Reporte Consolidado de Cursos y Participantes
     * @param filtrosReporte Filtros Ingresados
     */
    ConsolidadoService.prototype.reporteConsolidado = function (filtrosReporte) {
        var body = JSON.stringify(filtrosReporte);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CONSOLIDADO + "/reporteConsolidado/", body, { headers: headers });
    };
    /**
     * Inicia el proceso de Generación reporte consolidado
     * @param filtrosReporte Filtros Ingresados
     */
    ConsolidadoService.prototype.reporteConsolidadoStart = function (filtrosReporte) {
        var body = JSON.stringify(filtrosReporte);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CONSOLIDADO + "/reporteConsolidadoStart/", body, { headers: headers });
    };
    /**
     * Inicia el proceso de Generación reporte consolidado de cursos
     * @param filtrosReporte Filtros Ingresados
     */
    ConsolidadoService.prototype.reporteConsolidadoCursosStart = function (filtrosReporte) {
        var body = JSON.stringify(filtrosReporte);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CONSOLIDADO + "/reporteConsolidadoCursosStart/", body, { headers: headers });
    };
    /**
     * Obtiene los datos del reporte consolidado de cursos
     * @param filtrosReporte Filtros Ingresados
     */
    ConsolidadoService.prototype.reporteConsolidadoCursosSAP = function (filtrosReporte) {
        var body = JSON.stringify(filtrosReporte);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CONSOLIDADO + "/reporteConsolidadoCursos", body, { headers: headers });
    };
    /**
     * Inicia el proceso de Generación reporte historial del participante
     * @param filtrosReporte Filtros Ingresados
     */
    ConsolidadoService.prototype.reporteHistorialParticipanteStart = function (filtrosReporte) {
        var body = JSON.stringify(filtrosReporte);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CONSOLIDADO + "/reporteHistorialParticipanteStart/", body, { headers: headers });
    };
    ConsolidadoService.ngInjectableDef = i0.defineInjectable({ factory: function ConsolidadoService_Factory() { return new ConsolidadoService(i0.inject(i1.HttpClient)); }, token: ConsolidadoService, providedIn: "root" });
    return ConsolidadoService;
}());
export { ConsolidadoService };
