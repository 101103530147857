import { SocketService } from '../../app/services/socket.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "./socket.service";
import * as i2 from "@angular/common/http";
var NotificationService = /** @class */ (function () {
    function NotificationService(wsService, http) {
        this.wsService = wsService;
        this.http = http;
        this.message = wsService.connect(environment.API_DOMAINS.WS_URL);
        this.estado = wsService.connectadConfirmed$;
    }
    NotificationService.prototype.getListadoNotificacion = function (datos) {
        var body = JSON.stringify(datos);
        return this.http.post(environment.API_DOMAINS.CENTRO_NOTIFICACIONES + 'listar', body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
    };
    NotificationService.prototype.obtenerFechaHoy = function () {
        return this.http.get(environment.ENDPOINTS.COMUNES + '/fecha');
    };
    NotificationService.prototype.getListadoNotificacionTotal = function (usuarioId) {
        return this.http.get(environment.API_DOMAINS.CENTRO_NOTIFICACIONES + "count/" + usuarioId);
    };
    NotificationService.prototype.getSingURLNotificacion = function (datos) {
        var body = JSON.stringify(datos);
        return this.http.post(environment.API_DOMAINS.CENTRO_NOTIFICACIONES + 'generar-url-firmada', body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
    };
    NotificationService.prototype.setNotificacionLeida = function (datos) {
        var body = JSON.stringify(datos);
        return this.http.post(environment.API_DOMAINS.CENTRO_NOTIFICACIONES + 'marcar-leido', body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.SocketService), i0.inject(i2.HttpClient)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
