// import {Socket} from 'ngx-socket-io';
import * as Rx from 'rxjs';
import * as i0 from "@angular/core";
var SocketService = /** @class */ (function () {
    function SocketService() {
        this.conectadoState = new Rx.Subject();
        this.connectadConfirmed$ = this.conectadoState;
    }
    SocketService.prototype.connect = function (url) {
        if (!this.subject) {
            this.subject = this.create(url);
            console.log('conectado a websocket');
        }
        return this.subject;
    };
    SocketService.prototype.create = function (url) {
        var _this = this;
        var ws = new WebSocket(url);
        var observable = Rx.Observable.create(function (obs) {
            ws.onmessage = obs.next.bind(obs);
            ws.onerror = obs.error.bind(obs);
            ws.onclose = obs.complete.bind(obs);
            return ws.close.bind(ws);
        });
        ws.onopen = function () {
            _this.conectadoState.next('conectado');
        };
        var observer = {
            // tslint:disable-next-line:ban-types
            next: function (data) {
                console.log('ws.readyState', ws.readyState);
                console.log('WebSocket.OPEN', WebSocket.OPEN);
                if (ws.readyState === WebSocket.OPEN) {
                    console.log('datasend', data);
                    ws.send(JSON.stringify(data));
                }
            }
        };
        return Rx.Subject.create(observer, observable);
    };
    SocketService.ngInjectableDef = i0.defineInjectable({ factory: function SocketService_Factory() { return new SocketService(); }, token: SocketService, providedIn: "root" });
    return SocketService;
}());
export { SocketService };
