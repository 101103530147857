import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { SocketService } from '../../app/services/socket.service';
import { environment } from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public message: Rx.Subject<any>;
  public estado: Rx.Subject<any>;

  constructor(private wsService: SocketService,
              private http: HttpClient) {
    this.message = (wsService.connect(environment.API_DOMAINS.WS_URL) as Rx.Subject<any>);
    this.estado = (wsService.connectadConfirmed$ as Rx.Subject<any>);
  }

  getListadoNotificacion(datos: any): Observable<any> {
    const body = JSON.stringify(datos);
    return this.http.post(environment.API_DOMAINS.CENTRO_NOTIFICACIONES + 'listar', body, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
  }

  obtenerFechaHoy(): Observable<any> {
    return this.http.get(environment.ENDPOINTS.COMUNES + '/fecha' );
  }


  getListadoNotificacionTotal(usuarioId: string): Observable<any> {
    return this.http.get(`${environment.API_DOMAINS.CENTRO_NOTIFICACIONES}count/${usuarioId}`);
  }

  getSingURLNotificacion(datos: any): Observable<any> {
    const body = JSON.stringify(datos);
    return this.http.post(environment.API_DOMAINS.CENTRO_NOTIFICACIONES + 'generar-url-firmada', body, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
  }

  setNotificacionLeida(datos: any): Observable<any> {
    const body = JSON.stringify(datos);
    return this.http.post(environment.API_DOMAINS.CENTRO_NOTIFICACIONES + 'marcar-leido', body, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
  }

}
