import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GestionPlantillasService = /** @class */ (function () {
    function GestionPlantillasService(http) {
        this.http = http;
        this.apiUrl = "" + environment.ENDPOINTS.PLANTILLAS_REPORTE;
    }
    GestionPlantillasService.prototype.obtenerCabeceras = function () {
        return this.http.get(this.apiUrl + "/obtenerCabecerasNuevaPlantilla");
    };
    GestionPlantillasService.prototype.obtenerPlantillasUsuario = function () {
        return this.http.get(this.apiUrl + "/obtenerPlantillasUsuario");
    };
    GestionPlantillasService.prototype.crearNuevaPlantilla = function (dataPlantilla) {
        var body = JSON.stringify(dataPlantilla);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(this.apiUrl + "/crearPlantilla", body, { headers: headers });
    };
    GestionPlantillasService.prototype.editarPlantilla = function (dataPlantilla) {
        var body = JSON.stringify(dataPlantilla);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(this.apiUrl + "/editarPlantilla", body, { headers: headers });
    };
    GestionPlantillasService.ngInjectableDef = i0.defineInjectable({ factory: function GestionPlantillasService_Factory() { return new GestionPlantillasService(i0.inject(i1.HttpClient)); }, token: GestionPlantillasService, providedIn: "root" });
    return GestionPlantillasService;
}());
export { GestionPlantillasService };
