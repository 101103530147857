import {Injectable} from '@angular/core';

export const GLOBALS = {
    TOASTER: {
        TITLE_OK : 'Operación exitosa',
        TITLE_INFO : 'Información',
        TITLE_ERROR: 'Error'
    }
};

@Injectable()
export class GlobalService {
    /**
     * Formatea el RUT con Puntos y Guión
     * @param rut RUT sin Formato
     */
    formatRUT(rut: string): string {
        const actual = rut.replace(/^0+/, '');
        let rutPuntos = '';
        if (actual !== '' && actual.length > 1) {
            const sinPuntos = actual.replace(/\./g, '');
            const actualLimpio = sinPuntos.replace(/-/g, '');
            const inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            let i = 0;
            let j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                const letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 === 0 && j <= inicio.length - 1) {
                    rutPuntos = '.' + rutPuntos;
                }
                j++;
            }
            const dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + '-' + dv;
        }
        return rutPuntos;
    }
}
