import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactoAportesModel } from '../models/contactoAportes';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(private http: HttpClient) {
    console.log('NotificacionesService: constructor');
  }

  /**
   * Envia la notificación al Ejecutivo Comercial
   * @param contacto ContactoAportesModel
   */
  enviarNotificacionContAportes(contacto: ContactoAportesModel): Observable<any> {
    const body = JSON.stringify(contacto);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.NOTIFICACIONES}/enviarNotificacionContAportes/`, body, { headers });
  }
}
