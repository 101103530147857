/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-dual-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-dual-list.component";
var styles_AppDualListComponent = [i0.styles];
var RenderType_AppDualListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppDualListComponent, data: {} });
export { RenderType_AppDualListComponent as RenderType_AppDualListComponent };
function View_AppDualListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["draggable", "true"]], null, [[null, "click"], [null, "dragstart"], [null, "dragend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selectItem(_co.available.pick, _v.context.$implicit);
        var pd_0 = (_co.shiftClick($event, _v.context.index, _co.available, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("dragstart" === en)) {
        var pd_1 = (_co.drag($event, _v.context.$implicit, _co.available) !== false);
        ad = (pd_1 && ad);
    } if (("dragend" === en)) {
        var pd_2 = (_co.dragEnd(_co.available) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { selected: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "dragdrop-button"], ["src", "../../../../assets/img/drag_indicator.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isItemSelected(_co.available.pick, _v.context.$implicit)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit._name; _ck(_v, 4, 0, currVal_1); }); }
function View_AppDualListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["draggable", "true"]], null, [[null, "click"], [null, "dragstart"], [null, "dragend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selectItem(_co.confirmed.pick, _v.context.$implicit);
        var pd_0 = (_co.shiftClick($event, _v.context.index, _co.confirmed, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("dragstart" === en)) {
        var pd_1 = (_co.drag($event, _v.context.$implicit, _co.confirmed) !== false);
        ad = (pd_1 && ad);
    } if (("dragend" === en)) {
        var pd_2 = (_co.dragEnd(_co.confirmed) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { selected: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "dragdrop-button"], ["src", "../../../../assets/img/drag_indicator.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isItemSelected(_co.confirmed.pick, _v.context.$implicit)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit._name; _ck(_v, 4, 0, currVal_1); }); }
export function View_AppDualListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [["class", "dual-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "listbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "title-boxlist"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Opciones del reporte disponibles:"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "record-picker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ul", [["class", "box-list-ul"]], null, [[null, "drop"], [null, "dragover"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (_co.drop($event, _co.confirmed) !== false);
        ad = (pd_0 && ad);
    } if (("dragover" === en)) {
        var pd_1 = (_co.allowDrop($event, _co.available) !== false);
        ad = (pd_1 && ad);
    } if (("dragleave" === en)) {
        var pd_2 = (_co.dragLeave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { over: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppDualListComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "button-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Agregar todos "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["class", "selected-button"], ["src", "../../../../assets/img/keyboard_double_arrow_right.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveItem(_co.available, _co.confirmed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Agregar selecci\u00F3n "])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "img", [["class", "selected-button"], ["src", "../../../../assets/img/arrow_forward.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 15, "div", [["class", "listbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "p", [["class", "title-boxlist"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Opciones del reporte agregadas:"])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "div", [["class", "record-picker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 4, "ul", [["class", "box-list-ul"]], null, [[null, "drop"], [null, "dragover"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (_co.drop($event, _co.available) !== false);
        ad = (pd_0 && ad);
    } if (("dragover" === en)) {
        var pd_1 = (_co.allowDrop($event, _co.confirmed) !== false);
        ad = (pd_1 && ad);
    } if (("dragleave" === en)) {
        var pd_2 = (_co.dragLeave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(23, { over: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppDualListComponent_2)), i1.ɵdid(25, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 6, "div", [["class", "button-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveItem(_co.confirmed, _co.available) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, "img", [["class", "selected-button"], ["src", "../../../../assets/img/arrow_back.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Quitar selecci\u00F3n "])), (_l()(), i1.ɵeld(30, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, "img", [["class", "selected-button"], ["src", "../../../../assets/img/keyboard_double_arrow_left.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Quitar todos "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "box-list-ul"; var currVal_1 = _ck(_v, 7, 0, _co.available.dragOver); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = _co.available.sift; _ck(_v, 9, 0, currVal_2); var currVal_5 = "box-list-ul"; var currVal_6 = _ck(_v, 23, 0, _co.confirmed.dragOver); _ck(_v, 22, 0, currVal_5, currVal_6); var currVal_7 = _co.confirmed.sift; _ck(_v, 25, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isAllSelected(_co.available); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.available.pick.length === 0); _ck(_v, 14, 0, currVal_4); var currVal_8 = (_co.confirmed.pick.length === 0); _ck(_v, 27, 0, currVal_8); var currVal_9 = _co.isAllSelected(_co.confirmed); _ck(_v, 30, 0, currVal_9); }); }
export function View_AppDualListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dual-list", [], null, null, null, View_AppDualListComponent_0, RenderType_AppDualListComponent)), i1.ɵdid(1, 835584, null, 0, i3.AppDualListComponent, [i1.IterableDiffers], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppDualListComponentNgFactory = i1.ɵccf("app-dual-list", i3.AppDualListComponent, View_AppDualListComponent_Host_0, { id: "id", key: "key", display: "display", height: "height", filter: "filter", format: "format", sort: "sort", compare: "compare", disabled: "disabled", source: "source", destination: "destination" }, { destinationChange: "destinationChange" }, []);
export { AppDualListComponentNgFactory as AppDualListComponentNgFactory };
