import * as tslib_1 from "tslib";
import { IterableDiffers } from '@angular/core';
import { DualListComponent } from 'angular-dual-listbox';
var AppDualListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AppDualListComponent, _super);
    function AppDualListComponent(differs) {
        return _super.call(this, differs) || this;
    }
    AppDualListComponent.prototype.moveAll = function () {
        this.selectAll(this.available);
        this.moveItem(this.available, this.confirmed);
    };
    AppDualListComponent.prototype.removeAll = function () {
        this.selectAll(this.confirmed);
        this.moveItem(this.confirmed, this.available);
    };
    return AppDualListComponent;
}(DualListComponent));
export { AppDualListComponent };
