import { Component, IterableDiffers } from '@angular/core';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'app-dual-list',
  templateUrl: './app-dual-list.component.html',
  styleUrls: ['./app-dual-list.component.scss']
})
export class AppDualListComponent extends DualListComponent {

  constructor(differs: IterableDiffers) {
    super(differs);
  }

  moveAll() {
    this.selectAll(this.available);
    this.moveItem(this.available, this.confirmed);
  }

  removeAll() {
    this.selectAll(this.confirmed);
    this.moveItem(this.confirmed, this.available);
  }

}
