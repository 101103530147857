import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteComercialService {

  constructor(private http: HttpClient) {
    console.log('ClienteComercialService: constructor');
  }

  /**
   * Lista Empresas y sus Sucursales por Usuario
   */
  listarEmpresasSucursales(sucursalesAutorizadas: string): Observable<{
    rows: Array<{
      codigoempresa: string,
      rutempresa: string,
      razonsocial: string,
      codigosucursal: string,
      sucursal: string
    }>,
    fields: [
      { name: 'codigoempresa' },
      { name: 'rutempresa' },
      { name: 'razonsocial' },
      { name: 'codigosucursal' },
      { name: 'sucursal' }
    ]
  }> {
    const body = JSON.stringify(sucursalesAutorizadas);
    return this.http.post<any>(`${environment.ENDPOINTS.CLIENTE_COMERCIAL}/listarEmpresasSucursales/`, body, { 
      headers: {'Content-Type': 'application/json'}
    });
  }

  /**
   * Obtiene el Contacto Comercial de la Sucursal
   */
  obtenerContacto(codigoSucursal: string): Observable<any> {
    const body = JSON.stringify(codigoSucursal);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CLIENTE_COMERCIAL}/obtenerContacto/`, body, { headers });
  }


  /**
   * Obtiene la informacion de la empresa
   */
  obtenerEmpresa(codigoEmpresa: number): Observable<any> {
    return this.http.get(`${environment.ENDPOINTS.CLIENTE_COMERCIAL}/obtenerEmpresa/${codigoEmpresa}`);
  }
}
