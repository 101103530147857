import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserLoginService } from 'src/app/services/user-login.service';
import { NotificationService } from 'src/app/services/notification.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { GLOBALS } from 'src/app/shared/globals.shared';
var DescargasComponent = /** @class */ (function () {
    function DescargasComponent(route, router, spinner, toastr, localStorageService, userLoginService, notificationService) {
        this.route = route;
        this.router = router;
        this.spinner = spinner;
        this.toastr = toastr;
        this.localStorageService = localStorageService;
        this.userLoginService = userLoginService;
        this.notificationService = notificationService;
        this.notificaciones = [];
        this.userData = {};
        this.data = null;
        this.suscriptions = [];
        this.userData = this.localStorageService.get('userData');
    }
    DescargasComponent.prototype.ngOnInit = function () {
        this.userLoginService.isAuthenticated(this);
    };
    DescargasComponent.prototype.ngOnDestroy = function () {
        this.suscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    DescargasComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filtro, params;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (isLoggedIn) {
                    this.notificationService.obtenerFechaHoy()
                        .pipe(catchError(function (_) { return of(moment().format('DD/MM/YYYY')); }))
                        .subscribe(function (result) {
                        _this.hoy = moment.parseZone(result, 'DD/MM/YYYY').local(true).toDate();
                    });
                    filtro = {
                        usuarioId: this.userData.rut,
                        soloNoLeidas: false,
                        sistema: 'Reportes'
                    };
                    this.getNotificaciones(filtro);
                    params = this.route.queryParams.subscribe(function (q) {
                        try {
                            var token = q.token;
                            if (token && _this.comprobarToken(token)) {
                                _this.descargarArchivo(_this.data);
                            }
                            ;
                        }
                        catch (error) {
                            _this.toastr.error('Enlace invalido o expirado', GLOBALS.TOASTER.TITLE_ERROR);
                        }
                    });
                    this.suscriptions.push(params);
                }
                return [2 /*return*/];
            });
        });
    };
    DescargasComponent.prototype.getNotificaciones = function (filtros) {
        var _this = this;
        this.spinner.show();
        this.notificationService.getListadoNotificacion(filtros)
            .pipe(map(function (_a) {
            var notificaciones = _a.notificaciones;
            return notificaciones.filter(function (notificacion) { return notificacion.link && notificacion.estado === "success"; });
        }), catchError(function (_) { return of([]); }), finalize(function () { _this.spinner.hide(); }))
            .subscribe(function (next) {
            _this.notificaciones = next;
        }, function (error) {
            console.log(error);
            _this.toastr.error('Ocurrio un error al cargar las notificaciones');
        });
    };
    DescargasComponent.prototype.comprobarToken = function (token) {
        try {
            if (!token)
                return false;
            var iv = CryptoJS.enc.Hex.parse('101112131415161718191a1b1c1d1e1f');
            var passwordHash = CryptoJS.SHA256(environment.password_documentos);
            var cipher = CryptoJS.AES.decrypt(decodeURIComponent(token), passwordHash, { iv: iv }).toString(CryptoJS.enc.Utf8);
            var data = JSON.parse(cipher);
            if (!data)
                return false;
            //Validacion sistema
            if (data.sistema !== 'Reportes') {
                this.toastr.error('No estás autorizado para ingresar a esta página', GLOBALS.TOASTER.TITLE_ERROR);
                return false;
            }
            ;
            //Expiracion token
            var limite = moment.unix(data.expireAt).toDate();
            if (limite < this.hoy) {
                this.toastr.error('Token de descarga expirado', GLOBALS.TOASTER.TITLE_ERROR);
                return false;
            }
            ;
            this.data = data;
            return true;
        }
        catch (error) {
            this.toastr.error('Token de descarga inválido', GLOBALS.TOASTER.TITLE_ERROR);
            this.router.navigate(['./descargas']);
            return false;
        }
    };
    DescargasComponent.prototype.descargarArchivo = function (dataItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.notificationService.getSingURLNotificacion({
                            metodo: 'get',
                            key: dataItem.link,
                            bucket: dataItem.bucket
                        }).toPromise()];
                    case 1:
                        result = _a.sent();
                        window.open(result.message);
                        return [2 /*return*/];
                }
            });
        });
    };
    return DescargasComponent;
}());
export { DescargasComponent };
