import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RolService = /** @class */ (function () {
    function RolService(http) {
        this.http = http;
    }
    RolService.prototype.accionesActivas = function (usuarioId) {
        return this.http.get(environment.ENDPOINTS.PERFIL_ROL + "/accionesActivas/" + usuarioId);
    };
    RolService.prototype.obtenerPermisosClientesComercial = function (usuarioId) {
        return this.http.get(environment.ENDPOINTS.PERFIL_ROL + "/obtenerPermisosClientesComercial/" + usuarioId);
    };
    RolService.prototype.obtenerClientesComercialPorUsuarioEmail = function (usuarioEmail) {
        return this.http.get(environment.ENDPOINTS.PERFIL_ROL + "/obtenerClientesComercialPorUsuarioEmail/" + usuarioEmail);
    };
    RolService.prototype.obtenerEmpresasPorUsuarioEmail = function (usuarioEmail) {
        return this.http.get(environment.ENDPOINTS.PERFIL_ROL + "/obtenerEmpresasPorUsuarioEmail/" + usuarioEmail);
    };
    RolService.ngInjectableDef = i0.defineInjectable({ factory: function RolService_Factory() { return new RolService(i0.inject(i1.HttpClient)); }, token: RolService, providedIn: "root" });
    return RolService;
}());
export { RolService };
