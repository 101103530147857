/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i3 from "ngx-spinner";
import * as i4 from "@angular/router";
import * as i5 from "./components/header/header.component.ngfactory";
import * as i6 from "./shared/auth.shared";
import * as i7 from "./services/rol.service";
import * as i8 from "angular-web-storage";
import * as i9 from "./components/header/header.component";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
import * as i11 from "./services/user-login.service";
import * as i12 from "ngx-toastr";
import * as i13 from "./services/notification.service";
import * as i14 from "@angular/service-worker";
import * as i15 from "./components/footer/footer.component.ngfactory";
import * as i16 from "./components/footer/footer.component";
import * as i17 from "@angular/common";
import * as i18 from "./app.component";
import * as i19 from "./_guards/auth.guard";
import * as i20 from "./components/inactivity-timer/inactivity-timer.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ngx-spinner", [["bdColor", "rgba(51, 51, 51, 0.8)"], ["color", "#fff"], ["size", "default"], ["type", "ball-newton-cradle"]], null, null, null, i2.View_NgxSpinnerComponent_0, i2.RenderType_NgxSpinnerComponent)), i1.ɵdid(2, 770048, null, 0, i3.NgxSpinnerComponent, [i3.NgxSpinnerService, i1.ChangeDetectorRef], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "rgba(51, 51, 51, 0.8)"; var currVal_1 = "default"; var currVal_2 = "#fff"; var currVal_3 = "ball-newton-cradle"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 4, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-header", [], null, null, null, i5.View_HeaderComponent_0, i5.RenderType_HeaderComponent)), i1.ɵprd(512, null, i6.AuthService, i6.AuthService, [i7.RolService, i8.LocalStorageService]), i1.ɵdid(2, 114688, null, 0, i9.HeaderComponent, [i3.NgxSpinnerService, i10.NgbModal, i11.UserLoginService, i8.LocalStorageService, i12.ToastrService, i6.AuthService, i4.Router, i13.NotificationService, i14.SwUpdate], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ngx-spinner", [["bdColor", "rgba(51, 51, 51, 0.8)"], ["color", "#fff"], ["size", "default"], ["type", "ball-newton-cradle"]], null, null, null, i2.View_NgxSpinnerComponent_0, i2.RenderType_NgxSpinnerComponent)), i1.ɵdid(4, 770048, null, 0, i3.NgxSpinnerComponent, [i3.NgxSpinnerService, i1.ChangeDetectorRef], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i15.View_FooterComponent_0, i15.RenderType_FooterComponent)), i1.ɵdid(8, 114688, null, 0, i16.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = "rgba(51, 51, 51, 0.8)"; var currVal_1 = "default"; var currVal_2 = "#fff"; var currVal_3 = "ball-newton-cradle"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseTemplate", 2]], null, 0, null, View_AppComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.location.pathname == "/auth"); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["document", "keyup"], ["document", "click"], ["document", "wheel"]], function (_v, en, $event) { var ad = true; if (("document:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).resetTimer() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).resetTimer() !== false);
        ad = (pd_1 && ad);
    } if (("document:wheel" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).resetTimer() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i18.AppComponent, [i4.Router, i19.AuthGuard, i20.InactivityTimerComponent], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i18.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
