import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';
import { PermisoClientesComercialModel } from '../models/permisos-clientes-comercial.model';



@Injectable({
  providedIn: 'root'
})
export class RolService {

  constructor(
    private http: HttpClient
  ) { }

 
  accionesActivas(usuarioId: number): Observable< {modulosConPermisos: any} > {
    return this.http.get<any>(`${environment.ENDPOINTS.PERFIL_ROL}/accionesActivas/${usuarioId}`);
  }

  obtenerPermisosClientesComercial(usuarioId: number): Observable< Array<PermisoClientesComercialModel> > {
    return this.http.get<any>(`${environment.ENDPOINTS.PERFIL_ROL}/obtenerPermisosClientesComercial/${usuarioId}`);
  }

  obtenerClientesComercialPorUsuarioEmail(usuarioEmail: string): Observable<{
    usuario: {
      id: number,
      email: string,
      clicom: Array<{
        codigo: number,
        nombre: string
      }>
    }
  }> {
    return this.http.get<any>(`${environment.ENDPOINTS.PERFIL_ROL}/obtenerClientesComercialPorUsuarioEmail/${usuarioEmail}`);
  }

  obtenerEmpresasPorUsuarioEmail(usuarioEmail: string): Observable<any> {
    return this.http.get<any>(`${environment.ENDPOINTS.PERFIL_ROL}/obtenerEmpresasPorUsuarioEmail/${usuarioEmail}`);
  }
}
