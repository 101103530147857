import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumber'
})
export class NumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value){
      if(/^[0-9]+$/g.test(value)){
          return parseInt(value).toString();
      }
      return value;
  }
  return '';
  }

}
