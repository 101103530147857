import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LoggedInCallback} from '../../services/cognito.service';
import { SwUpdate } from '@angular/service-worker';
import {UserLoginService} from '../../services/user-login.service';
import {LocalStorageService} from 'angular-web-storage';
import {AuthService} from 'src/app/shared/auth.shared';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserDataLocalStorageModel} from 'src/app/models/userDataLocalStorage';
import {NotificationService} from '../../../../src/app/services/notification.service';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {timer} from 'rxjs';
import {InitParams, ClienteAuditoria} from 'otic-cliente-auditoria';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [AuthService]
})
export class HeaderComponent implements OnInit, LoggedInCallback {
  @ViewChild('refModalNotificacion', /* TODO: add static flag */ null) mdlNotificacion: TemplateRef<any>;
  @ViewChild('refModalNotificaciones', /* TODO: add static flag */ null) mdlNotificaciones: TemplateRef<any>;

  urlGestion: string = environment.url_gestor;

  userData: UserDataLocalStorageModel = {};
  clienteComercialSeleccionado: { codigo: number, nombre: string };

  permisosModulosAcciones: any = {};
  notificacionModalData: any;
  listadoNotificaciones: GridDataResult;
  notificacionTotal = 0;

  btnMenu = false;
  width = 55;

  // Objeto para controlar estado de menus colapsables
  collapsables = {
    cataCont: true,
    accioCapa: true,
    planesProg: true,
    liceHab: true,
    gestionAvi: true,
    mante: true
  };

  // Variable para marcar menú activo
  activeMenu = 'reportes';

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private userLoginService: UserLoginService,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    private swUpdate: SwUpdate
  ) {
    console.log('HeaderComponent: constructor');
    notificationService.message.subscribe(msg => {
      this.switchEvento(msg);
    });
    notificationService.estado.asObservable().subscribe(msg => {
      this.updateToknowIDWSoc();
    });
    InitParams({
      url: environment.URLACCIONES,
      key: environment.KEYACCIONES
    });

  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
          //if(confirm("Existe una nueva versión del sistema. Aceptar para actualizar")) {
              window.location.reload();
          //}
      });
  }
  }

  isLoggedIn(message: string, isLoggedIn: boolean): void {
    console.log('HeaderComponent: verificacion sesion');

    if (isLoggedIn) {
      this.userData = this.localStorageService.get('userData');

      this.clienteComercialSeleccionado =
        this.userData.empresas.length > 1 ? this.userData.empresaSeleccionada : this.userData.clienteComercialSeleccionado;

      this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
    }
  }

  async redirectTo(pagina: string, modulo: string, submodulo: string) {
    const idToken = this.localStorageService.get('idToken');
    const accessToken = this.localStorageService.get('accessToken');
    const ud = this.localStorageService.get('ud');
    const ci = this.localStorageService.get('ci');
    const rt = this.localStorageService.get('refreshToken');
    await this.accion(modulo, submodulo);

    this.collapseElements();

    const url = `${environment.url_gestor}auth#access_token=${accessToken}&id_token=${idToken}&ud=${ud}&ci=${ci}&rt=${rt}&clicom=${this.userData.clienteComercialSeleccionado.codigo}&emp=${this.userData.empresaSeleccionada.nombre.split(' - ')[0]}&navigate=${pagina}`;
    window.location.replace(url);
  }

  doLogout(): void {
    console.log('HeaderComponent: cerrando sesion');
    this.userLoginService.logout();
  }

  confirmarClienteComercial() {
    if (this.userData.clienteComercialSeleccionado && this.userData.clienteComercialSeleccionado.codigo) {
      this.spinner.show();

      // tslint:disable-next-line: max-line-length
      this.userData.clienteComercialSeleccionado = this.userData.clientesComercial.find(x => x.codigo === this.clienteComercialSeleccionado.codigo);
      this.userData.empresaSeleccionada = this.clienteComercialSeleccionado;

      Promise.all([
        this.authService.cargarPermisosCliCom(this.userData.usuarioId, this.userData.clienteComercialSeleccionado.codigo),
        this.authService.cargarAccionesActivas(this.userData.usuarioId)
      ])
        .then(() => {
          // guarda en storage
          this.userData.clienteComercialSeleccionado = this.userData.clientesComercial.find(x => x.codigo === this.clienteComercialSeleccionado.codigo);
          this.userData.empresaSeleccionada = this.clienteComercialSeleccionado;
          this.localStorageService.set('userData', this.userData, 0, 's');
          //
          this.toastr.success(`Cliente comercial ${this.userData.clienteComercialSeleccionado.nombre} seleccionado`);
          this.collapseSidebar();
          this.router.navigate(['/']);
        }).catch((error: any) => {
        this.toastr.error('Ocurrio un error al obtener Permisos.');
      }).finally(() => {
        this.spinner.hide();
      });
    }
  }

  async switchEvento(event) {
    console.log('evento', event);
    if (event) {
      const data = JSON.parse(event.data);
      switch (data.tipo) {
        case 'onKnow':
          console.log('actulizado para enviar notificaicon');
          this.keeplive();
          this.llenarGrid();
          this.llenarTotalNotificacion();
          break;
        case 'onMessage':
          console.log('actulizado para enviar notificaicon');
          console.log('data', data);
          this.notificacionModalData = data;
          this.modalService.open(this.mdlNotificacion, {backdrop: 'static', keyboard: false});
          // alerta de nuevo mensaje  y refrezca la data de la tabla
          break;
      }

    }
  }

  updateToknowIDWSoc() {
    console.log('envio quieen soy');
    const data = {
      user: this.userData.rut,
      tipo: 'onKnow',
    };
    const payload = {action: 'onKnow', message: JSON.stringify(data)};
    this.notificationService.message.next(payload);

  }

  abrirNotificaciones() {
    this.llenarGrid();
    const noLeidos = [];
    for (const row of this.listadoNotificaciones.data) {
        if(!row.leido){
            noLeidos.push(row.id);
        }
    }
    if(noLeidos.length > 0) {
        this.cambioAleido(noLeidos);
        console.log('no leidos',noLeidos );
    }
    this.modalService.open(this.mdlNotificaciones, {backdrop: 'static', size: 'lg', keyboard: false});
    this.accion('Módulo de Notificaciones', null);
  }
  async descargarArchivo(dato) {
    const result = await this.notificationService.getSingURLNotificacion({
      metodo: 'get',
      key: dato.link,
      bucket: dato.bucket
    }).toPromise();
    window.open(result.message);
    //this.cambioAleido([dato.id]);
  }

  llenarGrid() {
    const data = {usuarioId: this.userData.rut};
    this.notificationService.getListadoNotificacion(data).subscribe((result: any) => {
      this.listadoNotificaciones = {
        data: result.notificaciones,
        total: result.notificaciones.length,
      };
    });
  }

  llenarTotalNotificacion() {
    this.notificationService.getListadoNotificacionTotal(this.userData.rut).subscribe((result: any) => {
      this.notificacionTotal = result.total;
    });
  }

  cambioAleido(ids) {
    this.notificationService.setNotificacionLeida(ids).subscribe((result: any) => {
      console.log(result);
      this.llenarTotalNotificacion();
    });
  }
  keeplive() {
    timer(0, 300000).subscribe(() => {
      console.log('keeplive');
      const data = {
        usuarioId: this.userData.rut,
        tipo: 'keepAlive',
      };
      const payload = {action: 'onMessage', message: JSON.stringify(data)};
      this.notificationService.message.next(payload);
    });
  }

  cambiaMensaje(mensaje) {
    const retorno = mensaje.replace('correctamente', '<strong>correctamente</strong>');
    return retorno;
  }

  async accion(modulo: string, submodulo: string){
    const token = await this.localStorageService.get('idToken');
    console.log('token',token);

    ClienteAuditoria({
      sistema: 'GCH Reportes',
      modulo,
      submodulo: submodulo ? submodulo : '',
      usuario: this.userData.rut,
      token,
      accion: `Usuario ${this.userData.rut} entra`
    });
  }

  toggleSidebar(origen: string, event: any): void {
    // if (origen === 'btnMenu' || (origen === 'mouse' && !event.toElement.classList.value.includes('menu'))) {
        if (origen === 'btnMenu') {
            this.btnMenu = !this.btnMenu;

            if (this.btnMenu) {
                this.expandSidebar();
            } else {
                this.collapseSidebar();
            }
        } else {
            if (event.type === 'mouseover') {
                this.expandSidebar();
            } else if (event.type === 'mouseout' && (
                event.x < 0
                || event.x >= 265
                || event.y < 70
                || event.srcElement.classList.value.includes('sidebar')
            )
            ) {
                this.collapseSidebar();
            }
        }
    // }
  }

  collapseElements() {
    this.collapsables = {
        cataCont: true,
        accioCapa: true,
        planesProg: true,
        liceHab: true,
        gestionAvi: true,
        mante: true
    };
  }

  expandSidebar() {
    this.width = 265;
    this.btnMenu = true;
  }

  collapseSidebar() {
    this.width = 55;
    this.btnMenu = false;
    this.collapseElements();
  }
}
