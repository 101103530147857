import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AuthGuard } from '../../_guards';

@Component({
  selector: 'app-inactivity-timer',
  templateUrl: './inactivity-timer.component.html',
  styleUrls: ['./inactivity-timer.component.css']
})
export class InactivityTimerComponent implements OnInit, OnDestroy {

  minutesDisplay = 0;
  secondsDisplay = 0;

  endTime = 10; // tiempo maximo de inactividad

  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;

  constructor(private authGuard: AuthGuard) { }

  ngOnInit() {
    this.resetTimer();
    this.authGuard.userActionOccured.pipe(
        takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  resetTimer(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime * 60;
    this.authGuard.flaginactive(false);
    this.timerSubscription = timer(0, interval).pipe(
        take(duration)
    ).subscribe(value =>
            this.render((duration - +value) * interval),
        err => { },
        () => { //enviar a checker si el token va a expirar
          this.authGuard.flaginactive(true);
        }
    );
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

}
