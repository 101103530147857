import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserDataLocalStorageModel } from 'src/app/models/userDataLocalStorage';
import { UserLoginService } from 'src/app/services/user-login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { GLOBALS } from 'src/app/shared/globals.shared';

@Component({
  selector: 'app-descargas',
  templateUrl: './descargas.component.html',
})
export class DescargasComponent implements OnInit, OnDestroy {
  notificaciones: any[] = [];
  userData: UserDataLocalStorageModel = {};
  data: { link: string, bucket: string, sistema: string, createdAt: number, expireAt: number } = null;
  hoy: Date;
  private suscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private userLoginService: UserLoginService,
    private notificationService: NotificationService,
  ) {
    this.userData = this.localStorageService.get('userData');
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  ngOnDestroy(): void {
    this.suscriptions.forEach(s => s.unsubscribe());
  }

  async isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.notificationService.obtenerFechaHoy()
      .pipe(catchError(_ => of(moment().format('DD/MM/YYYY'))))
      .subscribe(result => {
        this.hoy = moment.parseZone(result,'DD/MM/YYYY').local(true).toDate()
      });
      const filtro: any = {
        usuarioId: this.userData.rut,
        soloNoLeidas: false,
        sistema: 'Reportes'
      };
      this.getNotificaciones(filtro)
      const params = this.route.queryParams.subscribe(q => {
        try {
          const { token } = q;
          if (token && this.comprobarToken(token)) {

            this.descargarArchivo(this.data);
          };
        } catch (error) {
          this.toastr.error('Enlace invalido o expirado', GLOBALS.TOASTER.TITLE_ERROR)
        }
      })

      this.suscriptions.push(params);
    }
  }

  getNotificaciones(filtros) {
    this.spinner.show();
    this.notificationService.getListadoNotificacion(filtros)
      .pipe(
        map(({ notificaciones }) => notificaciones.filter(notificacion => notificacion.link && notificacion.estado === "success")),
        catchError(_ => of([])),
        finalize(() => { this.spinner.hide()})
      )
      .subscribe(
        (next: any) => {
          this.notificaciones = next;
        },
        (error: any) => {
          console.log(error);
          this.toastr.error('Ocurrio un error al cargar las notificaciones');
        }
      );
  }

  comprobarToken(token: string): boolean {
    try {
      if (!token) return false;
      const iv = CryptoJS.enc.Hex.parse('101112131415161718191a1b1c1d1e1f');
      const passwordHash = CryptoJS.SHA256(environment.password_documentos);
      const cipher = CryptoJS.AES.decrypt(decodeURIComponent(token), passwordHash, { iv }).toString(CryptoJS.enc.Utf8);
      const data = JSON.parse(cipher) as { link: string, bucket: string, sistema: string, createdAt: number, expireAt: number };
      if (!data) return false;

      //Validacion sistema
      if (data.sistema !== 'Reportes') {
        this.toastr.error('No estás autorizado para ingresar a esta página', GLOBALS.TOASTER.TITLE_ERROR);
        return false
      };
      //Expiracion token
      const limite = moment.unix(data.expireAt).toDate();
      if (limite < this.hoy) {
        this.toastr.error('Token de descarga expirado', GLOBALS.TOASTER.TITLE_ERROR);
        return false;
      };

      this.data = data;

      return true;
    } catch (error) {
      this.toastr.error('Token de descarga inválido', GLOBALS.TOASTER.TITLE_ERROR);
      this.router.navigate(['./descargas'])
      return false;
    }
  }

  async descargarArchivo(dataItem) {
    const result = await this.notificationService.getSingURLNotificacion({
      metodo: 'get',
      key: dataItem.link,
      bucket: dataItem.bucket
    }).toPromise();
    window.open(result.message);
  }

}
