import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {UserLoginService} from '../services/user-login.service';

import { AuthInterceptor } from '../interceptors/auth.interceptor';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {AuthGuard} from '../_guards';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private navigate:string;
    constructor(private authenticationService: AuthInterceptor,
                private router: Router,
                private toastr: ToastrService,
                private authGuard: AuthGuard
                ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 || err.status === 0 ) {
                this.authGuard.timerComplete();
            } else if (err.status === 502) {
                this.toastr.error('Ocurrio un error en la peticion de información al servidor, favor vuelva a intentar.');
            } else if (err.status === 504) {
                if(this.router.url.includes('token')){
                    const [basePath,queryParams] = this.router.url.split('?');
                    this.navigate = `${basePath}${queryParams ? '#' + queryParams : ''}`;
                }
                localStorage.clear();
                this.toastr.error('Su sesión ha caducado.');
                window.location.href = `${environment.URL_SIGNOUT}?callback=${this.navigate ? environment.URL + this.navigate : environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
            }

            const error: HttpErrorResponse = err;
            error.error.message = error.error.message || error.statusText;
            return throwError(error);
        }));
    }
}
