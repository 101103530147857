// angular
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
// utils
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor() {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(' - Interceptor URL: ' + req.url);
    /**
     * Variable que condiciona
     * si una URL que se encuentra
     * en transito en el metodo
     * se agrega el token de seguridad
     * en el header de la peticion.
     */
    let agregaTokenURL = true;
    /**
     * La URL de google Maps
     * se excluye el token que
     * se integra a la peticion
     * header.
     */
    if (req.url.split('maps.googleapis.com').length > 1) {
      agregaTokenURL = false;
      console.log(' - Se excluye token a la URL: ' + req.url);
    }
    let request = req;
    /**
     * Si la URL No es excluida
     * se realiza la intercepcion
     * para agregar el token de seguridad
     * a la peticion en el header para
     * obtener la autorizacion de los
     * servicios que se estan realozando
     * invocacion.
     */
    if (agregaTokenURL) {
      const idToken = JSON.parse(localStorage.getItem('idToken'));
      /**
       * Si el token existe
       * se agrega a la peticion
       * en el header.
       */

      if (idToken) {
        request = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${idToken._value}`)
        });
        console.log('AuthInterceptor: authorization agregado a peticion.');
      }
    }
    return next.handle(request);
  }

}
