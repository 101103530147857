import { Subject, timer } from 'rxjs';
import * as i0 from "@angular/core";
var TimeoutService = /** @class */ (function () {
    function TimeoutService() {
        this._count = 0;
        this._serviceId = 'idleTimeoutSvc-' + Math.floor(Math.random() * 10000);
        this._timeoutMilliseconds = 1000 * 60 * 40; // colocar el tiempo de duracion de la sesion.
        this.resetOnTrigger = false;
        this.dateTimerInterval = 1000 * 60 * 5;
        this.dateTimerTolerance = 1000 * 60;
        this.timeoutExpired = new Subject();
        console.log('Constructed idleTimeoutService ' + this._serviceId);
        this.timeoutExpired.subscribe(function (n) {
            console.log('timeoutExpired subject next.. ' + n.toString());
        });
        this.startTimer();
        this.startDateCompare();
    }
    TimeoutService.prototype.setSubscription = function () {
        var _this = this;
        this._timer = timer(this._timeoutMilliseconds);
        this.timerSubscription = this._timer.subscribe(function (n) {
            _this.timerComplete(n);
        });
    };
    TimeoutService.prototype.startDateCompare = function () {
        var _this = this;
        this.lastTime = (new Date()).getTime();
        this.dateTimer = timer(this.dateTimerInterval); // compare every five minutes
        this.dateTimerSubscription = this.dateTimer.subscribe(function (n) {
            var currentTime = (new Date()).getTime();
            var time = _this.lastTime + _this.dateTimerInterval + _this.dateTimerTolerance;
            console.log('this.lastTime + this.dateTimerInterval + this.dateTimerTolerance', time);
            console.log('currentTime', currentTime);
            console.log('time', time);
            console.log('currentTime - time', currentTime - time);
            if (currentTime > time) { // look for 10 sec diff
                console.log('Looks like the machine just woke up.. ');
            }
            else {
                console.log('Machine did not sleep.. ');
            }
            _this.dateTimerSubscription.unsubscribe();
            _this.startDateCompare();
        });
    };
    TimeoutService.prototype.startTimer = function () {
        if (this.timerSubscription) {
            this.stopTimer();
        }
        this.setSubscription();
    };
    TimeoutService.prototype.stopTimer = function () {
        this.timerSubscription.unsubscribe();
    };
    TimeoutService.prototype.resetTimer = function () {
        this.startTimer();
    };
    TimeoutService.prototype.forcedTimeout = function () {
        this.timeoutExpired.next(2400000);
    };
    TimeoutService.prototype.timerComplete = function (n) {
        this.timeoutExpired.next(++this._count);
        if (this.resetOnTrigger) {
            this.startTimer();
        }
    };
    TimeoutService.ngInjectableDef = i0.defineInjectable({ factory: function TimeoutService_Factory() { return new TimeoutService(); }, token: TimeoutService, providedIn: "root" });
    return TimeoutService;
}());
export { TimeoutService };
