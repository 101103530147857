export class EmpresaModel {
    public Codigo:number;
    public Rut:string;
    public Nombre:string;
    public Holding:string;
    public Direccion:string
    public Comuna:string

    constructor(empresaJson?: any) {
        this.Codigo = empresaJson && empresaJson.Codigo || null;
        this.Rut = empresaJson && empresaJson.Rut || null;
        this.Nombre = empresaJson && empresaJson.Nombre || null;
        this.Holding = empresaJson && empresaJson.Holding || null;
        this.Direccion = empresaJson && empresaJson.Direccion || null;
        this.Comuna = empresaJson && empresaJson.Comuna || null;
    }
}
