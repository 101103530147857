import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CognitoUtilService } from './cognito.service';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { LocalStorageService } from 'angular-web-storage';
import * as AWS from 'aws-sdk/global';
import * as STS from 'aws-sdk/clients/sts';
import * as i0 from "@angular/core";
import * as i1 from "./cognito.service";
import * as i2 from "@angular/common/http";
import * as i3 from "angular-web-storage";
var UserLoginService = /** @class */ (function () {
    function UserLoginService(cognitoUtil, http, localStorageService) {
        var _this = this;
        this.cognitoUtil = cognitoUtil;
        this.http = http;
        this.localStorageService = localStorageService;
        this.getLoggedInUser = new EventEmitter();
        this.onLoginSuccess = function (callback, session) {
            console.log('En authenticateUser onSuccess callback');
            console.log(session);
            AWS.config.credentials = _this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());
            var clientParams = {};
            if (environment.STS_ENDPOINT) {
                clientParams.endpoint = environment.STS_ENDPOINT;
            }
            _this.localStorageService.set('idToken', session.getIdToken().getJwtToken());
            var sts = new STS(clientParams);
            sts.getCallerIdentity(function (err, data) {
                console.log('UserLoginService: credenciales AWS exitosamente seteadas');
                callback.cognitoCallback(null, session);
            });
        };
        this.onLoginError = function (callback, err) {
            callback.cognitoCallback(err.message, null);
        };
        console.log('UserLoginService: Constructor');
    }
    UserLoginService.prototype.authenticate = function (username, password, newPassword, callback) {
        var _this = this;
        console.log('UserLoginService: iniciando autenticacion');
        var authenticationData = {
            Username: username,
            Password: password,
        };
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        var userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        console.log('UserLoginService: Parametros seteados...Autenticando el usuario');
        var cognitoUser = new CognitoUser(userData);
        console.log('UserLoginService: config es ' + AWS.config);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                if (newPassword) {
                    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                        onFailure: function (error) { return _this.onLoginError(callback, error); },
                        onSuccess: function (result) { return _this.onLoginSuccess(callback, result); }
                    });
                }
                else {
                    callback.cognitoCallback('User needs to set password.', null);
                }
            },
            onSuccess: function (result) { return _this.onLoginSuccess(callback, result); },
            onFailure: function (err) { return _this.onLoginError(callback, err); },
            mfaRequired: function (challengeName, challengeParameters) {
                callback.handleMFAStep(challengeName, challengeParameters, function (confirmationCode) {
                    cognitoUser.sendMFACode(confirmationCode, {
                        onSuccess: function (result) { return _this.onLoginSuccess(callback, result); },
                        onFailure: function (err) { return _this.onLoginError(callback, err); }
                    });
                });
            }
        });
    };
    UserLoginService.prototype.logout = function () {
        console.log('UserLoginService: Cerrando sesion');
        this.cognitoUtil.getCurrentUser().signOut();
        localStorage.clear();
        window.location.href = environment.URL_SIGNOUT + "?callback=" + environment.URL_AUTH + "&code=" + environment.CLIENT_ID;
    };
    UserLoginService.prototype.isAuthenticated = function (callback) {
        var thisLocalStorage = this.localStorageService;
        if (callback == null) {
            throw new Error(('UserLoginService: Callback en isAuthenticated() no puede ser null'));
        }
        var cognitoUser = this.cognitoUtil.getCurrentUser();
        console.log('cognitoUser isAuthenticated Reportes', cognitoUser);
        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    console.log('UserLoginService: No se logro recuperar la sesion: ' + err, err.stack);
                    callback.isLoggedIn(err, false);
                }
                else {
                    console.log('session:' + session);
                    console.log('UserLoginService:' + session.isValid());
                    callback.isLoggedIn(err, session.isValid());
                }
            });
        }
        else {
            console.log('callback', callback);
            console.log('UserLoginService: no se logro recuperar el CurrentUser');
            callback.isLoggedIn('No se logro recuperar el CurrentUser', false);
        }
    };
    UserLoginService.prototype.validateToken = function (token) {
        var body = JSON.stringify(token);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.ENDPOINTS.COMUNES + "/authCognito", body, { headers: headers });
    };
    UserLoginService.prototype.refrestoken = function (token) {
        var body = JSON.stringify(token);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.CATENA_USR + "/tok/reftok", body, { headers: headers });
    };
    UserLoginService.ngInjectableDef = i0.defineInjectable({ factory: function UserLoginService_Factory() { return new UserLoginService(i0.inject(i1.CognitoUtilService), i0.inject(i2.HttpClient), i0.inject(i3.LocalStorageService)); }, token: UserLoginService, providedIn: "root" });
    return UserLoginService;
}());
export { UserLoginService };
