import { Deserializable } from '../interfaces/deserializable.interface';

export class AportesModel implements Deserializable<AportesModel> {
    public codigoEmpresa: string;
    public codigoSucursal: string;
    public Empresa: string;
    public codigoAporte: string;
    public fechaImputacion: string;
    public oficinaVentas: string;
    public tipoTransaccion: string;
    public fechaTransaccion: string;
    public nroDocumento: string;
    public banco: string;
    public monto: string;

    constructor(aportesJson?: any) {
        this.codigoEmpresa = aportesJson && aportesJson.codigoempresa || null;
        this.codigoSucursal = aportesJson && aportesJson.codigosucursal || null;
        this.Empresa = aportesJson && aportesJson.empresa || null;
        this.codigoAporte = aportesJson && aportesJson.codigoaporte || null;
        this.fechaImputacion = aportesJson && aportesJson.fechaimputacion || null;
        this.oficinaVentas = aportesJson && aportesJson.oficinaventas || null;
        this.tipoTransaccion = aportesJson && aportesJson.tipotransaccion || null;
        this.fechaTransaccion = aportesJson && aportesJson.fechatransaccion || null;
        this.nroDocumento = aportesJson && aportesJson.nrodocumento || null;
        this.banco = aportesJson && aportesJson.banco || null;
        this.monto = aportesJson && aportesJson.monto.toString().trim() || null;
    }

    deserialize(input: any): AportesModel {
        Object.assign(this, input);
        return this;
    }
}
