import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GLOBALS, GlobalService } from '../../shared/globals.shared';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'angular-web-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CartolaService } from 'src/app/services/cartola.service';
import { ClienteComercialService } from 'src/app/services/cliente-comercial.service';
import { ConsolidadoService } from 'src/app/services/consolidado.service';
import { UserLoginService } from 'src/app/services/user-login.service';
import { PermisosCliComLocalStorageModel } from 'src/app/models/permisosCliComLocalStorage';
import { UserDataLocalStorageModel } from 'src/app/models/userDataLocalStorage';
import { Subject, interval } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rep-cartola-sap-envio-masivo',
  templateUrl: './rep-cartola-sap-envio-masivo.component.html',
  styleUrls: ['./rep-cartola-sap-envio-masivo.component.scss'],
  providers: [GlobalService]
})
export class RepCartolaSAPEnvioMasivoComponent implements OnInit {
  urlGestion: string = environment.url_gestor;
  listadoEjecuciones: any[] = [];

  userData: UserDataLocalStorageModel = {};

  permisosModulosAcciones: any = {};
  permisos: Array<PermisosCliComLocalStorageModel>;
  
  $subject: Subject<any> = new Subject();
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private cartolaService: CartolaService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userLoginService: UserLoginService
    ) { }
  ngOnInit() {
    console.log('RepCartolaComponent: OnInit');

    this.userData =  this.localStorageService.get('userData');
    this.userLoginService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

        this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
        this.permisos = this.localStorageService.get('permisos');

        if (this.permisosModulosAcciones.REPOCARTSAP.acciones.includes('REPOCARTSAP_MAS_PDF')) {
          this.buscarEjecuciones();
        } else {
          this.toastr.error(`No estás autorizado para ingresar a esta página`, GLOBALS.TOASTER.TITLE_ERROR);
          this.router.navigate(['/']);
        }

    }
  }

  buscarEjecuciones(){
    const params = {
      usuario: this.userData.rut
    };
    this.spinner.show();
    this.cartolaService.buscarEjecucionesCartola(params)
    .pipe(finalize(() => this.spinner.hide()))
    .subscribe((res) => {
      this.listadoEjecuciones = res.data;

      const sub = interval(10000).
        pipe(
          takeUntil(this.$subject),
          switchMap(() => this.cartolaService.buscarEjecucionesCartola(params))
          )
        .subscribe(
          (res) => {
            console.log('resultado',res);
            this.listadoEjecuciones = res.data;
        },(err) => this.$subject.next(err));
    });
  }
}
