import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'miles'
})
export class MilesPipe implements PipeTransform {

  transform(value: any, args?: string): string {
      if (value) {
        return value.replace(',', '.');
      }
      return '';
    }
}
