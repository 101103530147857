import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartolaService {

  constructor(private http: HttpClient) {
    console.log('CartolaService: constructor');
  }

  /**
   * Retorna el Reporte de Consolidado Cartola
   * @param filtrosReporte Filtros Ingresados
   */
  reporteCartola(filtrosReporte: any): Observable<any> {
    const body = JSON.stringify(filtrosReporte);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CARTOLA}/reporteCartola/`, body, { headers });
  }

  /**
   * Retorna el Reporte de Consolidado Cartola separado por Sociedad
   * @param filtrosReporte Filtros Ingresados
   */
  reporteCartolaSociedad(filtrosReporte: any): Observable<any> {
    const body = JSON.stringify(filtrosReporte);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CARTOLA}/reporteCartolaSociedad/`, body, { headers });
  }
  /**
   * Inicia la generacion de la cartola SAP
   * @param filtrosReporte Filtros Ingresados
   */
  iniciarCartolaMasiva(filtrosReporte: any): Observable<any> {
    const body = JSON.stringify(filtrosReporte);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CARTOLA}/iniciarGeneracionMasiva/`, body, { headers });
  }

  /**
   * Obtine el destinatario de la cartola SAP
   * @param filtrosReporte Filtros Ingresados
   */
  obtenerDestinatarioCartola(codEmpresa: number): Observable<any> {
    const body = JSON.stringify(codEmpresa);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CARTOLA}/destinatarioCartola/`, body, { headers });
  }

   /**
   * Obtine las ejecuciones de envio masivo
   * @param usuario Usuario que realiza la ejecución
   */
   buscarEjecucionesCartola(params: any): Observable<any> {
    const body = JSON.stringify(params);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(`${environment.ENDPOINTS.CARTOLA}/buscarEjecuciones/`, body, { headers });
  }
}
