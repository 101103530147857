import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UserLoginService } from '../../services/user-login.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'angular-web-storage';
import { Router } from '@angular/router';
import { InitParams, ClienteAuditoria } from 'otic-cliente-auditoria';
var HomeReportesComponent = /** @class */ (function () {
    function HomeReportesComponent(userLoginService, localStorageService, router) {
        this.userLoginService = userLoginService;
        this.localStorageService = localStorageService;
        this.router = router;
        // opciones: any;
        this.permisosModulosAcciones = {};
        this.urlGestion = environment.url_gestor;
        console.log('HomeReportesComponent: constructor');
        InitParams({
            url: environment.URLACCIONES,
            key: environment.KEYACCIONES
        });
    }
    HomeReportesComponent.prototype.ngOnInit = function () {
        this.userLoginService.isAuthenticated(this);
    };
    HomeReportesComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        if (isLoggedIn) {
            this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
            if (JSON.stringify(this.permisosModulosAcciones) === '{}') {
                this.router.navigate(['/auth']);
            }
        }
        else {
            this.router.navigate(['/auth']);
        }
    };
    HomeReportesComponent.prototype.redireccionar = function (page, modulo, submodulo) {
        this.accion(modulo, submodulo);
        this.router.navigate(page);
    };
    HomeReportesComponent.prototype.accion = function (modulo, submodulo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, userData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localStorageService.get('idToken')];
                    case 1:
                        token = _a.sent();
                        return [4 /*yield*/, this.localStorageService.get('userData')];
                    case 2:
                        userData = _a.sent();
                        ClienteAuditoria({
                            sistema: 'GCH Reportes',
                            modulo: modulo,
                            submodulo: submodulo ? submodulo : '',
                            usuario: userData.rut,
                            token: token,
                            accion: "Usuario " + userData.rut + " entra"
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return HomeReportesComponent;
}());
export { HomeReportesComponent };
