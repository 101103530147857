<!-- <app-header *ngIf="!router.url.includes('auth')"></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="ball-newton-cradle"></ngx-spinner>
<router-outlet></router-outlet>
<app-footer *ngIf="!router.url.includes('auth')"></app-footer> -->

<ng-container *ngIf="location.pathname == '/auth';else elseTemplate">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="ball-newton-cradle"></ngx-spinner>
    <router-outlet></router-outlet>
</ng-container>
<ng-template #elseTemplate>
    <app-header></app-header>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="ball-newton-cradle"></ngx-spinner>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</ng-template>