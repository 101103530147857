import { OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
var TimeoutdialogComponent = /** @class */ (function () {
    function TimeoutdialogComponent(activeModal, changeRef) {
        this.activeModal = activeModal;
        this.changeRef = changeRef;
        this.showCancel = false;
        this.confirmText = 'Ok';
        this.cancelText = 'Cancel';
        // console.log("DialogComponent construct");
    }
    TimeoutdialogComponent.prototype.ngOnInit = function () {
        // console.log("DialogComponent init");
    };
    return TimeoutdialogComponent;
}());
export { TimeoutdialogComponent };
var TimeoutdialogService = /** @class */ (function () {
    function TimeoutdialogService(modalService) {
        this.modalService = modalService;
    }
    TimeoutdialogService.prototype.confirm = function () {
        var modalRef = this.modalService.open(TimeoutdialogComponent);
        var instance = modalRef._windowCmptRef.instance;
        instance.windowClass = '';
        // setImmediate(() => {
        //    instance.windowClass = 'custom-show'
        // })
        setTimeout(function () {
            instance.windowClass = 'custom-show';
        }, 0);
        var fx = modalRef._removeModalElements.bind(modalRef);
        modalRef._removeModalElements = function () {
            instance.windowClass = '';
            setTimeout(fx, 250);
        };
        modalRef.componentInstance.title = 'Discard Changes?';
        modalRef.componentInstance.message = 'Are you sure you want to discard your changes?';
        modalRef.componentInstance.changeRef.markForCheck();
        return modalRef.result;
    };
    TimeoutdialogService.prototype.open = function (title, message, showCancel, confirmText, cancelText, options) {
        if (showCancel === void 0) { showCancel = false; }
        if (confirmText === void 0) { confirmText = 'Ok'; }
        if (cancelText === void 0) { cancelText = 'Cancel'; }
        if (options === void 0) { options = { size: 'sm' }; }
        var modalRef = this.modalService.open(TimeoutdialogComponent, options);
        var instance = modalRef._windowCmptRef.instance;
        // setImmediate(() => {
        //    instance.windowClass = "custom-show";
        // })
        setTimeout(function () {
            instance.windowClass = 'custom-show';
        }, 0);
        var fx = modalRef._removeModalElements.bind(modalRef);
        modalRef._removeModalElements = function () {
            instance.windowClass = '';
            setTimeout(fx, 250);
        };
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.showCancel = showCancel;
        modalRef.componentInstance.confirmText = confirmText;
        modalRef.componentInstance.cancelText = cancelText;
        modalRef.componentInstance.changeRef.markForCheck();
        return modalRef.result;
    };
    TimeoutdialogService.ngInjectableDef = i0.defineInjectable({ factory: function TimeoutdialogService_Factory() { return new TimeoutdialogService(i0.inject(i1.NgbModal)); }, token: TimeoutdialogService, providedIn: "root" });
    return TimeoutdialogService;
}());
export { TimeoutdialogService };
