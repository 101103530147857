<div class="container-fluid mb-4 fondo-gris-ok">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-12 mt-2">
                <h1>Descargas</h1>
            </div>
        </div>
    </div>
</div>
<main>
<div class="container-fluid fondo-blanco mx-auto mb-3" style="margin-top:-20px;">
        <div class="container pt-4">
            <div class="col-xl-12 col-lg-12 col-12 mx-auto margin-top-20 table-responsive">

                <kendo-grid
                [kendoGridBinding]="notificaciones"
                [pageable]="true"
                [pageSize]="20"
                >
                <kendo-grid-column field="cliComercial" title="Cliente comercial"></kendo-grid-column>
                <kendo-grid-column field="mensaje" title="Mensaje"></kendo-grid-column>
                <kendo-grid-column field="fecha" title="Fecha">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.fecha | date :'dd/MM/yyyy HH:mm' }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="modulo" title="Modulo"></kendo-grid-column>
                <kendo-grid-column field="sistema" title="Sistema"></kendo-grid-column>
                <kendo-grid-column field="link" title="Archivo" width="100">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <button class="btn btn-primary btn-sm mr-1 mb-1" (click)="descargarArchivo(dataItem)" data-toggle="tooltip" ngbTooltip="Haz clic para descargar el archivo." placement="left">
                            <img class="icon-md" src="assets/img/download.png" />
                        </button>
                      </ng-template>
                </kendo-grid-column>
            </kendo-grid>
            </div>
        </div>
    </div>
</main>