import { Deserializable } from '../interfaces/deserializable.interface';

export class ContactoAportesModel implements Deserializable<ContactoAportesModel> {
    public codigoEmpresa: string;
    public empresa: string;
    public codigoAporte: string;
    public ejecutivoComercial: string;
    public ejecutivoComercialMail: string;
    public asistenteComercial: string;
    public asistenteComercialMail: string;
    public usuario: string;
    public usuarioMail: string;
    public mensaje: string;

    constructor(contactoAportesJson?: any) {
        this.codigoEmpresa = contactoAportesJson && contactoAportesJson.codigoempresa || null;
        this.empresa = contactoAportesJson && contactoAportesJson.empresa || null;
        this.codigoAporte = contactoAportesJson && contactoAportesJson.codigoaporte || null;
        this.ejecutivoComercial = contactoAportesJson && contactoAportesJson.ejecutivoComercial || null;
        this.ejecutivoComercialMail = contactoAportesJson && contactoAportesJson.ejecutivoComercialMail || null;
        this.asistenteComercial = contactoAportesJson && contactoAportesJson.asistenteComercial || null;
        this.asistenteComercialMail = contactoAportesJson && contactoAportesJson.asistenteComercialMail || null;
        this.usuario = contactoAportesJson && contactoAportesJson.usuario || null;
        this.usuarioMail = contactoAportesJson && contactoAportesJson.usuarioMail || null;
        this.mensaje = contactoAportesJson && contactoAportesJson.mensaje || null;
    }

    deserialize(input: any): ContactoAportesModel {
        Object.assign(this, input);
        return this;
    }
}
