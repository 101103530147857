import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginService } from '../services/user-login.service';
import { from, timer, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { TimeoutService } from '../services/timeout.service';
import { TimeoutdialogService } from '../services/timeoutdialog.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, userLoginService, idleTimeoutSvc, toastr, dialogSvc, titleService) {
        this.router = router;
        this.userLoginService = userLoginService;
        this.idleTimeoutSvc = idleTimeoutSvc;
        this.toastr = toastr;
        this.dialogSvc = dialogSvc;
        this.titleService = titleService;
        // tslint:disable-next-line:variable-name
        this._userActionOccured = new Subject();
        this.getLoggedInUser = new EventEmitter();
        // tslint:disable-next-line:variable-name
        this._counter = 0;
        // tslint:disable-next-line:variable-name
        this._status = 'Initialized.';
    }
    Object.defineProperty(AuthGuard.prototype, "userActionOccured", {
        get: function () { return this._userActionOccured.asObservable(); },
        enumerable: true,
        configurable: true
    });
    AuthGuard.prototype.canActivate = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (route.data.redirect) {
                            this._navigate = "/" + route.routeConfig.path + ((route.queryParams) ? '#' + Object.entries(route.queryParams).map(function (_a) {
                                var key = _a[0], value = _a[1];
                                return key + "=" + encodeURIComponent(value);
                            }).join('&') : '');
                        }
                        if (!(route.fragment && route.fragment.includes('access_token'))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.router.navigate(['/auth'], {
                                fragment: route.fragment
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.userLoginService.isAuthenticated(this);
                        _a.label = 3;
                    case 3: return [2 /*return*/, true];
                }
            });
        });
    };
    // isLoggedIn
    AuthGuard.prototype.isLoggedIn = function (message, isLoggedIn) {
        console.log('authguard: verificacion sesion', this._idleTimerSubscription);
        console.log('localStorage', localStorage);
        if (isLoggedIn) {
            if (this._idleTimerSubscription === undefined) {
                this.sessionTimeout();
            }
        }
        else {
            this.logout();
        }
        if (!localStorage.getItem('refreshToken')) {
            this.logout();
        }
    };
    AuthGuard.prototype.sessionTimeout = function () {
        this.startCounter();
        this.idleTimeoutSvc.resetTimer();
        this.timerout();
    };
    AuthGuard.prototype.timerout = function () {
        var _this = this;
        this._idleTimerSubscription = this.idleTimeoutSvc.timeoutExpired.subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var timmer, modalPromise, newObservable;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        timmer = setTimeout(function () {
                            console.log('Sesión caducada despues de 10 min');
                            _this.logout();
                        }, 1000 * 60 * 10);
                        if (!this._flagInactivity) return [3 /*break*/, 1];
                        this.checkPageFocus();
                        modalPromise = this.dialogSvc.open('Sesión Expirando!', 'Tu sesión esta apunto de expirar. Necesitas mas tiempo?', true, 'Si', 'No');
                        newObservable = from(modalPromise);
                        newObservable.subscribe(function (resp) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(resp === true)) return [3 /*break*/, 2];
                                        console.log('Extending session inactive...');
                                        clearInterval(timmer);
                                        this.checkPageFocus();
                                        return [4 /*yield*/, this.refreshtoken()];
                                    case 1:
                                        _a.sent();
                                        this.startCounter();
                                        this.idleTimeoutSvc.resetTimer();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        console.log('Not extending session...');
                                        clearInterval(timmer);
                                        this.logout();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }, function (reason) {
                            console.log('Dismissed ' + reason);
                            _this._status = 'Sesión no fue extendida.';
                            _this.logout();
                        });
                        return [3 /*break*/, 3];
                    case 1:
                        console.log('Extending session active...');
                        clearInterval(timmer);
                        return [4 /*yield*/, this.refreshtoken()];
                    case 2:
                        _a.sent();
                        this.startCounter();
                        this.idleTimeoutSvc.resetTimer();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    AuthGuard.prototype.startCounter = function () {
        var _this = this;
        if (this._timerSubscription) {
            this._timerSubscription.unsubscribe();
        }
        this._counter = 0;
        this._timer = timer(1000, 1000);
        this._timerSubscription = this._timer.subscribe(function (n) {
            _this._counter++;
        });
    };
    AuthGuard.prototype.timerComplete = function () {
        this.idleTimeoutSvc.forcedTimeout();
    };
    AuthGuard.prototype.reset = function () {
        this.startCounter();
        this._status = 'Initialized.';
        this.idleTimeoutSvc.resetTimer();
    };
    AuthGuard.prototype.logout = function () {
        console.log('auth guard logout');
        console.log('redirect after logout', this._navigate);
        localStorage.clear();
        this.toastr.error('Su sesión ha caducado.');
        window.location.href =
            environment.URL_SIGNOUT + "?callback=" + (this._navigate ? environment.URL + this._navigate : environment.URL_AUTH) + "&code=" + environment.CLIENT_ID;
    };
    AuthGuard.prototype.refreshtoken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ci, retoken, token;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                ci = JSON.parse(localStorage.getItem('ci'));
                retoken = JSON.parse(localStorage.getItem('refreshToken'));
                console.log('ci', ci);
                token = {
                    app_id: ci._value,
                    token: retoken._value
                };
                this.userLoginService.refrestoken(token).subscribe(function (response) {
                    var idToken = { _value: response.IdToken };
                    var accessToken = { _value: response.AccessToken };
                    localStorage.setItem('idToken', JSON.stringify(idToken));
                    localStorage.setItem('accessToken', JSON.stringify(accessToken));
                }, function (error) {
                    console.log(error);
                    _this.toastr.warning('Ocurrió un error en su sesión de usuario y deberá reiniciarla ');
                    _this.logout();
                });
                return [2 /*return*/];
            });
        });
    };
    AuthGuard.prototype.notifyUserAction = function () {
        this._userActionOccured.next();
    };
    AuthGuard.prototype.flaginactive = function (item) {
        this._flagInactivity = item;
    };
    AuthGuard.prototype.checkPageFocus = function () {
        var titulo = this.titleService.getTitle();
        if (document.hasFocus()) {
            var last = titulo.split(')');
            this.titleService.setTitle(last[last.length - 1]);
        }
        else {
            console.log('This document does not have the focus.');
            var newTitle = '(ALERTA)' + titulo;
            this.titleService.setTitle(newTitle);
        }
    };
    return AuthGuard;
}());
export { AuthGuard };
