import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClienteComercialService = /** @class */ (function () {
    function ClienteComercialService(http) {
        this.http = http;
        console.log('ClienteComercialService: constructor');
    }
    /**
     * Lista Empresas y sus Sucursales por Usuario
     */
    ClienteComercialService.prototype.listarEmpresasSucursales = function (sucursalesAutorizadas) {
        var body = JSON.stringify(sucursalesAutorizadas);
        return this.http.post(environment.ENDPOINTS.CLIENTE_COMERCIAL + "/listarEmpresasSucursales/", body, {
            headers: { 'Content-Type': 'application/json' }
        });
    };
    /**
     * Obtiene el Contacto Comercial de la Sucursal
     */
    ClienteComercialService.prototype.obtenerContacto = function (codigoSucursal) {
        var body = JSON.stringify(codigoSucursal);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CLIENTE_COMERCIAL + "/obtenerContacto/", body, { headers: headers });
    };
    /**
     * Obtiene la informacion de la empresa
     */
    ClienteComercialService.prototype.obtenerEmpresa = function (codigoEmpresa) {
        return this.http.get(environment.ENDPOINTS.CLIENTE_COMERCIAL + "/obtenerEmpresa/" + codigoEmpresa);
    };
    ClienteComercialService.ngInjectableDef = i0.defineInjectable({ factory: function ClienteComercialService_Factory() { return new ClienteComercialService(i0.inject(i1.HttpClient)); }, token: ClienteComercialService, providedIn: "root" });
    return ClienteComercialService;
}());
export { ClienteComercialService };
