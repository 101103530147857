import * as tslib_1 from "tslib";
// Shared
import { GLOBALS, GlobalService } from '../../shared/globals.shared';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { LocalStorageService } from 'angular-web-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { EmpresasSucursalModel } from 'src/app/models/empresasSucursal.model';
import { CartolaService } from 'src/app/services/cartola.service';
import { ClienteComercialService } from 'src/app/services/cliente-comercial.service';
import { UserLoginService } from 'src/app/services/user-login.service';
import { EmpresaModel } from 'src/app/models/empresa.model';
import { ConsolidadoService } from 'src/app/services/consolidado.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var RepCartolaSAPComponent = /** @class */ (function () {
    function RepCartolaSAPComponent(spinner, toastr, modalService, globalService, clienteComercialService, cartolaService, consolidadoService, router, localStorageService, userLoginService) {
        this.spinner = spinner;
        this.toastr = toastr;
        this.modalService = modalService;
        this.globalService = globalService;
        this.clienteComercialService = clienteComercialService;
        this.cartolaService = cartolaService;
        this.consolidadoService = consolidadoService;
        this.router = router;
        this.localStorageService = localStorageService;
        this.userLoginService = userLoginService;
        this.min = null;
        this.max = null;
        this.urlGestion = environment.url_gestor;
        this.fechaActual = [
            new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
            (new Date().getMonth() + 1).toString().length === 1 ?
                '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
            new Date().getFullYear()
        ].join('/');
        this.empresasSucursales = [];
        this.parsedData = [];
        this.checkedKeys = [];
        this.searchTerm = '';
        this.empresasSucursalSeleccionadas = [];
        this.empresas = [];
        this.empresasSeleccionadas = [];
        this.empresaSeleccionado = new EmpresaModel();
        this.fecIni = null;
        this.fecFin = null;
        this.periodo = new Date().getFullYear();
        this.userData = {};
        this.errorFiltro = {
            empresaSucursal: false,
            filtros: false,
            fecIni: false,
            fecFin: false
        };
        this.cartolaCompleta = {
            // Cabecera
            aporteComprometido: 0,
            unoAnterior: 0,
            topeInvDnc: 0,
            comision: {
                comisionPromedio: 0,
                comisionCollapse: true,
                comisiones: []
            },
            // Aporte
            aportesCollapse: true,
            aportes: {
                aporteImputadoTotal: 0,
                aportesImputadosAnioAnteriorTotal: 0,
                aportesImputados: [],
                aportesImputadosAnioAnterior: [],
                aportes: []
            },
            // Cuenta Normal y Reparto
            ctaNormalyReparto: 0,
            ctaNormalyRepartoDisp: 0,
            ctaNormalYRepartoInter: 0,
            ctaNormalyRepartoMinimo: 0,
            ctaNormalyRepartoCom: 0,
            ctaNormalyRepartoCollapse: true,
            aporteImputado: {
                aporteImputadoTotal: 0,
                aporteImputadoCollapse: true,
                aportesImputados: [],
            },
            invCtaNormal: {
                invCtaNormalTotal: 0,
                invCtaNormalyRepCollapse: true,
                subClienteNormalyRep: []
            },
            invCtaNormalReparto: {
                invCtaNormalTotal: 0,
                invCtaNormalyRepCollapse: true,
                subClienteNormalyRep: []
            },
            topeInvVyTNormal: {
                topeInvVyTNormalTotal: 0,
                topeInvVyTNormalCollapse: true,
                topeInvVyTNormales: [],
            },
            invVyTNormal: {
                invVyTNormalTotal: 0,
                invVyTNormalCollapse: true,
                invVyTNormales: [],
            },
            comisionInterm: {
                comisionIntermTotal: 0,
                comisionIntermCollapse: true,
                comisionesIntermediadas: [],
            },
            comisionNoInterm: {
                comisionNoIntermTotal: 0,
                comisionNoIntermCollapse: true,
                comisionesNoIntermediadas: [],
            },
            // Cuenta Excedentes
            ctaExcedentes: 0,
            ctaExcedentesInter: 0,
            saldoVyTExcedentesTotal: 0,
            ctaExcedentesCollapse: true,
            saldoExcedentesLiq: {
                saldoExcedentesLiqTotal: 0,
                saldoExcedentesLiqCollapse: true,
                saldoExcedentesLiqs: []
            },
            invCtaExcedentes: {
                invCtaExcedentesTotal: 0,
                invCtaExcedentesCollapse: true,
                subClienteExcedentes: []
            },
            invCtaExcedentesReparto: {
                invCtaExcedentesTotal: 0,
                invCtaExcedentesCollapse: true,
                subClienteExcedentes: []
            },
            topeInvVyTExcedentes: {
                topeInvVyTExcedentesTotal: 0,
                topeInvVyTExcedentesCollapse: true,
                topeInvVyTExcedentes: [],
            },
            invVyTExcedentes: {
                invVyTExcedentesTotal: 0,
                invVyTExcedentesCollapse: true,
                invVyTExcedentes: [],
            },
            becasMandato: {
                becasMandatoTotal: 0,
                becasMandatoCollapse: true,
                becasMandatos: [],
            }
        };
        this.detalleCursos = {
            normal: {
                invCtaNormal: 0,
                collapse: true,
                cursos: [],
                totales: {
                    cursootic: 0,
                    viaticootic: 0,
                    trasladootic: 0,
                    totalotic: 0,
                    cursoempresa: 0,
                    viaticoempresa: 0,
                    trasladoempresa: 0,
                    totalempresa: 0,
                    total: 0
                }
            },
            reparto: {
                invCtaRep: 0,
                collapse: true,
                cursos: [],
                totales: {
                    cursootic: 0,
                    viaticootic: 0,
                    trasladootic: 0,
                    totalotic: 0,
                    cursoempresa: 0,
                    viaticoempresa: 0,
                    trasladoempresa: 0,
                    totalempresa: 0,
                    total: 0
                }
            },
            excedentes: {
                invCtaExc: 0,
                collapse: true,
                cursos: [],
                totales: {
                    cursootic: 0,
                    viaticootic: 0,
                    trasladootic: 0,
                    totalotic: 0,
                    cursoempresa: 0,
                    viaticoempresa: 0,
                    trasladoempresa: 0,
                    totalempresa: 0,
                    total: 0
                }
            },
            excedentesReparto: {
                invCtaExcRep: 0,
                collapse: true,
                cursos: [],
                totales: {
                    cursootic: 0,
                    viaticootic: 0,
                    trasladootic: 0,
                    totalotic: 0,
                    cursoempresa: 0,
                    viaticoempresa: 0,
                    trasladoempresa: 0,
                    totalempresa: 0,
                    total: 0
                }
            },
        };
        this.destinatario = {
            nombre: '',
            mail: ''
        };
        this.contacto = {
            nombre: '',
            mail: '',
            tipo: '',
            telefono: ''
        };
        this.permisosModulosAcciones = {};
        //Exportacion PDF
        this.seccionesCartola = [
            { nombre: 'Cartola Cuenta Corriente', codigo: 1, valor: 'Cuenta Corriente' },
            { nombre: 'Detalle de aportes', codigo: 2, valor: 'Aportes' },
            { nombre: 'Detalle de cursos contratados', codigo: 3, valor: 'Cursos' },
        ];
        this.filtrosSeccionesCartola = [];
        this.selectableSettings = {
            checkboxOnly: true,
        };
        /**
         * Trae los hijos del árbol
         */
        this.children = function (dataItem) { return of(dataItem.items); };
        /**
         * Valida si tiene hijos el árbol
         */
        this.hasChildren = function (dataItem) { return !!dataItem.items; };
        console.log('RepCartolaComponent: constructor');
    }
    RepCartolaSAPComponent.prototype.ngOnInit = function () {
        console.log('RepCartolaComponent: OnInit');
        this.userLoginService.isAuthenticated(this);
        this.userData = this.localStorageService.get('userData');
    };
    RepCartolaSAPComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        if (isLoggedIn) {
            this.router.events.subscribe(function (evt) {
                if (!(evt instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
            this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
            this.permisos = this.localStorageService.get('permisos');
            if (this.permisosModulosAcciones.REPOCARTSAP) {
                this.initListadoEmpresas();
                this.configuraFechas();
            }
            else {
                this.toastr.error("No est\u00E1s autorizado para ingresar a esta p\u00E1gina", GLOBALS.TOASTER.TITLE_ERROR);
                this.router.navigate(['/']);
            }
        }
    };
    /**
     * Configura las fechas del formulario
     */
    RepCartolaSAPComponent.prototype.configuraFechas = function () {
        if (this.empresasSucursalSeleccionadas.length === 0) {
            this.min = null;
            this.max = null;
            this.fecIni = null;
            this.fecFin = null;
        }
        if (this.min === null && this.max === null) {
            var fechaActual = new Date();
            this.fecIni = new Date(fechaActual.getFullYear(), 0, 1);
            this.fecFin = new Date(fechaActual.getFullYear(), 11, 31);
            this.min = new Date(2006, 0, 1);
            this.max = this.fecFin;
        }
    };
    /**
     * Configura la fecha de Fin del Calendario
     */
    RepCartolaSAPComponent.prototype.actualizarFechaFin = function (fecha) {
        if (fecha !== null && fecha !== undefined) {
            this.fecFin = new Date(fecha.getFullYear(), 11, 31);
        }
    };
    /**
     * Inicializa el listado de Empresas
     */
    RepCartolaSAPComponent.prototype.initListadoEmpresas = function () {
        var _this = this;
        this.spinner.show();
        var sucursalesAutorizadas = this.permisos.map(function (item) { return item.data.sucursalCodigo; }).join(',');
        if (sucursalesAutorizadas) {
            this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
                .subscribe(function (reporte) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var index, codigoEmpresa, rama, _i, _a, empresa;
                return tslib_1.__generator(this, function (_b) {
                    if (reporte.rows.length > 0) {
                        this.empresas = reporte.rows.map(function (empresa) { return new EmpresasSucursalModel(empresa); });
                        index = 1;
                        codigoEmpresa = void 0;
                        rama = void 0;
                        for (_i = 0, _a = this.empresas; _i < _a.length; _i++) {
                            empresa = _a[_i];
                            if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    value: empresa.codigoSucursal,
                                    items: []
                                };
                            }
                            else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    items: [{
                                            text: empresa.sucursal,
                                            value: empresa.codigoSucursal
                                        }]
                                };
                            }
                            else {
                                rama.items.push({
                                    text: empresa.sucursal,
                                    value: empresa.codigoSucursal
                                });
                            }
                            if (index === this.empresas.length) {
                                this.empresasSucursales.push(rama);
                            }
                            index++;
                        }
                        this.parsedData = this.empresasSucursales;
                    }
                    else {
                        this.toastr.warning("No hay informaci\u00F3n de Empresas / Sucursales para Reportes", "Advertencia");
                    }
                    this.spinner.hide();
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                _this.spinner.hide();
                _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            });
        }
        else {
            this.toastr.warning("No hay Empresas / Sucursales en Cliente Comercial", "Advertencia");
            this.spinner.hide();
        }
    };
    Object.defineProperty(RepCartolaSAPComponent.prototype, "checkableSettings", {
        /**
         * Crea las Propiedades del TreeView
         */
        get: function () {
            return {
                checkParents: true,
                mode: 'multiple',
                checkOnClick: false
            };
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Evento al presionar una tecla en la búsqueda
     * @param value Valor Ingresado
     */
    RepCartolaSAPComponent.prototype.onkeyup = function (value) {
        this.parsedData = this.search(this.empresasSucursales, value);
    };
    /**
     * Búsqueda
     * @param items Items
     * @param term Término (Palabra)
     */
    RepCartolaSAPComponent.prototype.search = function (items, term) {
        var _this = this;
        return items.reduce(function (acc, item) {
            if (_this.contains(item.text, term)) {
                acc.push(item);
            }
            else if (item.items && item.items.length > 0) {
                var newItems = _this.search(item.items, term);
                if (newItems.length > 0) {
                    acc.push({ text: item.text, items: newItems });
                }
            }
            return acc;
        }, []);
    };
    /**
     * Compara el Texto y el Término ingresado
     * @param text Texto
     * @param term Término (Palabra)
     */
    RepCartolaSAPComponent.prototype.contains = function (text, term) {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    };
    /**
     * Crea el array de empresas
     * @param itemLookup TreeItemLookup
     */
    RepCartolaSAPComponent.prototype.handleChecking = function (itemLookup) {
        if (this.checkedKeys.find(function (x) { return x === itemLookup.item.index; }) !== undefined) {
            if (itemLookup.children.length > 0) {
                var _loop_1 = function (hijo) {
                    this_1.empresasSucursalSeleccionadas = this_1.empresasSucursalSeleccionadas.filter(function (x) { return x !== hijo.item.dataItem.value; });
                };
                var this_1 = this;
                for (var _i = 0, _a = itemLookup.children; _i < _a.length; _i++) {
                    var hijo = _a[_i];
                    _loop_1(hijo);
                }
            }
            else {
                this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(function (x) { return x !== itemLookup.item.dataItem.value; });
            }
        }
        else {
            if (itemLookup.children.length > 0) {
                var _loop_2 = function (hijo) {
                    this_2.empresasSucursalSeleccionadas = this_2.empresasSucursalSeleccionadas.filter(function (x) { return x !== hijo.item.dataItem.value; });
                    this_2.empresasSucursalSeleccionadas.push(hijo.item.dataItem.value);
                };
                var this_2 = this;
                for (var _b = 0, _c = itemLookup.children; _b < _c.length; _b++) {
                    var hijo = _c[_b];
                    _loop_2(hijo);
                }
            }
            else {
                this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(function (x) { return x !== itemLookup.item.dataItem.value; });
                this.empresasSucursalSeleccionadas.push(itemLookup.item.dataItem.value);
            }
        }
        console.log('empresa', this.empresasSucursalSeleccionadas);
        this.filtrarEmpresas();
        this.configuraFechas();
    };
    /**
     * Filtra el Reporte
     */
    RepCartolaSAPComponent.prototype.filtrarReporte = function () {
        var _this = this;
        this.errorFiltro = {
            empresaSucursal: false,
            filtros: false,
            fecIni: false,
            fecFin: false
        };
        if (this.empresasSucursalSeleccionadas.length === 0) {
            this.errorFiltro.empresaSucursal = true;
            return;
        }
        else {
            if (this.fecIni === null) {
                this.errorFiltro.fecIni = true;
            }
            if (this.fecFin === null) {
                this.errorFiltro.fecFin = true;
            }
            if (this.fecIni === null || this.fecFin === null) {
                return;
            }
        }
        var fechaIni = [
            this.fecIni.getFullYear(),
            (this.fecIni.getMonth() + 1).toString().length === 1 ?
                '0' + (this.fecIni.getMonth() + 1).toString() : (this.fecIni.getMonth() + 1).toString(),
            this.fecIni.getDate().toString().length === 1 ? '0' + this.fecIni.getDate().toString() : this.fecIni.getDate().toString()
        ].join('-');
        var fechaFin = [
            this.fecFin.getFullYear(),
            (this.fecFin.getMonth() + 1).toString().length === 1 ?
                '0' + (this.fecFin.getMonth() + 1).toString() : (this.fecFin.getMonth() + 1).toString(),
            this.fecFin.getDate().toString().length === 1 ? '0' + this.fecFin.getDate().toString() : this.fecFin.getDate().toString()
        ].join('-');
        this.periodo = this.fecIni.getFullYear();
        this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
        var empresa = this.empresas.find(function (x) { return _this.empresasSucursalSeleccionadas.filter(function (sucursal) { return sucursal == x.codigoSucursal; }).length > 0; });
        var filtrosReporte = {
            cliComecial: this.nombreCC,
            empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
            fecIni: fechaIni,
            fecFin: fechaFin
        };
        this.spinner.show();
        forkJoin([
            this.clienteComercialService.obtenerEmpresa(empresa.codigoEmpresa),
            this.cartolaService.reporteCartola(filtrosReporte),
            this.consolidadoService.reporteConsolidadoCursosSAP(filtrosReporte),
            this.cartolaService.obtenerDestinatarioCartola(empresa.codigoEmpresa),
            this.clienteComercialService.obtenerContacto(empresa.codigoEmpresa.toString()),
        ])
            .subscribe(function (_a) {
            var info = _a[0], reporte = _a[1], cursos = _a[2], destinatario = _a[3], contacto = _a[4];
            _this.spinner.hide();
            if (info.length > 0) {
                _this.empresaSeleccionado = new EmpresaModel(info[0]);
            }
            if (contacto.length > 0) {
                _this.contacto = contacto.find(function (x) { return x.tipo.toString() === 'Ejecutivo Consultor'; });
            }
            if (destinatario.length > 0) {
                _this.destinatario = destinatario[0];
            }
            _this.detalleCursos.normal = {
                collapse: true,
                cursos: cursos.normal,
                invCtaNormal: cursos.normal.reduce(function (acc, curr) {
                    acc += curr.costocursootic + curr.costoviaticootic + curr.costotrasladootic;
                    return acc;
                }, 0),
                totales: _this.calcularTotal(cursos.normal)
            };
            _this.detalleCursos.reparto = {
                collapse: true,
                cursos: cursos.reparto,
                invCtaRep: cursos.reparto.reduce(function (acc, curr) {
                    acc += curr.costocursootic + curr.costoviaticootic + curr.costotrasladootic;
                    return acc;
                }, 0),
                totales: _this.calcularTotal(cursos.reparto)
            };
            _this.detalleCursos.excedentes = {
                collapse: true,
                cursos: cursos.excedentes,
                invCtaExc: cursos.excedentes.reduce(function (acc, curr) {
                    acc += curr.costocursootic + curr.costoviaticootic + curr.costotrasladootic;
                    return acc;
                }, 0),
                totales: _this.calcularTotal(cursos.excedentes)
            };
            _this.detalleCursos.excedentesReparto = {
                collapse: true,
                cursos: cursos.excedentesReparto,
                invCtaExcRep: cursos.excedentesReparto.reduce(function (acc, curr) {
                    acc += curr.costocursootic + curr.costoviaticootic + curr.costotrasladootic;
                    return acc;
                }, 0),
                totales: _this.calcularTotal(cursos.excedentesReparto)
            };
            // Aportes
            _this.cartolaCompleta.aportes.aportes = [];
            if (reporte.aportes.length > 0) {
                _this.cartolaCompleta.aportes.aportes = reporte.aportes;
            }
            // Aporte Comprometido
            _this.cartolaCompleta.aporteComprometido = 0;
            if (reporte.aporteComprometido.length > 0) {
                for (var _i = 0, _b = reporte.aporteComprometido; _i < _b.length; _i++) {
                    var obj = _b[_i];
                    _this.cartolaCompleta.aporteComprometido = _this.cartolaCompleta.aporteComprometido + obj.valor;
                }
            }
            // 1% año anterior
            _this.cartolaCompleta.unoAnterior = 0;
            if (reporte.datosCierre.rows.length > 0) {
                _this.cartolaCompleta.unoAnterior = parseInt(reporte.datosCierre.rows[0].klimk, 10);
            }
            // Comision
            _this.cartolaCompleta.comision.comisionCollapse = true;
            _this.cartolaCompleta.comision.comisiones = [];
            _this.cartolaCompleta.comision.comisionPromedio = 0;
            var comisionEmpresa = reporte.comision.find(function (emp) { return emp.codigo == _this.empresaSeleccionado.Codigo; });
            if (comisionEmpresa) {
                _this.cartolaCompleta.comision.comisionPromedio = parseFloat(comisionEmpresa.tasa);
            }
            if (reporte.datosCierre.rows.length > 0) {
                for (var _c = 0, _d = reporte.datosCierre.rows; _c < _d.length; _c++) {
                    var obj = _d[_c];
                    _this.cartolaCompleta.comision.comisiones.push(obj);
                }
            }
            // Aporte Imputado
            _this.cartolaCompleta.aporteImputado.aporteImputadoCollapse = true;
            _this.cartolaCompleta.aporteImputado.aportesImputados = [];
            _this.cartolaCompleta.aporteImputado.aporteImputadoTotal = 0;
            if (reporte.aportes.length > 0) {
                for (var _e = 0, _f = reporte.aporteImputado; _e < _f.length; _e++) {
                    var obj = _f[_e];
                    _this.cartolaCompleta.aporteImputado.aporteImputadoTotal =
                        _this.cartolaCompleta.aporteImputado.aporteImputadoTotal + parseInt(obj.aporteimpdisp, 10);
                    _this.cartolaCompleta.aporteImputado.aportesImputados.push(obj);
                }
            }
            // Total comisión por administración
            if (reporte.aportes.length > 0) {
                var comision = reporte.comision.find(function (x) { return x.codigo === _this.empresaSeleccionado.Codigo; }).tasa;
                _this.cartolaCompleta.ctaNormalyRepartoCom = Math.round((_this.cartolaCompleta.aporteImputado.aporteImputadoTotal * (parseFloat(comision) / 100)));
            }
            // Total Inversión en Capacitación (Normal y Reparto)
            _this.cartolaCompleta.invCtaNormal.invCtaNormalyRepCollapse = true;
            _this.cartolaCompleta.invCtaNormal.subClienteNormalyRep = [];
            _this.cartolaCompleta.invCtaNormal.invCtaNormalTotal = 0;
            _this.cartolaCompleta.invCtaNormalReparto.invCtaNormalyRepCollapse = true;
            _this.cartolaCompleta.invCtaNormalReparto.subClienteNormalyRep = [];
            _this.cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal = 0;
            if (_this.detalleCursos.normal.cursos.concat(_this.detalleCursos.reparto.cursos).length > 0) {
                for (var _g = 0, _h = _this.detalleCursos.normal.cursos; _g < _h.length; _g++) {
                    var obj = _h[_g];
                    _this.cartolaCompleta.invCtaNormal.invCtaNormalTotal =
                        _this.cartolaCompleta.invCtaNormal.invCtaNormalTotal +
                            parseInt(obj.costocursootic, 10);
                }
                for (var _j = 0, _k = _this.detalleCursos.reparto.cursos; _j < _k.length; _j++) {
                    var obj = _k[_j];
                    _this.cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal =
                        _this.cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal +
                            parseInt(obj.costocursootic, 10);
                }
            }
            // Tope Inversión Viáticos y Traslados (Normal y Reparto)
            _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalCollapse = true;
            _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales = [];
            _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal = 0;
            var datosCierreAnterior = reporte.datosCierreAnterior.rows.filter(function (obj) { return obj.cta_exc == 1000; });
            if (datosCierreAnterior.length > 0) {
                for (var _l = 0, datosCierreAnterior_1 = datosCierreAnterior; _l < datosCierreAnterior_1.length; _l++) {
                    var obj = datosCierreAnterior_1[_l];
                    //Tope VyT es el 10% del 1% de los aportes del año anterior
                    var topeNormal = Math.round(parseInt(obj.klimk, 10) * 0.1);
                    _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales.push({
                        nombreempresa: obj.nombreempresa,
                        nombresucursal: obj.nombresucursal,
                        tope: topeNormal
                    });
                    _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal =
                        _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal + topeNormal;
                }
            }
            // Total Inversión Viáticos y Traslados (Normal y Reparto)
            _this.cartolaCompleta.invVyTNormal.invVyTNormalCollapse = true;
            _this.cartolaCompleta.invVyTNormal.invVyTNormales = [];
            _this.cartolaCompleta.invVyTNormal.invVyTNormalTotal = 0;
            if (reporte.subClienteInscripcionesNormalYReparto.length > 0) {
                for (var _m = 0, _o = reporte.subClienteInscripcionesNormalYReparto; _m < _o.length; _m++) {
                    var obj = _o[_m];
                    _this.cartolaCompleta.invVyTNormal.invVyTNormales.push({
                        sucursal: obj.sucursal,
                        subCliente: obj.subCliente,
                        vyt: obj.inscripciones.vyt
                    });
                    _this.cartolaCompleta.invVyTNormal.invVyTNormalTotal =
                        _this.cartolaCompleta.invVyTNormal.invVyTNormalTotal + parseInt(obj.inscripciones.vyt, 10);
                }
            }
            // Total Comisión sobre Intermediación (Normal y Reparto)
            _this.cartolaCompleta.comisionInterm.comisionIntermCollapse = true;
            _this.cartolaCompleta.comisionInterm.comisionesIntermediadas = [];
            _this.cartolaCompleta.comisionInterm.comisionIntermTotal = 0;
            if (reporte.subClienteInscripcionesNormalYReparto.length > 0) {
                for (var _p = 0, _q = reporte.subClienteInscripcionesNormalYReparto; _p < _q.length; _p++) {
                    var obj = _q[_p];
                    var comision = parseInt(obj.inscripciones.comision, 10);
                    _this.cartolaCompleta.comisionInterm.comisionesIntermediadas.push({
                        sucursal: obj.sucursal,
                        subCliente: obj.subCliente,
                        comision: comision
                    });
                    _this.cartolaCompleta.comisionInterm.comisionIntermTotal =
                        _this.cartolaCompleta.comisionInterm.comisionIntermTotal + comision;
                }
            }
            // Total Comisión sobre Saldos no Intevertidos (Normal y Reparto)
            _this.cartolaCompleta.comisionNoInterm.comisionNoIntermCollapse = true;
            _this.cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas = [];
            _this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal = 0;
            if (reporte.subClienteInscripcionesNormalYReparto.length > 0) {
                var _loop_3 = function (obj) {
                    //const tasa  = obj.tasa_por ? parseInt(obj.tasa_por, 10) : 0;
                    var comision = 0;
                    if (_this.cartolaCompleta.comision.comisiones.length > 0) {
                        var reporte_1 = _this.cartolaCompleta.comision.comisiones.find(function (x) { return x.cod_sucursal === obj.sucursal; });
                        if (reporte_1)
                            comision = reporte_1.tasa_por;
                    }
                    var aporte = _this.cartolaCompleta.aporteImputado.aportesImputados.find(function (x) { return x.sucursal === obj.sucursal; });
                    var invVyt = _this.cartolaCompleta.invVyTNormal.invVyTNormales.find(function (x) { return x.sucursal === obj.sucursal; });
                    var comisionInv = _this.cartolaCompleta.comisionInterm.comisionesIntermediadas.find(function (x) { return x.sucursal === obj.sucursal; });
                    var comisionNoInterm = Math.round(((aporte !== undefined ? parseInt(aporte.aporteimpdisp, 10) : 0) -
                        (parseInt(obj.inscripciones.cuentaNormal, 10) +
                            parseInt(obj.inscripciones.cuentaReparto, 10) +
                            parseInt(obj.inscripciones.eyccl, 10)) -
                        (invVyt !== undefined ? parseInt(invVyt.vyt, 10) : 0) -
                        (comisionInv !== undefined ? parseInt(comisionInv.comision, 10) : 0)) *
                        (comision !== undefined ? comision / 100 : 0));
                    if (comisionNoInterm > 0) {
                        _this.cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas.push({
                            subCliente: obj.subCliente,
                            comision: comisionNoInterm
                        });
                        _this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal =
                            _this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal +
                                (comisionNoInterm > 0 ? comisionNoInterm : 0);
                    }
                };
                for (var _r = 0, _s = reporte.subClienteInscripcionesNormalYReparto; _r < _s.length; _r++) {
                    var obj = _s[_r];
                    _loop_3(obj);
                }
            }
            // Saldo disponible intermediación
            _this.cartolaCompleta.ctaNormalyRepartoDisp =
                _this.cartolaCompleta.aporteImputado.aporteImputadoTotal -
                    _this.cartolaCompleta.ctaNormalyRepartoCom -
                    _this.cartolaCompleta.invVyTNormal.invVyTNormalTotal;
            _this.cartolaCompleta.ctaNormalyRepartoCollapse = true;
            // Total intermediado
            _this.cartolaCompleta.ctaNormalYRepartoInter =
                _this.cartolaCompleta.invCtaNormal.invCtaNormalTotal +
                    _this.cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal;
            // Saldo Disponible
            _this.cartolaCompleta.ctaNormalyReparto =
                _this.cartolaCompleta.ctaNormalyRepartoDisp -
                    _this.cartolaCompleta.ctaNormalYRepartoInter;
            // Monto minimo para aporte siguiente año
            _this.cartolaCompleta.ctaNormalyRepartoMinimo =
                Math.round(_this.cartolaCompleta.ctaNormalyReparto / (1 - (_this.cartolaCompleta.comision.comisionPromedio / 100)));
            // Saldo Excedentes Líquido (Excedentes)
            _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqCollapse = true;
            _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs = [];
            _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal = 0;
            if (reporte.datosCierre.rows.length > 0) {
                for (var _t = 0, _u = reporte.datosCierre.rows; _t < _u.length; _t++) {
                    var obj = _u[_t];
                    /*const comisionAnterior = reporte.comisionAnioAnterior.find(x => x.sucursal === obj.sucursal);
                    const inversionAnterior = reporte.inscripcionesAnioAnterior.find(x => x.sucursal === obj.sucursal);
                    const saldoExcedentes =
                      parseInt(obj.aporteimpdisp, 10) -
                      (inversionAnterior !== undefined ? parseInt(inversionAnterior.costootic, 10) : 0) -
                      (Math.round((inversionAnterior !== undefined ? parseInt(inversionAnterior.costootic, 10) : 0) *
                      (comisionAnterior !== undefined ? parseInt(comisionAnterior.valor, 10) / 100 : 0)) +
                      (inversionAnterior !== undefined ? parseInt(inversionAnterior.costovyt, 10) : 0));*/
                    var saldoExcedentes = parseInt(obj.exc_liqu, 10);
                    _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs.push({
                        nombreempresa: obj.nombreempresa,
                        nombresucursal: obj.nombresucursal,
                        saldo: saldoExcedentes
                    });
                    _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal =
                        _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal + saldoExcedentes;
                }
            }
            // Total Inversión en Capacitación (Excedentes)
            _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesCollapse = true;
            _this.cartolaCompleta.invCtaExcedentes.subClienteExcedentes = [];
            _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal = 0;
            _this.cartolaCompleta.invCtaExcedentesReparto.invCtaExcedentesTotal = 0;
            if (_this.detalleCursos.excedentes.cursos.concat(_this.detalleCursos.excedentesReparto.cursos).length > 0) {
                for (var _v = 0, _w = _this.detalleCursos.excedentes.cursos; _v < _w.length; _v++) {
                    var obj = _w[_v];
                    _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal =
                        _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal +
                            parseInt(obj.costocursootic, 10);
                }
                for (var _x = 0, _y = _this.detalleCursos.excedentesReparto.cursos; _x < _y.length; _x++) {
                    var obj = _y[_x];
                    _this.cartolaCompleta.invCtaExcedentesReparto.invCtaExcedentesTotal =
                        _this.cartolaCompleta.invCtaExcedentesReparto.invCtaExcedentesTotal +
                            parseInt(obj.costocursootic, 10);
                }
            }
            // Tope Inversión Viáticos y Traslados (Excedentes)
            _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesCollapse = true;
            _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes = [];
            _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal = 0;
            if (reporte.aporteImputadoAnioAnterior.length > 0) {
                var _loop_4 = function (obj) {
                    /*const inversionVyT = reporte.inscripcionesAnioAnterior.find(x => x.sucursal === obj.sucursal);
                    const topeExcedentes =
                      Math.round(parseInt(obj.aporteimpdisp, 10) * 0.1) - (inversionVyT !== undefined ? inversionVyT.costovyt : 0);*/
                    var datosCierreAnterior_2 = reporte.datosCierreAnterior.rows.find(function (x) { return x.cod_sucursal === obj.sucursal; });
                    //Inicializar si es que no hay datos
                    var vt_costo = '0';
                    var via_tras = '0';
                    if (datosCierreAnterior_2) {
                        vt_costo = datosCierreAnterior_2.vt_costo;
                        via_tras = datosCierreAnterior_2.via_tras;
                        _this.cartolaCompleta.aportes.aportesImputadosAnioAnteriorTotal += parseInt(obj.aporteImpDisp, 10);
                        _this.cartolaCompleta.aportes.aportesImputadosAnioAnterior.push(datosCierreAnterior_2);
                    }
                    var topeExcedentes = Math.round((parseInt(obj.aporteimpdisp, 10) * 0.1)
                        - parseInt(vt_costo, 10)
                        - parseInt(via_tras, 10));
                    _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes.push({
                        nombreempresa: obj.nombreempresa,
                        nombresucursal: obj.nombresucursal,
                        tope: topeExcedentes
                    });
                    _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal =
                        _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal + topeExcedentes;
                };
                for (var _z = 0, _0 = reporte.aporteImputadoAnioAnterior; _z < _0.length; _z++) {
                    var obj = _0[_z];
                    _loop_4(obj);
                }
            }
            // Total Inversión Viáticos y Traslados (Excedentes)
            _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesCollapse = true;
            _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentes = [];
            _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal = 0;
            if (reporte.subClienteInscripcionesExcedentes.length > 0) {
                for (var _1 = 0, _2 = reporte.subClienteInscripcionesExcedentes; _1 < _2.length; _1++) {
                    var obj = _2[_1];
                    _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentes.push({
                        sucursal: obj.sucursal,
                        subCliente: obj.subCliente,
                        vyt: obj.inscripciones.vyt
                    });
                    _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal =
                        _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal + parseInt(obj.inscripciones.vyt, 10);
                }
            }
            //Saldo actual Viáticos y Traslados (Excedentes)
            _this.cartolaCompleta.saldoVyTExcedentesTotal =
                _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal - _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal;
            // Becas Mandato
            _this.cartolaCompleta.becasMandato.becasMandatoCollapse = true;
            _this.cartolaCompleta.becasMandato.becasMandatos = [];
            _this.cartolaCompleta.becasMandato.becasMandatoTotal = 0;
            if (reporte.becasMandato.length > 0) {
                for (var _3 = 0, _4 = reporte.becasMandato; _3 < _4.length; _3++) {
                    var obj = _4[_3];
                    _this.cartolaCompleta.becasMandato.becasMandatos.push(obj);
                    _this.cartolaCompleta.becasMandato.becasMandatoTotal = _this.cartolaCompleta.becasMandato.becasMandatoTotal + obj.valor;
                }
            }
            _this.cartolaCompleta.ctaExcedentesCollapse = true;
            _this.cartolaCompleta.ctaExcedentesInter =
                _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal + //(**)
                    _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal +
                    _this.cartolaCompleta.becasMandato.becasMandatoTotal;
            _this.cartolaCompleta.ctaExcedentes =
                _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal - //(**)
                    _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal - //(**)
                    _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal -
                    _this.cartolaCompleta.becasMandato.becasMandatoTotal;
            //Validacion Tope V&T Excedentes no puede ser mayor al saldo Excedentes
            _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal =
                _this.cartolaCompleta.ctaExcedentes > _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal ? _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal : _this.cartolaCompleta.ctaExcedentes;
            _this.cartolaCompleta.saldoVyTExcedentesTotal =
                _this.cartolaCompleta.ctaExcedentes > _this.cartolaCompleta.saldoVyTExcedentesTotal ? _this.cartolaCompleta.saldoVyTExcedentesTotal : _this.cartolaCompleta.ctaExcedentes;
            console.log('cartola', _this.cartolaCompleta);
            console.log('detalle cursos', _this.detalleCursos);
            if (reporte.clienteCerrado.toString() === 'No') {
                _this.toastr.warning('Cliente no está cerrado, los valores pueden variar', GLOBALS.TOASTER.TITLE_INFO);
            }
        }, function (error) {
            console.log(error);
            _this.spinner.hide();
            _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        });
    };
    /**
     * Filtra Reporte al presionar tecla Enter
     * @param event Evento
     */
    RepCartolaSAPComponent.prototype.filtrarReporteKeydown = function (event) {
        if (event.key === 'Enter') {
            this.filtrarReporte();
        }
    };
    RepCartolaSAPComponent.prototype.openModal = function (refModal) {
        var _this = this;
        var modalref = this.modalService.open(refModal);
        modalref.result.then(function (result) {
            if (result) {
                _this.iniciarEnvioMasivo();
            }
        });
    };
    RepCartolaSAPComponent.prototype.filtrarEmpresas = function () {
        var _this = this;
        var empresas = this.empresas.filter(function (x) { return _this.empresasSucursalSeleccionadas.filter(function (sucursal) { return sucursal == x.codigoSucursal; }).length > 0; });
        this.empresasSeleccionadas = Array.from(new Set(empresas.map(function (emp) { return emp.razonSocial; })));
    };
    RepCartolaSAPComponent.prototype.calcularTotal = function (cursos) {
        var totales = {
            cursootic: 0,
            viaticootic: 0,
            trasladootic: 0,
            totalotic: 0,
            cursoempresa: 0,
            viaticoempresa: 0,
            trasladoempresa: 0,
            totalempresa: 0,
            total: 0
        };
        for (var _i = 0, cursos_1 = cursos; _i < cursos_1.length; _i++) {
            var curso = cursos_1[_i];
            totales.cursootic += curso.costocursootic;
            totales.viaticootic += curso.costoviaticootic;
            totales.trasladootic += curso.costotrasladootic;
            totales.totalotic += (curso.costocursootic + curso.costoviaticootic + curso.costotrasladootic);
            totales.cursoempresa += curso.costocursoempresa;
            totales.viaticootic += curso.costoviaticoempresa;
            totales.trasladoempresa += curso.costotrasladoempresa;
            totales.totalempresa += (curso.costocursoempresa + curso.costoviaticoempresa + curso.costotrasladoempresa);
            totales.total += (curso.costootic + curso.costoempresa);
        }
        return totales;
    };
    RepCartolaSAPComponent.prototype.iniciarEnvioMasivo = function () {
        var _this = this;
        var filtros = {
            cliComercial: this.empresasSucursalSeleccionadas.length > 0 ? this.userData.clienteComercialSeleccionado : 'TODOS',
            empresas: this.empresasSucursalSeleccionadas.join(','),
            fecIni: moment(this.fecIni).format('YYYY-MM-DD'),
            fecFin: moment(this.fecFin).format('YYYY-MM-DD'),
            periodo: moment(this.fecFin).format('YYYY'),
            usuario: this.userData.rut,
            email: this.userData.email,
            tipo: 'pdf'
        };
        this.spinner.show();
        this.cartolaService.iniciarCartolaMasiva(filtros).subscribe(function (result) {
            _this.toastr.success(result.data, GLOBALS.TOASTER.TITLE_OK);
            _this.spinner.hide();
            _this.router.navigate(['rep-cartola-actualizada/ejecuciones']);
        });
    };
    RepCartolaSAPComponent.prototype.iniciarEnvioMasivoExcel = function () {
        var _this = this;
        var filtros = {
            cliComercial: this.empresasSucursalSeleccionadas.length > 0 ? this.userData.clienteComercialSeleccionado : 'TODOS',
            empresas: this.empresasSucursalSeleccionadas.join(','),
            fecIni: moment(this.fecIni).format('YYYY-MM-DD'),
            fecFin: moment(this.fecFin).format('YYYY-MM-DD'),
            periodo: moment(this.fecFin).format('YYYY'),
            usuario: this.userData.rut,
            email: this.userData.email,
            tipo: 'excel'
        };
        this.spinner.show();
        this.cartolaService.iniciarCartolaMasiva(filtros).subscribe(function (result) {
            _this.toastr.success(result.data, GLOBALS.TOASTER.TITLE_OK);
            _this.spinner.hide();
        });
    };
    RepCartolaSAPComponent.prototype.mySelectionKey = function (context) {
        return context.dataItem;
    };
    RepCartolaSAPComponent.prototype.openModalPDF = function (refModal) {
        var _this = this;
        var modalref = this.modalService.open(refModal);
        modalref.result.then(function (result) {
            _this.reportepdf.saveAs('Cartola Actualizada.pdf');
            _this.filtrosSeccionesCartola = [];
        });
    };
    RepCartolaSAPComponent.prototype.isSelected = function (codigo) {
        return !!this.filtrosSeccionesCartola.find(function (seccion) { return seccion.codigo == codigo; });
    };
    return RepCartolaSAPComponent;
}());
export { RepCartolaSAPComponent };
