import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { CheckableSettings, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { Observable, Subject, of } from 'rxjs';
import { Workbook } from '@progress/kendo-ooxml';
import { saveAs } from '@progress/kendo-file-saver';
import * as moment from 'moment';
const lzwCompress = require('lzwcompress');
import {ClienteAuditoria} from 'otic-cliente-auditoria';
import {UserDataLocalStorageModel} from 'src/app/models/userDataLocalStorage';
// Services
import { UserLoginService } from '../../services/user-login.service';
import { ClienteComercialService } from '../../services/cliente-comercial.service';

// Models
import { EmpresasSucursalModel } from 'src/app/models/empresasSucursal.model';

// Shared
import { environment } from '../../../environments/environment';
import { GlobalService, GLOBALS } from '../../shared/globals.shared';
import { ConsolidadoService } from '../../services/consolidado.service';
import { PermisosCliComLocalStorageModel } from 'src/app/models/permisosCliComLocalStorage';
import { NgbModalConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GestionPlantillasService } from 'src/app/services/gestion-plantillas.service';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-rep-consolidado-cursos-part',
  templateUrl: './rep-consolidado-cursos-part.component.html',
  styleUrls: ['./rep-consolidado-cursos-part.component.scss'],
  providers: [GlobalService]
})
export class RepConsolidadoCursosPartComponent implements OnInit {
  [x: string]: any;
  modalRef: NgbModalRef | undefined;
  urlGestion: string = environment.url_gestor;

  min: Date = null;
  max: Date = null;
  fechaActual: string = [
    new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
    (new Date().getMonth() + 1).toString().length === 1 ?
      '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
    new Date().getFullYear()].join('/');

  sinPVs = false;
  userData: UserDataLocalStorageModel = {};
  empresasSucursales: any[] = [];
  parsedData: any[] = [];
  checkedKeys: any[] = [];
  searchTerm = '';
  listadoFinal: any;
  nombreCC: string;
  empresasSucursalSeleccionadas: string[] = [];
  prevEmpresasSucursalSeleccionadas: any [] = [];
  fecIni: Date = null;
  fecFin: Date = null;

  errorFiltro = {
    empresaSucursal: false,
    fecIni: false,
    fecFin: false,
  };

  listFecIni: Array<string> = [];
  listFecFin: Array<string> = [];

  consolidado: any[] = [];
  camposAdicACCabecera: any[] = [];
  camposAdicAC: any[] = [];
  camposAdicPartCabecera: any[] = [];
  camposAdicPart: any[] = [];
  permisosModulosAcciones: any = {};
  permisos: Array<PermisosCliComLocalStorageModel>;

  checkAnulados = false;
  checkCASeparados = false;
  checkCASoloCC = false;

  plantillaSeleccionada: string = 'nueva';
  nombrePlantilla: string = '';
  nombrePlantillaVacio: boolean = true;
  opcionesAgregadasVacio: boolean = true;

  @ViewChild('selectPlantillas') selectPlantillas: ElementRef;
  plantillas: { [key: number]: any[] } = {};

  cabecerasDefault: Array<any> = [];
  cabecerasDisponibles: Array<any> = [];
  cabecerasSeleccionadas: Array<any> = [];

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private clienteComercialService: ClienteComercialService,
    private userLoginService: UserLoginService,
    private consolidadoService: ConsolidadoService,
    private gestionPlantillasService: GestionPlantillasService) {
    config.backdrop = 'static';
    config.keyboard = false;
    console.log('RepAportesImputadosComponent: constructor');
  }

  /**
   * OnInit Event
   */
  ngOnInit() {
    console.log('RepAportesImputadosComponent: OnInit');

    this.userLoginService.isAuthenticated(this);
    this.userData = this.localStorageService.get('userData');
    this.spinner.show();
    this.cargarPlantillasUsuario();
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });

      this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
      this.permisos = this.localStorageService.get('permisos');

      if (this.permisosModulosAcciones.REPCURPART) {
        this.initListadoEmpresas();
      } else {
        this.toastr.error(`No estás autorizado para ingresar a esta página`, GLOBALS.TOASTER.TITLE_ERROR);
        this.router.navigate(['/']);
      }

    }
  }

  /**
   * Inicializa el listado de Empresas
   */
  initListadoEmpresas() {
    this.spinner.show();

    const sucursalesAutorizadas = this.permisos.map((item) => item.data.sucursalCodigo).join(',');

    if (sucursalesAutorizadas) {
      this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
        .subscribe(async (result) => {

            if (result.rows.length > 0) {
              const empresas = result.rows.map((empresa) => new EmpresasSucursalModel(empresa));

              let index = 1;
              let codigoEmpresa: number;
              let rama: any;
              for (const empresa of empresas) {
                if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    value: empresa.codigoEmpresa,
                    items: []
                  };
                } else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    items: [{
                      text: empresa.sucursal,
                      value: empresa.codigoEmpresa
                    }]
                  };
                } else {
                  rama.items.push({
                    text: empresa.sucursal,
                    value: empresa.codigoEmpresa
                  });
                }

                if (index === empresas.length) {
                  this.empresasSucursales.push(rama);
                }

                index++;
              }

              this.parsedData = this.empresasSucursales;
              // console.log('this.parsedData', this.parsedData);
              this.listadoFinal = [{
                text: 'Seleccionar Todos',
                value: 'Todos',
                items: this.parsedData.map(x => {
                  return {
                    text: x.text,
                    value: x.value,
                    items: x.items
                  };
                })
              }];
              // console.log('prueba', this.listadoFinal);

            } else {
              this.toastr.warning(`No hay información de Empresas / Sucursales para Reportes`, `Advertencia`);
            }

            // this.spinner.hide();
          }, error => {
            console.log(error);
            // this.spinner.hide();
            this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
          }
        );
    } else {
      this.toastr.warning(`No hay Empresas / Sucursales en Cliente Comercial`, `Advertencia`);
      // this.spinner.hide();
    }
  }

  /**
   * Crea las Propiedades del TreeView
   */
  public get checkableSettings(): CheckableSettings {
    return {
      checkChildren: true,
      checkParents: true,
      mode: 'multiple',
      checkOnClick: false
    };
  }

  /**
   * Trae los hijos del árbol
   */
  public children = (dataItem: any): Observable<any[]> => of(dataItem.items);

  /**
   * Valida si tiene hijos el árbol
   */
  public hasChildren = (dataItem: any): boolean => !!dataItem.items;

  /**
   * Evento al presionar una tecla en la búsqueda
   * @param value Valor Ingresado
   */
  public onkeyup(value: string): void {
    this.parsedData = this.search(this.empresasSucursales, value);
    if(this.parsedData.length > 0){
      this.listadoFinal = [{
        text: 'Seleccionar Todos',
        value: 'Todos',
        items: this.parsedData.map(x => {
          return {
            text: x.text,
            value: x.value,
            items: x.items
          };
        })
      }];
      // console.log('prueba', this.listadoFinal);
    } else {
      this.listadoFinal = [];
    }

  }

  /**
   * Búsqueda
   * @param items Items
   * @param term Término (Palabra)
   */
  public search(items: any[], term: string): any[] {
    return items.reduce((acc, item) => {
      if (this.contains(item.text, term)) {
        acc.push(item);
      } else if (item.items && item.items.length > 0) {
        const newItems = this.search(item.items, term);

        if (newItems.length > 0) {
          acc.push({ text: item.text, items: newItems });
        }
      }

      return acc;
    }, []);
  }

  /**
   * Compara el Texto y el Término ingresado
   * @param text Texto
   * @param term Término (Palabra)
   */
  public contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  }

  /**
   * Crea el array de empresas
   * @param itemLookup TreeItemLookup
   */
  public handleChecking(itemLookup: TreeItemLookup): void {
    this.empresasSucursalSeleccionadas = [];
    if (this.checkedKeys.find(x => x === itemLookup.item.index) !== undefined) {
      if (itemLookup.children.length > 0) {
        for (const hijo of itemLookup.children) {
          const aux = hijo.item.dataItem.items;
          if(aux){
            if(hijo.item.dataItem.items.length > 0){
              let x:any;
              for (x of hijo.item.dataItem.items) {
                let valor = {
                  value:  x.value,
                  text:  x.text
                }
                this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(x => x.value !== valor.value  || x.text !== valor.text);
              }
            } else {
              let valor = {
                value:  hijo.item.dataItem.value,
                text:  hijo.item.dataItem.text
              }
              this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(x => x.value !== valor.value  || x.text !== valor.text);
            }
          } else {
            let valor = {
              value:  hijo.item.dataItem.value,
              text:  hijo.item.dataItem.text
            }
            this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(x => x.value !== valor.value || x.text !== valor.text);
          }
        }
      } else {
        let valor = {
          value:  itemLookup.item.dataItem.value,
          text:  itemLookup.item.dataItem.text
        }
        this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(x => x.value !== valor.value || x.text !== valor.text);
      }
    } else {
      if (itemLookup.children.length > 0) {
        for (const hijo of itemLookup.children) {
          const aux = hijo.item.dataItem.items;
          if(aux){
            if(hijo.item.dataItem.items.length > 0){
              let x:any;
              for (x of hijo.item.dataItem.items) {
                let valor = {
                  value:  x.value,
                  text:  x.text
                }
                this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(x => x.value !== valor.value  || x.text !== valor.text);
                this.prevEmpresasSucursalSeleccionadas.push(valor);
              }
            } else {
              let valor = {
                value:  hijo.item.dataItem.value,
                text:  hijo.item.dataItem.text
              }
              this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(x => x.value !== valor.value  || x.text !== valor.text);
              this.prevEmpresasSucursalSeleccionadas.push(valor);
            }
          } else {
            let valor = {
              value:  hijo.item.dataItem.value,
              text:  hijo.item.dataItem.text
            }
            this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(x => x.value !== valor.value  || x.text !== valor.text);
            this.prevEmpresasSucursalSeleccionadas.push(valor);
          }
        }
      } else {
        let valor = {
          value:  itemLookup.item.dataItem.value,
          text:  itemLookup.item.dataItem.text
        }
        this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(x => x.value !== valor.value || x.text !== valor.text);
        this.prevEmpresasSucursalSeleccionadas.push(valor);
      }
    }
    console.log('this.prevEmpresasSucursalSeleccionadas: ',this.prevEmpresasSucursalSeleccionadas);

    for (let a of this.prevEmpresasSucursalSeleccionadas) {
      this.empresasSucursalSeleccionadas.push(a.value);
    }



    this.inicializaFechas();
  }

  /**
   * Busca las fechas de Inicio y Fin de las PV al cambiar la selección
   */
  inicializaFechas() {
    this.fecIni = null;
    this.fecFin = null;
    this.sinPVs = false;

    this.errorFiltro = {
      empresaSucursal: false,
      fecIni: false,
      fecFin: false
    };

    if (this.empresasSucursalSeleccionadas.length > 0) {
      const rutsEmpresa = this.empresasSucursalSeleccionadas.join(',');
      // const rutsEmpresa = `'88566900-K', '96731890-6'`;

      this.spinner.show();
      this.consolidadoService.obtenerFechasPVs(rutsEmpresa).subscribe(
        (result: any) => {
          this.spinner.hide();

          if (result.minanio === null) {
            this.sinPVs = true;
          } else {

            this.min = new Date(result.minanio.substring(0, 19));
            this.max = new Date(result.maxanio.substring(0, 19));

            this.fecIni = this.min;
            this.fecFin = this.max;

            // this.min = result.minanio.toString();
            // this.max = result.maxanio.toString();

            // this.fecIni = this.min;
            // this.fecFin = this.max;

            // this.listFecIni = [];
            // this.listFecFin = [];
            // for (let z = this.min; z <= this.max; z++) {
            //   this.listFecIni.push(z.toString());
            //   this.listFecFin.push(z.toString());
            // }
          }
        },
        error => {
          console.log(error);
          this.spinner.hide();
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      );
    } else {
      if (this.empresasSucursales.length > 0) {
        this.errorFiltro.empresaSucursal = true;
      }
    }
  }

  /**
   * Configura las fechas de inicio y fin seleccionables cuando cambia el valor
   */
  configuraFechas() {
    /*
    this.spinner.show();

    this.listFecIni = [];
    this.listFecFin = [];

    for (let z = this.minIni; z <= (this.fecFinDesde !== undefined ? this.fecFinDesde : this.maxIni); z++) {
      this.listFecIni.push(z.toString());
    }

    for (let z = (this.fecIniDesde !== undefined ? this.fecIniDesde : this.minIni); z <= this.maxIni; z++) {
      this.listFecFin.push(z.toString());
    }

    this.spinner.hide();
    */
  }

  /**
   * Filtra el Reporte
   */
  filtrarReporte() {
    this.errorFiltro = {
      empresaSucursal: false,
      fecIni: false,
      fecFin: false
    };

    if (this.empresasSucursalSeleccionadas.length === 0) {
      this.errorFiltro.empresaSucursal = true;
      return;
    } else {
      if (this.fecIni === undefined || this.fecIni === null) {
        this.errorFiltro.fecIni = true;
      }
      if (this.fecFin === undefined || this.fecFin === null) {
        this.errorFiltro.fecFin = true;
      }
      if (this.fecIni === undefined || this.fecIni === null || this.fecFin === undefined || this.fecFin === null) {
        return;
      }
      // if ((this.fecFinDesde - this.fecIniDesde) > 1) {
      //   this.toastr.warning('De momento no es posible obtener reportes mayores a 2 años');
      //   return;
      // }
    }

    this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
    const filtrosReporte = {
      cliComercial:this.nombreCC,
      empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
      // empresaSucursal: `'88566900-K', '93658000-9'`,
      fecIni: moment(this.fecIni).format('L'),
      fecFin: moment(this.fecFin).format('L'),
      usuario: this.userData.rut,
      email: this.userData.email,
      anulados: this.checkAnulados,
      caseparados: this.checkCASeparados,
      cacc: this.checkCASoloCC,
      replaId: this.plantillaSeleccionada === 'nueva' ? null : this.plantillaSeleccionada
    };
    console.log('filtrosReporte:', filtrosReporte);


    this.spinner.show();
    this.consolidadoService.reporteConsolidadoStart(filtrosReporte).subscribe(
      (result: any) => {
        console.log("Proceso iniciado");
        this.accion('Reporte Consolidado de Cursos y Participantes','', 'Genera Reporte para '+ this.nombreCC);
        this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
        this.spinner.hide();
      },
      error => {
        console.log("No es posible descargar el archivo",error);
        this.spinner.hide();
        this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
      }
    );
  }

  /**
   * Metodo para exportar a Excel
   * @param grid Grid
   */
  public exportToExcel(): void {
    this.spinner.show();

    const workbook = new Workbook({
      sheets: [{
        columns: [],
        rows: [{ cells: [{}] }]
      }]
    });

    workbook.options.sheets[0].name = 'Consolidado';

    const columnas = Object.keys(this.consolidado[0]);

    workbook.options.sheets[0].rows[0].cells[0].colSpan =
      columnas.length + this.camposAdicACCabecera.length - 1  + this.camposAdicPartCabecera.length - 1;
    workbook.options.sheets[0].rows[0].cells[0].value = `Fecha Informe: ${this.fechaActual}`;

    workbook.options.sheets[0].rows.push({ cells: [] });

    let index = 0;
    for (const valor of columnas) {
      let columna: string;
      columna = valor;

      if (index === 31) {
        for (const nombreCAAC of this.camposAdicACCabecera) {
          columna = nombreCAAC.nombre;
          workbook.options.sheets[0].rows[1].cells.push({
            background: '#184D9B',
            bold: true,
            color: '#ffffff',
            value: columna
          });
          workbook.options.sheets[0].columns.push({ autoWidth: true });
        }
      } else if (index === 56) {
        for (const nombreCAPart of this.camposAdicPartCabecera) {
          columna = nombreCAPart.nombre;
          workbook.options.sheets[0].rows[1].cells.push({
            background: '#184D9B',
            bold: true,
            color: '#ffffff',
            value: columna
          });
          workbook.options.sheets[0].columns.push({ autoWidth: true });
        }
      } else {
        switch (valor) {
          case 'nropv': columna = 'Nº PV'; break;
          case 'tipodocumento': columna = 'Tipo de Documento'; break;
          case 'cuentafinanciamiento': columna = 'Cuenta de Financiamiento'; break;
          case 'tipocurso': columna = 'Tipo de Curso'; break;
          case 'modalidadcapacitacion': columna = 'Modalidad de Capacitación'; break;
          case 'modalidadcapacitaciondesc': columna = 'Modalidad: Descripción'; break;
          case 'clasepedido': columna = 'Clase de Pedido'; break;
          case 'clasepedidodesc': columna = 'Clase de Pedido: Descripción'; break;
          case 'modalidadinstruccion': columna = 'Modalidad de Instrucción'; break;
          case 'modalidadinstrucciondesc': columna = 'Modalidad de Instrucción: Descripción'; break;
          case 'fechainicio': columna = 'Fecha Inicio'; break;
          case 'fechatermino': columna = 'Fecha Termino'; break;
          case 'viaticosrendidos': columna = 'Viáticos Rendidos'; break;
          case 'asistenciarendida': columna = 'Asistencia Rendida'; break;
          case 'codigosence': columna = 'Código Sence'; break;
          case 'nombrecurso': columna = 'Nombre del Curso'; break;
          case 'horasautorizadas': columna = 'Horas Autorizadas'; break;
          case 'horasrealizadas': columna = 'Horas Realizadas'; break;
          case 'statusdescripcion': columna = 'Status Descripción'; break;
          case 'statusrechazo': columna = 'Status Rechazo'; break;
          case 'nombreotec': columna = 'Nombre OTEC'; break;
          case 'sedeotec': columna = 'Sede OTEC'; break;
          case 'nroparticipantes': columna = 'Nº Participantes'; break;
          case 'costocursootic': columna = 'Costo Curso OTIC'; break;
          case 'costoviaticootic': columna = 'Costo Viatico OTIC'; break;
          case 'costotrasladootic': columna = 'Costo Traslado OTIC'; break;
          case 'costocursoempresa': columna = 'Costo Curso Empresa'; break;
          case 'costoviaticoempresa': columna = 'Costo Viatico Empresa'; break;
          case 'costotrasladoempresa': columna = 'Costo Traslado Empresa'; break;
          case 'comision': columna = 'Comisión'; break;
          case 'complementaria': columna = 'Complementaria'; break;
          // Campos AC - Posicion 31
          case 'nombreholding': columna = 'Nombre Holding'; break;
          case 'clientefinancia': columna = 'Cliente que Franquicia'; break;
          case 'sucursal': columna = 'Sucursal'; break;
          case 'areaconocimientocurso': columna = 'Area de Conocimiento Curso'; break;
          case 'especialidadcurso': columna = 'Especialidad curso'; break;
          case 'ciudadejecucion': columna = 'Ciudad de Ejecución'; break;
          case 'region': columna = 'Región'; break;
          case 'direccionejecucion': columna = 'Dirección Lugar de Ejecución'; break;
          case 'unohistorico': columna = '1 % Historico'; break;
          case 'dnc': columna = 'Detección de Nececidades'; break;
          case 'cbp': columna = 'Comité Bipartito'; break;
          case 'rut': columna = 'Rut'; break;
          case 'codtipodocumento': columna = 'Cod.Tipo de Documento'; break;
          case 'nombre': columna = 'Nombre'; break;
          case 'appaterno': columna = 'Ap.Paterno'; break;
          case 'apmaterno': columna = 'Ap.Materno'; break;
          case 'viaticosolicitado': columna = 'Viatico Solicitado'; break;
          case 'viaticorendido': columna = 'Viatico Rendido'; break;
          case 'trasladosolicitado': columna = 'Traslado Solicitado'; break;
          case 'trasladorendido': columna = 'Traslado Rendido'; break;
          case 'diasvyt': columna = 'Dias VyT'; break;
          case 'franquicia': columna = 'Franquicia'; break;
          case 'asistencia': columna = 'Asistencia'; break;
          case 'calificacion': columna = 'Calificacion'; break;
          case 'evaluacion': columna = 'Evaluacion'; break;
          // Campos Participantes - Posicion 56
          case 'rechazo': columna = 'Rechazo'; break;
          case 'motivorechazo': columna = 'Motivo de Rechazo'; break;
          case 'costocursooticparticipante': columna = 'Costo Curso OTIC por Participante'; break;
          case 'costoviaticooticparticipante': columna = 'Costo Viatico OTIC por Participante'; break;
          case 'costotrasladooticparticipante': columna = 'Costo Traslado OTIC por Participante'; break;
          case 'costocursoempresaparticipante': columna = 'Costo Curso Empresa por Participante'; break;
          case 'costoviaticoempresaparticipante': columna = 'Costo Viatico Empresa por Participante'; break;
          case 'costotrasladoempresaparticipante': columna = 'Costo Traslado Empresa por Participante'; break;
          case 'comisionparticioante': columna = 'Comision'; break;
          case 'condicion': columna = 'Condicion'; break;
          case 'fechadesvinculacion': columna = 'Fecha Desvinculación'; break;
          case 'nivelocupacional': columna = 'Nivel Ocupacional'; break;
          case 'nivelescolaridad': columna = 'Nivel de Escolaridad'; break;
          case 'sexo': columna = 'Sexo'; break;
          case 'totalcostootic': columna = 'Total Costo OTIC'; break;
          case 'totalcostoempresa': columna = 'Total Costo Empresa'; break;
          case 'solt': columna = 'Nº Solicitud'; break;
        }

        workbook.options.sheets[0].rows[1].cells.push({
          background: '#112540',
          bold: true,
          color: '#ffffff',
          value: columna
        });
        workbook.options.sheets[0].columns.push({ autoWidth: true });
      }

      index++;
    }

    for (const obj of this.consolidado) {
      const celdas: any[] = [];

      index = 0;
      for (const valor of columnas) {
        if (index === 31) {
          for (const nombreCAAC of this.camposAdicACCabecera) {
            const valorCA = this.camposAdicAC.find(x =>
              parseInt(x.pv, 10) === parseInt(obj.nropv, 10) &&
              x.nombre.toString() === nombreCAAC.nombre.toString());

            celdas.push({
              value: valorCA !== null && valorCA !== undefined ? valorCA.valor : null
            });
          }
        } else if (index === 56) {
          for (const nombreCAPart of this.camposAdicPartCabecera) {
            const valorPart = this.camposAdicPart.find(x =>
              parseInt(x.pv, 10) === parseInt(obj.nropv, 10) &&
              x.rut.toString() === obj.rut.toString() &&
              x.nombre.toString() === nombreCAPart.nombre.toString());

            celdas.push({
              value: valorPart !== null && valorPart !== undefined ? valorPart.valor : null
            });
          }
        } else {
          celdas.push({
            value: obj[valor]
          });
        }

        index++;
      }

      workbook.options.sheets[0].rows.push({
        cells: celdas
      });
    }

    this.spinner.hide();

    workbook.toDataURL().then(dataUrl => {
      saveAs(dataUrl, 'Consolidado.xlsx');
    });
  }

  /**
   * Filtra Reporte al presionar tecla Enter
   * @param event Evento
   */
  filtrarReporteKeydown(event) {
    if (event.key === 'Enter') {
      this.filtrarReporte();
    }
  }

  async accion(modulo: string, submodulo: string, acc: string){
    const token = await this.localStorageService.get('idToken');
    console.log('token',token);

    ClienteAuditoria({
      sistema: 'GCH Reportes',
      modulo,
      submodulo: submodulo ? submodulo : '',
      usuario: this.userData.rut,
      token,
      accion: acc
    });
  }

  async openModal(content: any) {
    this.nombrePlantillaVacio = true;
    this.opcionesAgregadasVacio = true;
    if (this.plantillaSeleccionada === 'nueva') {
      this.nombrePlantilla = '';
      this.cabecerasSeleccionadas = [];
      this.cabecerasDisponibles = this.cabecerasDefault;
      this.modalRef = this.modalService.open(content, { size: 'lg' });
    } else {
      this.modalRef = this.modalService.open(content, { size: 'lg' });
    }
  }

  closeModal() {
    this.nombrePlantillaVacio = true;
    this.opcionesAgregadasVacio = true;
    this.modalRef.close();
  }

  async cargarPlantillasUsuario() {
    this.plantillas = {};
    try {
      this.cargarCabecerasNuevaPlantilla();
      const data: any[] = await this.gestionPlantillasService.obtenerPlantillasUsuario().pipe(retry(3)).toPromise();
      data.forEach((item: any) => {
        const plantillaId = item.replaId;
        if (!this.plantillas[plantillaId]) {
          this.plantillas[plantillaId] = [];
        }
        this.plantillas[plantillaId].push(item);
      });
      this.llenarSelectPlantillas();
    } catch (error) {
      console.error('Error al obtener las cabeceras', error);
      throw error;
    }
  }

  async cargarCabecerasNuevaPlantilla() {
    this.cabecerasDefault = [];
    try {
      const data: any[] = await this.gestionPlantillasService.obtenerCabeceras().pipe(retry(3)).toPromise();
      this.cabecerasDefault = data;
    } catch (error) {
      console.error('Error al obtener las cabeceras default', error);
      throw error;
    }
  }

  llenarSelectPlantillas() {
    const selectElement: HTMLSelectElement = this.selectPlantillas.nativeElement;
    while (selectElement.firstChild) {
      selectElement.removeChild(selectElement.firstChild);
    }
    const nuevaPlantillaOption = document.createElement('option');
    nuevaPlantillaOption.value = 'nueva';
    nuevaPlantillaOption.text = 'Nueva Plantilla';
    selectElement.appendChild(nuevaPlantillaOption);
    if (Object.keys(this.plantillas).length > 0) {
      const addedPlantillas = new Set();
      for (const plantilla in this.plantillas) {
        if (this.plantillas.hasOwnProperty(plantilla)) {
          const opciones = this.plantillas[plantilla];
          if (!addedPlantillas.has(plantilla)) {
            const option = document.createElement('option');
            option.value = plantilla;
            option.text = opciones[0].nombre;
            selectElement.appendChild(option);
            addedPlantillas.add(plantilla);
          }
        }
      }
    }
    this.spinner.hide();
  }

  seleccionPlantilla(event: any) {
    const seleccion = event.target.value;
    this.plantillaSeleccionada = seleccion;
    if (seleccion !== 'nueva') {
      this.cabecerasDisponibles = this.cabecerasDefault;
      this.cabecerasSeleccionadas = [];
      const dataAsociada = this.plantillas[seleccion];
      this.nombrePlantilla = dataAsociada[0].nombre;
      dataAsociada[0].reportePlantillaCabeceras.forEach(item => {
        this.cabecerasSeleccionadas.push({
          recaId: item.reporteCabecera.recaId,
          nombre: item.reporteCabecera.nombre
        });
      });
    }
  }

  async guardarPlantilla() {
    if (this.nombrePlantilla !== '') {
      this.nombrePlantillaVacio = true;
      if (this.cabecerasSeleccionadas.length > 0) {
        if (this.plantillaSeleccionada !== 'nueva') {
          this.opcionesAgregadasVacio = true;
          this.spinner.show();
          const estructuraPlantilla = { replaId: this.plantillaSeleccionada, plantilla_nombre: this.nombrePlantilla, cabeceras: [] }
          this.cabecerasSeleccionadas.forEach(item => {
            estructuraPlantilla.cabeceras.push({
              recaId: parseInt(item.recaId),
              orden: item.orden
            });
          });
          try {
            this.closeModal();
            await this.gestionPlantillasService.editarPlantilla(estructuraPlantilla).pipe(retry(3)).toPromise();
            this.cargarPlantillasUsuario();
            this.toastr.success(`Plantilla ${estructuraPlantilla.plantilla_nombre}, modificada con exito.`, GLOBALS.TOASTER.TITLE_OK);
          } catch (error) {
            console.error('Error al modificar los datos', error);
          }
        } else {
          this.spinner.show();
          const estructuraPlantilla = { plantilla_nombre: this.nombrePlantilla, cabeceras: [] }
          this.cabecerasSeleccionadas.forEach(item => {
            estructuraPlantilla.cabeceras.push({
              recaId: item.recaId,
              orden: item.orden
            });
          });
          try {
            this.closeModal();
            await this.gestionPlantillasService.crearNuevaPlantilla(estructuraPlantilla).pipe(retry(3)).toPromise();
            this.cargarPlantillasUsuario();
            this.toastr.success(`Plantilla ${estructuraPlantilla.plantilla_nombre}, creada con exito.`, GLOBALS.TOASTER.TITLE_OK);
          } catch (error) {
            console.error('Error al insertar los datos', error);
          }
        }
      } else {
        this.opcionesAgregadasVacio = false;
      }
    } else {
      this.nombrePlantillaVacio = false;
    }
  }

}
