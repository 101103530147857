import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotificacionesService = /** @class */ (function () {
    function NotificacionesService(http) {
        this.http = http;
        console.log('NotificacionesService: constructor');
    }
    /**
     * Envia la notificación al Ejecutivo Comercial
     * @param contacto ContactoAportesModel
     */
    NotificacionesService.prototype.enviarNotificacionContAportes = function (contacto) {
        var body = JSON.stringify(contacto);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.NOTIFICACIONES + "/enviarNotificacionContAportes/", body, { headers: headers });
    };
    NotificacionesService.ngInjectableDef = i0.defineInjectable({ factory: function NotificacionesService_Factory() { return new NotificacionesService(i0.inject(i1.HttpClient)); }, token: NotificacionesService, providedIn: "root" });
    return NotificacionesService;
}());
export { NotificacionesService };
