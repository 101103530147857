import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Workbook } from '@progress/kendo-ooxml';
import { saveAs } from '@progress/kendo-file-saver';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
// Services
import { ClienteComercialService } from '../../services/cliente-comercial.service';
import { AportesService } from '../../services/aportes.service';
import { UserLoginService } from '../../services/user-login.service';
import { NotificacionesService } from '../../services/notificaciones.service';
// Models
import { EmpresasSucursalModel } from '../../models/empresasSucursal.model';
import { AportesModel } from '../../models/aportes.model';
// Shared
import { GLOBALS, GlobalService } from '../../shared/globals.shared';
import { environment } from 'src/environments/environment';
import { ContactoAportesModel } from 'src/app/models/contactoAportes';
var RepAportesImputadosComponent = /** @class */ (function () {
    function RepAportesImputadosComponent(spinner, toastr, modalService, globalService, clienteComercialService, aportesService, notificacionesService, router, localStorageService, userLoginService) {
        this.spinner = spinner;
        this.toastr = toastr;
        this.modalService = modalService;
        this.globalService = globalService;
        this.clienteComercialService = clienteComercialService;
        this.aportesService = aportesService;
        this.notificacionesService = notificacionesService;
        this.router = router;
        this.localStorageService = localStorageService;
        this.userLoginService = userLoginService;
        this.urlGestion = environment.url_gestor;
        this.min = null;
        this.max = null;
        this.fechaActual = [
            new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
            (new Date().getMonth() + 1).toString().length === 1 ?
                '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
            new Date().getFullYear()
        ].join('/');
        this.sinAportes = false;
        this.empresasSucursales = [];
        this.parsedData = [];
        this.checkedKeys = [];
        this.searchTerm = '';
        this.empresasSucursalSeleccionadas = [];
        this.fecImpIni = null;
        this.fecImpFin = null;
        this.errorFiltro = {
            empresaSucursal: false,
            fecImpIni: false,
            fecImpFin: false
        };
        this.groups = [{ field: 'Empresa' }];
        this.aportes = [];
        this.aportesPdf = [];
        this.contactoAporte = new ContactoAportesModel();
        this.listFecIni = [];
        this.listFecFin = [];
        this.permisosModulosAcciones = {};
        /**
         * Trae los hijos del árbol
         */
        this.children = function (dataItem) { return of(dataItem.items); };
        /**
         * Valida si tiene hijos el árbol
         */
        this.hasChildren = function (dataItem) { return !!dataItem.items; };
        console.log('RepAportesImputadosComponent: constructor');
    }
    /**
     * OnInit Event
     */
    RepAportesImputadosComponent.prototype.ngOnInit = function () {
        console.log('RepAportesImputadosComponent: OnInit');
        this.userLoginService.isAuthenticated(this);
        this.userData = this.localStorageService.get('userData');
    };
    RepAportesImputadosComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        if (isLoggedIn) {
            this.router.events.subscribe(function (evt) {
                if (!(evt instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
            this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
            this.permisos = this.localStorageService.get('permisos');
            this.userData = this.localStorageService.get('userData');
            if (this.permisosModulosAcciones.REPOAPOR) {
                this.initListadoEmpresas();
            }
            else {
                this.toastr.error("No est\u00E1s autorizado para ingresar a esta p\u00E1gina", GLOBALS.TOASTER.TITLE_ERROR);
                this.router.navigate(['/']);
            }
        }
    };
    /**
     * Inicializa el listado de Empresas
     */
    RepAportesImputadosComponent.prototype.initListadoEmpresas = function () {
        var _this = this;
        this.spinner.show();
        var sucursalesAutorizadas = this.permisos.map(function (item) { return item.data.sucursalCodigo; }).join(',');
        if (sucursalesAutorizadas) {
            this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
                .subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var empresas, index, codigoEmpresa, rama, _i, empresas_1, empresa;
                return tslib_1.__generator(this, function (_a) {
                    if (result.rows.length > 0) {
                        empresas = result.rows.map(function (empresa) { return new EmpresasSucursalModel(empresa); });
                        index = 1;
                        codigoEmpresa = void 0;
                        rama = void 0;
                        for (_i = 0, empresas_1 = empresas; _i < empresas_1.length; _i++) {
                            empresa = empresas_1[_i];
                            if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    value: empresa.codigoSucursal,
                                    items: []
                                };
                            }
                            else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    items: [{
                                            text: empresa.sucursal,
                                            value: empresa.codigoSucursal
                                        }]
                                };
                            }
                            else {
                                rama.items.push({
                                    text: empresa.sucursal,
                                    value: empresa.codigoSucursal
                                });
                            }
                            if (index === empresas.length) {
                                this.empresasSucursales.push(rama);
                            }
                            index++;
                        }
                        this.parsedData = this.empresasSucursales;
                    }
                    else {
                        this.toastr.warning("No hay informaci\u00F3n de Empresas / Sucursales en Reportes", "Advertencia");
                    }
                    this.spinner.hide();
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                _this.spinner.hide();
                _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            });
        }
        else {
            this.toastr.warning("No hay Empresas / Sucursales en Cliente Comercial", "Advertencia");
            this.spinner.hide();
        }
    };
    Object.defineProperty(RepAportesImputadosComponent.prototype, "checkableSettings", {
        /**
         * Crea las Propiedades del TreeView
         */
        get: function () {
            return {
                checkChildren: true,
                checkParents: true,
                mode: 'multiple',
                checkOnClick: false
            };
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Evento al presionar una tecla en la búsqueda
     * @param value Valor Ingresado
     */
    RepAportesImputadosComponent.prototype.onkeyup = function (value) {
        this.parsedData = this.search(this.empresasSucursales, value);
    };
    /**
     * Búsqueda
     * @param items Items
     * @param term Término (Palabra)
     */
    RepAportesImputadosComponent.prototype.search = function (items, term) {
        var _this = this;
        return items.reduce(function (acc, item) {
            if (_this.contains(item.text, term)) {
                acc.push(item);
            }
            else if (item.items && item.items.length > 0) {
                var newItems = _this.search(item.items, term);
                if (newItems.length > 0) {
                    acc.push({ text: item.text, items: newItems });
                }
            }
            return acc;
        }, []);
    };
    /**
     * Compara el Texto y el Término ingresado
     * @param text Texto
     * @param term Término (Palabra)
     */
    RepAportesImputadosComponent.prototype.contains = function (text, term) {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    };
    /**
     * Crea el array de empresas
     * @param itemLookup TreeItemLookup
     */
    RepAportesImputadosComponent.prototype.handleChecking = function (itemLookup) {
        if (this.checkedKeys.find(function (x) { return x === itemLookup.item.index; }) !== undefined) {
            if (itemLookup.children.length > 0) {
                var _loop_1 = function (hijo) {
                    this_1.empresasSucursalSeleccionadas = this_1.empresasSucursalSeleccionadas.filter(function (x) { return x !== hijo.item.dataItem.value; });
                };
                var this_1 = this;
                for (var _i = 0, _a = itemLookup.children; _i < _a.length; _i++) {
                    var hijo = _a[_i];
                    _loop_1(hijo);
                }
            }
            else {
                this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(function (x) { return x !== itemLookup.item.dataItem.value; });
            }
        }
        else {
            if (itemLookup.children.length > 0) {
                var _loop_2 = function (hijo) {
                    this_2.empresasSucursalSeleccionadas = this_2.empresasSucursalSeleccionadas.filter(function (x) { return x !== hijo.item.dataItem.value; });
                    this_2.empresasSucursalSeleccionadas.push(hijo.item.dataItem.value);
                };
                var this_2 = this;
                for (var _b = 0, _c = itemLookup.children; _b < _c.length; _b++) {
                    var hijo = _c[_b];
                    _loop_2(hijo);
                }
            }
            else {
                this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(function (x) { return x !== itemLookup.item.dataItem.value; });
                this.empresasSucursalSeleccionadas.push(itemLookup.item.dataItem.value);
            }
        }
        this.inicializaFechas();
    };
    /**
     * Busca las fechas de Inicio y Fin de los aportes al cambiar la selección
     */
    RepAportesImputadosComponent.prototype.inicializaFechas = function () {
        var _this = this;
        this.fecImpIni = null;
        this.fecImpFin = null;
        this.sinAportes = false;
        this.errorFiltro = {
            empresaSucursal: false,
            fecImpIni: false,
            fecImpFin: false
        };
        if (this.empresasSucursalSeleccionadas.length > 0) {
            var codigosEmpresa = this.empresasSucursalSeleccionadas.join(',');
            this.spinner.show();
            this.aportesService.obtenerFechasAportes(codigosEmpresa).subscribe(function (result) {
                _this.spinner.hide();
                if (result.rows[0].minanio === null) {
                    _this.sinAportes = true;
                }
                else {
                    _this.min = result.rows[0].minanio;
                    _this.max = result.rows[0].maxanio;
                    _this.fecImpIni = _this.min;
                    _this.fecImpFin = _this.max;
                    _this.listFecIni = [];
                    _this.listFecFin = [];
                    for (var z = _this.min; z <= _this.max; z++) {
                        _this.listFecIni.push(z.toString());
                        _this.listFecFin.push(z.toString());
                    }
                }
            }, function (error) {
                console.log(error);
                _this.spinner.hide();
                _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            });
        }
        else {
            if (this.empresasSucursales.length > 0) {
                this.errorFiltro.empresaSucursal = true;
            }
        }
    };
    /**
     * Configura las fechas de inicio y fin seleccionables cuando cambia el valor
     */
    RepAportesImputadosComponent.prototype.configuraFechas = function () {
        this.spinner.show();
        this.listFecIni = [];
        this.listFecFin = [];
        for (var z = this.min; z <= (this.fecImpFin !== undefined ? this.fecImpFin : this.max); z++) {
            this.listFecIni.push(z.toString());
        }
        for (var z = (this.fecImpIni !== undefined ? this.fecImpIni : this.min); z <= this.max; z++) {
            this.listFecFin.push(z.toString());
        }
        this.spinner.hide();
    };
    /**
     * Filtra el Reporte
     */
    RepAportesImputadosComponent.prototype.filtrarReporte = function () {
        var _this = this;
        this.errorFiltro = {
            empresaSucursal: false,
            fecImpIni: false,
            fecImpFin: false
        };
        if (this.empresasSucursalSeleccionadas.length === 0) {
            this.errorFiltro.empresaSucursal = true;
            return;
        }
        else {
            if (this.fecImpIni === undefined || this.fecImpIni === null) {
                this.errorFiltro.fecImpIni = true;
            }
            if (this.fecImpFin === undefined || this.fecImpFin === null) {
                this.errorFiltro.fecImpFin = true;
            }
            if (this.fecImpIni === undefined || this.fecImpIni === null || this.fecImpFin === undefined || this.fecImpFin === null) {
                return;
            }
        }
        this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
        var filtrosReporte = {
            cliComercial: this.nombreCC,
            empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
            fecImpIni: this.fecImpIni,
            fecImpFin: this.fecImpFin
        };
        this.spinner.show();
        this.aportesService.reporteAportes(filtrosReporte).subscribe(function (result) {
            _this.spinner.hide();
            _this.aportes = result.rows.map(function (aporte) {
                return new AportesModel(aporte);
            });
            // Arma la estructura necesaria para displegar el PDF
            _this.aportesPdf = [];
            var empresa = '';
            for (var _i = 0, _a = _this.aportes; _i < _a.length; _i++) {
                var aporte = _a[_i];
                if (aporte.Empresa !== empresa) {
                    empresa = aporte.Empresa;
                    _this.aportesPdf.push({
                        empresa: empresa,
                        aportes: []
                    });
                }
                _this.aportesPdf.find(function (x) { return x.empresa === empresa; }).aportes.push(aporte);
            }
        }, function (error) {
            console.log(error);
            _this.spinner.hide();
            _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        });
    };
    /**
     * Metodo para exportar a Excel
     * @param grid Grid
     */
    RepAportesImputadosComponent.prototype.exportToExcel = function () {
        this.spinner.show();
        var workbook = new Workbook({
            sheets: [{
                    columns: [],
                    rows: [{ cells: [{}] }]
                }]
        });
        workbook.options.sheets[0].name = 'Aportes';
        // workbook.options.sheets[0].rows[0].cells[0].colSpan = 8;
        workbook.options.sheets[0].rows[0].cells[0].colSpan = 7;
        workbook.options.sheets[0].rows[0].cells[0].value = "Fecha Informe: " + this.fechaActual;
        workbook.options.sheets[0].rows.push({ cells: [] });
        // for (let z = 0; z < 8; z++) {
        for (var z = 0; z < 7; z++) {
            var columna = void 0;
            switch (z) {
                case 0:
                    columna = 'Nº Aporte';
                    break;
                case 1:
                    columna = 'Fecha Imputación';
                    break;
                case 2:
                    columna = 'Oficina Ventas';
                    break;
                case 3:
                    columna = 'Tipo Transacción';
                    break;
                // case 4:
                //   columna = 'Fecha Transacción';
                //   break;
                case 5:
                    columna = 'Nro. Documento';
                    break;
                case 6:
                    columna = 'Banco';
                    break;
                default:
                    columna = 'Monto';
                    break;
            }
            workbook.options.sheets[0].columns.push({ autoWidth: true });
            workbook.options.sheets[0].rows[1].cells.push({
                background: '#112540',
                bold: true,
                color: '#ffffff',
                value: columna
            });
        }
        var codigoEmpresa = '';
        for (var _i = 0, _a = this.aportes; _i < _a.length; _i++) {
            var aporte = _a[_i];
            if (codigoEmpresa !== aporte.codigoEmpresa) {
                codigoEmpresa = aporte.codigoEmpresa;
                workbook.options.sheets[0].rows.push({
                    cells: [
                        // { value: `Empresa: ${aporte.Empresa}`, background: '#ededed', colSpan: 8 }
                        { value: "Empresa: " + aporte.Empresa, background: '#ededed', colSpan: 7 }
                    ]
                });
            }
            workbook.options.sheets[0].rows.push({
                cells: [
                    { value: aporte.codigoAporte },
                    { value: aporte.fechaImputacion },
                    { value: aporte.oficinaVentas },
                    { value: aporte.tipoTransaccion },
                    // { value: aporte.fechaTransaccion },
                    { value: aporte.nroDocumento },
                    { value: aporte.banco },
                    { value: aporte.monto, textAlign: 'right' }
                ]
            });
        }
        this.spinner.hide();
        workbook.toDataURL().then(function (dataUrl) {
            saveAs(dataUrl, 'Aportes.xlsx');
        });
    };
    /**
     * Muestra Modal con Contacto del Ejecutivo
     * @param aporte AportesModel
     */
    RepAportesImputadosComponent.prototype.mostrarContactoEjecutivo = function (aporte) {
        var _this = this;
        this.spinner.show();
        this.clienteComercialService.obtenerContacto(aporte.codigoSucursal).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (result.find(function (x) { return x.tipo.toString() === 'Ejecutivo Consultor'; }) === undefined) {
                    this.toastr.warning('Empresa no tiene un Ejecutivo Comercial asignado', GLOBALS.TOASTER.TITLE_INFO);
                }
                else {
                    this.contactoAporte.codigoEmpresa = aporte.codigoEmpresa;
                    this.contactoAporte.empresa = aporte.Empresa;
                    this.contactoAporte.codigoAporte = aporte.codigoAporte;
                    this.contactoAporte.ejecutivoComercial = result[0].nombre;
                    this.contactoAporte.ejecutivoComercialMail = result[0].mail;
                    this.contactoAporte.asistenteComercial = result[1] !== undefined ? result[1].nombre : null;
                    this.contactoAporte.asistenteComercialMail = result[1] !== undefined ? result[1].mail : null;
                    this.contactoAporte.usuario = this.userData.nombre;
                    this.contactoAporte.usuarioMail = this.userData.email;
                    this.contactoAporte.mensaje = '';
                    this.modalRef = this.modalService.open(this.mdlEditar, { size: 'lg', backdrop: 'static' });
                }
                this.spinner.hide();
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.spinner.hide();
            _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        });
    };
    /**
     * Envía notificación al Ejecutivo Comercial
     * @param form Formulario
     */
    RepAportesImputadosComponent.prototype.enviarContacto = function (form) {
        var _this = this;
        if (!form.valid) {
            return;
        }
        this.spinner.show();
        this.notificacionesService.enviarNotificacionContAportes(this.contactoAporte).subscribe(function () {
            _this.toastr.success('Mensaje Enviado al Ejecutivo Comercial', GLOBALS.TOASTER.TITLE_OK);
            _this.modalRef.close();
            _this.spinner.hide();
        }, function (error) {
            console.log(error);
            _this.spinner.hide();
            _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        });
    };
    /**
     * Filtra Reporte al presionar tecla Enter
     * @param event Evento
     */
    RepAportesImputadosComponent.prototype.filtrarReporteKeydown = function (event) {
        if (event.key === 'Enter') {
            this.filtrarReporte();
        }
    };
    return RepAportesImputadosComponent;
}());
export { RepAportesImputadosComponent };
