import { Deserializable } from '../interfaces/deserializable.interface';
import { GlobalService } from '../shared/globals.shared';

export class EmpresasSucursalModel implements Deserializable<EmpresasSucursalModel> {
    public codigoEmpresa: number;
    public rutEmpresa: string;
    public razonSocial: number;
    public codigoSucursal: number;
    public sucursal: string;
    public rutRazonSocial: string;

    constructor(empresaSucursalJson: any) {
        this.codigoEmpresa = Number(empresaSucursalJson.codigoempresa) || null;
        this.rutEmpresa = empresaSucursalJson.rutempresa || null;
        this.razonSocial = empresaSucursalJson.razonsocial || null;
        this.codigoSucursal = Number(empresaSucursalJson.codigosucursal) || null;
        this.sucursal = empresaSucursalJson.sucursal || null;

        const globalService = new GlobalService();
        this.rutRazonSocial = globalService.formatRUT(this.rutEmpresa) + ' - ' + this.razonSocial;
    }

    deserialize(input: any): EmpresasSucursalModel {
        Object.assign(this, input);
        return this;
    }
}
