import {EventEmitter, Injectable, Output} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CognitoCallback, CognitoUtilService, LoggedInCallback } from './cognito.service';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { LocalStorageService } from 'angular-web-storage';
import { Router } from '@angular/router';
import * as AWS from 'aws-sdk/global';
import * as STS from 'aws-sdk/clients/sts';

@Injectable({
    providedIn: 'root'
})
export class UserLoginService {
  @Output() getLoggedInUser: EventEmitter<any> = new EventEmitter();
  constructor(
      public cognitoUtil: CognitoUtilService,
      private http: HttpClient,
      private localStorageService: LocalStorageService) {
          console.log('UserLoginService: Constructor');
  }

  private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {
      console.log('En authenticateUser onSuccess callback');
      console.log(session);
      AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());
      const clientParams: any = {};
      if (environment.STS_ENDPOINT) {
          clientParams.endpoint = environment.STS_ENDPOINT;
      }
      this.localStorageService.set('idToken', session.getIdToken().getJwtToken());
      const sts = new STS(clientParams);
      sts.getCallerIdentity((err, data) => {
          console.log('UserLoginService: credenciales AWS exitosamente seteadas');
          callback.cognitoCallback(null, session);
      });
  }

  private onLoginError = (callback: CognitoCallback, err) => {
      callback.cognitoCallback(err.message, null);
  }

  authenticate(username: string, password: string, newPassword: string, callback: CognitoCallback) {
      console.log('UserLoginService: iniciando autenticacion');
      const authenticationData = {
          Username: username,
          Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);
      const userData = {
          Username: username,
          Pool: this.cognitoUtil.getUserPool()
      };
      console.log('UserLoginService: Parametros seteados...Autenticando el usuario');
      const cognitoUser = new CognitoUser(userData);
      console.log('UserLoginService: config es ' + AWS.config);
      cognitoUser.authenticateUser(authenticationDetails, {
          newPasswordRequired: (userAttributes, requiredAttributes) => {
              if (newPassword) {
                  cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                      onFailure: error => this.onLoginError(callback, error),
                      onSuccess: result => this.onLoginSuccess(callback, result)
                  });
              } else {
                  callback.cognitoCallback('User needs to set password.', null);
              }
          },
          onSuccess: result => this.onLoginSuccess(callback, result),
          onFailure: err => this.onLoginError(callback, err),
          mfaRequired: (challengeName, challengeParameters) => {
              callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
                  cognitoUser.sendMFACode(confirmationCode, {
                      onSuccess: result => this.onLoginSuccess(callback, result),
                      onFailure: err => this.onLoginError(callback, err)
                  });
              });
          }
      });
  }

  logout() {
      console.log('UserLoginService: Cerrando sesion');
      this.cognitoUtil.getCurrentUser().signOut();
      localStorage.clear();
      window.location.href = `${environment.URL_SIGNOUT}?callback=${environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
  }

  isAuthenticated(callback: LoggedInCallback) {
    const thisLocalStorage = this.localStorageService;
    if (callback == null) {
      throw new Error(('UserLoginService: Callback en isAuthenticated() no puede ser null'));
    }
    const cognitoUser =  this.cognitoUtil.getCurrentUser();
    console.log('cognitoUser isAuthenticated Reportes', cognitoUser);
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log('UserLoginService: No se logro recuperar la sesion: ' + err, err.stack);
          callback.isLoggedIn(err, false);
        } else {
          console.log('session:' + session);
          console.log('UserLoginService:' + session.isValid());
          callback.isLoggedIn(err, session.isValid());
        }
      });
    } else {
      console.log('callback',callback);
      console.log('UserLoginService: no se logro recuperar el CurrentUser');
      callback.isLoggedIn('No se logro recuperar el CurrentUser', false);
    }
  }

  validateToken(token: any): Observable<any> {
      const body = JSON.stringify(token);
      const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
      return this.http.post(`${environment.ENDPOINTS.COMUNES}/authCognito`, body, {headers});
  }

  refrestoken(token: any): Observable<any> {
    const body = JSON.stringify(token);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.CATENA_USR}/tok/reftok`, body, {headers});
  }
}
